/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { useCallback, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import api from "../../services/CtsApi";
import rapApi from "../../services/RapApi";
import parApi from "../../services/ParApi";

import Constants from "../../common/Constants";
import CTSConstants from "../../common/CTSConstants";

import Spinner from "./Spinner";
import DateTime from "../../helpers/DateTime";
import SuccessSound from "../../assets/success-sound-effect.mp3";

const uploadMethods = {
  [Constants.SOURCE_RAP_BULK]: rapApi.uploadBulkDataFile,
  [Constants.SOURCE_GLOBAL_COST_DATA_BULK]: rapApi.uploadGlobalBulkDataFile,
  [Constants.SOURCE_SMARTRECRUITER_DATA_BULK]:
    rapApi.uploadSmartRecruiterDataFile,
  [Constants.SOURCE_PAR_PAY_RANGE]: parApi.bulkUploadPARPayRangesData,
  [Constants.SOURCE_PAR_BULK_UPLOAD]: parApi.bulkUploadPARPINData
};

const UploadFileSource = ({
  batchId,
  sourceName,
  source,
  isScenarioLocked,
  isBatchSourceLoading,
  onSuccessfulUpload
}) => {
  const [totalProgress, setTotalProgress] = useState(-1);
  const [sourceLoading, setSourceLoading] = useState(false);

  const isBulkSource = (name) => {
    const bulkSources = [
      Constants.SOURCE_RAP_BULK,
      Constants.SOURCE_GLOBAL_COST_DATA_BULK,
      Constants.SOURCE_SMARTRECRUITER_DATA_BULK,
      Constants.SOURCE_PAR_PAY_RANGE,
      Constants.SOURCE_PAR_BULK_UPLOAD
    ];

    return bulkSources.includes(name);
  };

  // Define a ref since we are accessing it inside a callback method
  const totalProgressRef = useRef();
  totalProgressRef.current = totalProgress;

  const onFileDialogOpen = useCallback(() => {
    // Reset the state values
    setTotalProgress(-1);
  });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setTotalProgress(10);
      const reader = new FileReader();
      reader.onabort = () => {
        setTotalProgress(100);
        console.log("file reading was aborted");
      };
      reader.onerror = () => {
        setTotalProgress(100);
        console.log("file reading has failed");
      };

      reader.onload = async () => {
        setSourceLoading(true);
        const binaryStr = reader.result;
        // Get the Base64 String from the File reader
        const base64String = binaryStr.replace("data:", "").replace(/^.+,/, "");
        const intervalId = setInterval(() => {
          // Just stay at 90 so that the progress bar only updates when its done
          if (totalProgressRef.current < 90) {
            const newProgress = totalProgressRef.current + 3;
            setTotalProgress(newProgress);
          }
        }, 3000);

        // to wrap up all the things that we need to wrap up
        const completeJob = (progVal, toaster, msg) => {
          setTotalProgress(progVal);
          clearInterval(intervalId);
          toaster(msg, Constants.TOAST_OPTIONS);
        };

        try {
          let result = {};
          if (!isBulkSource(sourceName)) {
            result =
              (await api.uploadDataSourceFile(
                batchId,
                file.name,
                base64String,
                sourceName
              )) || {};
          } else {
            result =
              (await uploadMethods[sourceName](file.name, base64String)) || {};
          }
          if (result.status === CTSConstants.SUCCESS) {
            if (!isBulkSource(sourceName)) {
              // If the result is success that means that file upload has been finished but
              // file ingestion might be still going on,
              // So, the CTS_DATA_SOURCE table needs to be checked for the status of the job
              // Only if the status is 'Loaded', we show the success msg
              let resultResource = null;
              let isSourceLoading = true;
              do {
                const response = await api.getBatchSources(batchId);
                resultResource = (
                  (response && response.batchSources) ||
                  []
                ).find((s) => s.sourceType === sourceName);
                isSourceLoading =
                  !resultResource ||
                  !["loaded", "error"].includes(resultResource.status);

                // wait a second...
                await new Promise((resolve) => setTimeout(resolve, 1000)); // eslint-disable-line
              } while (isSourceLoading);

              if (resultResource.status === "loaded") {
                completeJob(100, toast.success, "File Uploaded Successfully");
                // Play a success sound when source upload is done
                const successAlert = new Audio(SuccessSound);
                onSuccessfulUpload();
                successAlert.play();
              } else {
                let processError = "";
                // Client Matrix has custom error handling
                if (
                  sourceName === CTSConstants.SOURCE_CLIENT_MATRIX ||
                  sourceName === CTSConstants.SOURCE_TIMESHEET
                ) {
                  const batchData = await api.getBatchDetails(batchId);
                  processError = batchData.processError || "";
                  toast.error(processError, Constants.TOAST_OPTIONS);
                }
                completeJob(
                  0,
                  toast.error,
                  "There was a problem in uploading the file. Please check file contents"
                );
              }
            } else {
              let message =
                sourceName === Constants.SOURCE_PAR_BULK_UPLOAD
                  ? "The PAR Bulk Upload was successful. Go to your PAR Submissions tab to review the draft tickets and submit."
                  : "Upload completed successfully";
              completeJob(100, toast.success, message);
              // Play a success sound when source upload is done.
              const successAlert = new Audio(SuccessSound);
              if (sourceName === Constants.SOURCE_PAR_BULK_UPLOAD) {
                onSuccessfulUpload(result);
              } else {
                onSuccessfulUpload(result.uploadCount);
              }
              successAlert.play();
            }
          } else if (
            result.status === "error" &&
            sourceName === Constants.SOURCE_PAR_BULK_UPLOAD
          ) {
            completeJob(0, toast.error, "Upload failed with errors");
            onSuccessfulUpload(result);
          } else {
            completeJob(
              0,
              toast.error,
              "There was a problem in uploading the file. Please check file contents"
            );
          }
        } catch (ex) {
          completeJob(
            0,
            toast.error,
            `There was an unexpected error in uploading the file - ${ex.message}`
          );
          console.log(ex);
        } finally {
          setSourceLoading(false);
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const downloadFile = async (uploadType) => {
    let filePath = "";
    try {
      let response = null;
      if (!isBulkSource(sourceName)) {
        response = await api.downloadDataFile({
          batchId,
          fileType: source.sourceType
        });
      } else {
        response = await rapApi.getBulkUploadTemplate(uploadType);
      }
      // Get full url
      if (response && response.url) {
        filePath = response.url;
      } else {
        // Show error
        toast.error(
          "There was a problem in downloading the file.",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in downloading the file - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    await window.open(`${filePath}`, "_blank");
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    onFileDialogOpen,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    // Restrict it to only select 1 file at a time
    maxFiles: 1,
    // Limit it to only accept .xls and .xlsx extensions
    accept:
      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

  let filePath = "";
  if (acceptedFiles && acceptedFiles.length >= 1) {
    filePath = acceptedFiles[0].path;
  }

  let sourceHeader = sourceName;
  if (
    source &&
    source.status === "loaded" &&
    source.rowCount &&
    !sourceLoading
  ) {
    sourceHeader = `${sourceName} (${source.rowCount})`;
  } else if (sourceLoading) {
    sourceHeader = (
      <>
        {sourceName}&nbsp;
        <Spinner waiting />
      </>
    );
  }
  let dateUpdated = null;
  if (source && source.updated) {
    dateUpdated = `Latest Records as on ${DateTime.getUIDisplayDate(
      source.updated
    )}`;
  }
  let existingfilePath = null;
  if (source && source.fileName) {
    existingfilePath = source.fileName;
  }

  return (
    <div className="card mt-3">
      {isBatchSourceLoading && (
        <div className="row ml-2 mt-3">
          <Spinner waiting />
        </div>
      )}
      {!isBatchSourceLoading && (
        <>
          <div className="row gray-text mt-3">
            <div className="col-md-6 ml-1">&nbsp;Data Source</div>
            {source &&
              !isBulkSource(sourceName) &&
              (source.rowCount ||
                sourceName === CTSConstants.EXCEL_OUTPUT_TEMPLATE) && (
                <div className="col-md-5 ml-4 row blue-text">
                  <a className="ml-4" onClick={() => downloadFile()}>
                    <i className="fas fa-download" />
                    &nbsp; Download
                  </a>
                </div>
              )}
            {source && sourceName === Constants.SOURCE_RAP_BULK && (
              <div className="col-md-5 row blue-text">
                <a
                  className="float-right mr-1"
                  onClick={() =>
                    downloadFile(Constants.BULK_UPLOAD_TYPE.rap_record_data)
                  }
                >
                  <i className="fas fa-download" />
                  6&nbsp; Download RAP Template
                </a>
              </div>
            )}
            {source && sourceName === Constants.SOURCE_PAR_PAY_RANGE && (
              <div className="col-md-6 row blue-text">
                <a
                  className="float-right mr-1"
                  onClick={() =>
                    downloadFile(Constants.BULK_UPLOAD_TYPE.par_pay_range_data)
                  }
                >
                  <i className="fas fa-download" />
                  &nbsp; Download PAR Pay Range Template
                </a>
              </div>
            )}
            {source &&
              sourceName === Constants.SOURCE_GLOBAL_COST_DATA_BULK && (
                <div className="col-md-6 row blue-text">
                  <a
                    className="float-right mr-1"
                    onClick={() =>
                      downloadFile(Constants.BULK_UPLOAD_TYPE.cost_card_data)
                    }
                  >
                    <i className="fas fa-download" />
                    &nbsp; Download Cost Card Template
                  </a>
                </div>
              )}
            {source &&
              sourceName === Constants.SOURCE_SMARTRECRUITER_DATA_BULK && (
                <div className="col-md-6 row blue-text">
                  <a
                    className="float-right mr-1"
                    onClick={() =>
                      downloadFile(
                        Constants.BULK_UPLOAD_TYPE.smart_recruiter_data
                      )
                    }
                  >
                    <i className="fas fa-download" />
                    &nbsp; Download SmartRecuiter Template
                  </a>
                </div>
              )}
          </div>
          <div className="row mt-2">
            <div className="col-md-9">
              <h5 className="ml-2 blue-text">{sourceHeader}</h5>
            </div>
            {totalProgress !== -1 && (
              <div className="col-md-3">
                <div className="float-left">{`Uploaded ${totalProgress}%`}</div>
                <br />
                <div className="h-25 mr-4 progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${totalProgress}%` }}
                    aria-valuenow={totalProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    aria-label="Progress"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row gray-text ml-2 mt-2">
            Upload Source<span className="red-text">&nbsp;*</span>
          </div>
          <div {...getRootProps({ className: "row mb-3 mr-2" })}>
            <input {...getInputProps()} />
            <div className="col-md-9">
              <input
                type="text"
                className="ml-2 form-control"
                placeholder={existingfilePath}
                value={`${filePath}`}
                disabled
              />
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className="mr-3 btn btn-sm btn-success"
                disabled={isScenarioLocked}
                onClick={open}
              >
                Browse
              </button>
            </div>
          </div>
          {dateUpdated && (
            <div className="row mb-3 ml-2 mr-2 light-gray-text">
              {dateUpdated}
            </div>
          )}
        </>
      )}
    </div>
  );
};

UploadFileSource.propTypes = {
  sourceName: PropTypes.string.isRequired,
  batchId: PropTypes.number.isRequired,
  source: PropTypes.shape({
    batchId: PropTypes.number,
    created: PropTypes.string,
    fileLocation: PropTypes.string,
    fileName: PropTypes.string,
    rowCount: PropTypes.number,
    sourceType: PropTypes.string,
    status: PropTypes.string,
    updated: PropTypes.string
  }),
  isScenarioLocked: PropTypes.bool.isRequired,
  isBatchSourceLoading: PropTypes.bool.isRequired,
  onSuccessfulUpload: PropTypes.func.isRequired
};

UploadFileSource.defaultProps = {
  source: {}
};

export default UploadFileSource;

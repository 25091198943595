import React from "react";
import PropTypes from "prop-types";

const ChangeLog = ({ versionNumber }) => (
  <div>
    <h5>{`Current Version: ${versionNumber}`}</h5>
    <hr />
    <h4>Version 4.0.0</h4>
    <h5>New Features</h5>
    <ul>
      <li>PAR v1 Full functionality released</li>
    </ul>
    <hr />
    <h4>Version 3.3.0</h4>
    <h5>New Features</h5>
    <ul>
      <li>Added new PAR Related Sections and Features</li>
    </ul>
    <hr />
    <h4>Version 3.2.7</h4>
    <h5>New Features</h5>
    <ul>
      <li>
        Add SmartRecruiter Data in RAP, ability to upload the data and show the
        related status
      </li>
    </ul>
    <hr />
    <h4>Version 3.2.6</h4>
    <h5>New Features</h5>
    <ul>
      <li>Add Support to upload global cost card data</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      <li>Fix Position ID field showing as non-editable</li>
      <li>Fix Long Names not showing up properly on RAP Form Title</li>
    </ul>
    <hr />
    <h4>Version 3.2.5</h4>
    <h5>New Features</h5>
    <ul>
      <li>Add Multiple Country(Canada) Support to RAP</li>
      <li>
        Add TBH Category Option in Dropdown and a new Field for OPR Number
      </li>
      <li>
        Show TBH Category & Status Icon based on Specific TBH Category Business
        Rules
      </li>
    </ul>
    <hr />
    <h4>Version 3.2.4</h4>
    <h5>New Features</h5>
    <ul>
      <li>Add new feature for users to add values to Dropdowns</li>
      <li>Add new feature to allow Bulk Uploads of RAP Data</li>
    </ul>
    <hr />
    <h4>Version 3.2.3</h4>
    <h5>New Features</h5>
    <ul>
      <li>Automate MSL PIN Prefix Generation in RAP</li>
      <li>Automate PIN Status Selection</li>
    </ul>
    <hr />
    <h4>Version 3.2.2</h4>
    <h5>New Features</h5>
    <ul>
      <li>Introduce Reallocation workflow</li>
      <li>Introduce Copy Record workflow</li>
      <li>Introduce a new User Management Page</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      <li>Fix Login Failures due to Okta Cookies</li>
    </ul>
    <hr />
    <h4>Version 3.2.1</h4>
    <h5>New Features</h5>
    <ul>
      <li>Update Reallocation workflow</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      <li>Remove Employee Journey functionality</li>
    </ul>
    <hr />
    <h4>Version 3.2.0</h4>
    <h5>New Features</h5>
    <ul>
      <li>Update JQuery Library to latest version</li>
      <li>Update Relationship between Client and Client LOB</li>
      <li>Add RAP Reallocate functionality in RAP UI</li>
      <li>Add TBH Category & Employee Type to Advanced Search</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      <li>Fix Sensitive Information Stored in Browser Storage</li>
    </ul>
    <hr />
    <h4>Version 3.1.9</h4>
    <h5>New Features</h5>
    <ul>
      <li>Change RAP PIN Status Location in the RAP Form</li>
      <li>Update RAP DV/ SG&A Toggle Display Labels and Values</li>
      <li>Update CTS Uploader to show Client Matrix Errors properly</li>
      <li>Update CTS Uploader to show Timesheet Errors properly</li>
      <li>
        Update Payroll Department Drop-List to be child dropdown and show
        filtered data
      </li>
    </ul>
    <hr />
    <h4>Version 3.1.8</h4>
    <h5>New Features</h5>
    <ul>
      <li>Enhancement to Exclude Expired PINs from UI search results</li>
      <li>Added New Field - Workday Position ID</li>
      <li>Add Search for Global Key Field</li>
      <li>Added New Field - Employee Type and Cleanup Employee Status</li>
      <li>Fix Error Reporting Logic in CTS Scenario Processing</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      <li>RAP Bug: Unable to Save Record When Only Adding a Note</li>
    </ul>
    <hr />
    <h4>Version 3.1.7</h4>
    <h5>New Features</h5>
    <ul>
      <li>Add New Columns (End Date) to Quick Search Results</li>
    </ul>
    <ul>
      <li>
        Summary Department and Service Line Drop List Options need to be in
        parent and child relationship
      </li>
    </ul>
    <hr />
    <h4>Version 3.1.6</h4>
    <h5>New Features</h5>
    <ul>
      <li>Restrict Record Edits to Defined User Group.</li>
    </ul>
    <ul>
      <li>Advanced Search Feature - Combined Client/Brand Drop-list.</li>
    </ul>
    <ul>
      <li>Added a new Global Key Field.</li>
    </ul>
    <ul>
      <li>Removed Termed Clients from Client Drop-List in Allocations.</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      Save button should be disabled if only Reason for change is modified
    </ul>
    <hr />
    <h4>Version 3.1.5</h4>
    <h5>New Features</h5>
    <ul>
      <li>Add Reconcilability Indicators in Allocations Section in RAP.</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      Remove red mandatory symbol from Client Lob and Start date in allocation
      section.
    </ul>
    <hr />
    <h4>Version 3.1.4</h4>
    <h5>New Features</h5>
    <ul>
      <li>Automatically Fill End Date for Newly Created Records in RAP.</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>Advanced Search - Client Field in RAP will not show duplicates now.</ul>
    <ul>Edit Screen to support an employee having multiple PINs over time.</ul>
    <hr />
    <h4>Version 3.1.3</h4>
    <h5>Updated</h5>
    <ul>
      <li> Re-instated Start and End Date Allocations on Main Form</li>
    </ul>
    <h5>New Features</h5>
    <ul>
      <li>Add functionality to show SUM of allocations in the PIN record.</li>
      <li>
        Show the NAME of eliminated/removed client allocations within Change
        History
      </li>
      <li>
        Allocation number displayed in the header is now limited to two decimal
        places.
      </li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>
      Client Allocations That Dont End in 0 or 5 should not create Long Decimals
    </ul>
    <hr />

    <h4>Version 3.1.2</h4>
    <h5>Updated</h5>
    <ul>
      <li> Removed Start and End Date Allocations</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>Fixed Pin change history to be more readable</ul>
    <hr />

    <h5>Version 3.1.1</h5>
    <h5>New Features</h5>
    <ul>
      <li>Added Change log within Application</li>
      <li>Added QA warning banner</li>
      <li>Updated Pin status color indicators</li>
      <li>Added bug report button</li>
    </ul>
    <h5>Updated</h5>
    <ul>
      <li> Improved Date picker</li>
      <li> Improved styles/formatting on smaller screens</li>
    </ul>
    <h5>Bug Fixes</h5>
    <ul>Removed hard refresh requirement to view changes</ul>

    <h5>Infrastructure changes </h5>
    <ul>
      <li>Formatted/Linted repo</li>
      <li>
        Added automatic formatting/linting Added support for starting
        application locally easier
      </li>
    </ul>
    <hr />

    <h4>Version 3.1.0</h4>
    <h5>New Features</h5>
    <ul>
      <li>Added Start Date and End Date to RAP UI</li>
      <li>Added Collapsible components to the RAP UI.</li>
    </ul>
    <h5>Updated</h5>
    <ul>
      <li>
        Updated Lambda Save method to also update the start date and end date in
        the Rap Allocations data table
      </li>
      <li>
        Performed site wide update to all Records, System Logs and Allocations
        to match the start date of the PIN with the newly added “Start Date”
        value in all active allocations
      </li>
      <li>Updated View for Power Bi to include “ChangedBy” for reporting</li>
      <li>
        Updated Data Table: RAP_ALLOCATIONS to include start and end date fields
        and add it to list of monitored tables
      </li>
      <li>
        Added Bot command to update all Rap Records and Logs with the relevant
        start date information
      </li>
    </ul>
    <h5>Bug Fixes</h5>
    <p>(No bug fixes mentioned in the text..)</p>
  </div>
);

ChangeLog.propTypes = {
  versionNumber: PropTypes.string.isRequired
};

export default ChangeLog;

import Constants from "../common/Constants";
import RapConstants from "../common/RapConstants";
import baseApi from "./BaseApi";
import RapApi from "./RapApi";

/**
 * Abstract the core HTTP call with specific verb functions.
 */
const postRequest = (path, data) =>
  baseApi.baseRequest("POST", RapConstants.API_ROOT_URL + path, data);
const getRequest = (path) =>
  baseApi.baseRequest("GET", RapConstants.API_ROOT_URL + path);

function getCountry() {
  // Get the countryid from localStorage / sesssion
  const defaultCountry = JSON.parse(
    localStorage.getItem(Constants.FINOPS_COUNTRY)
  );
  return defaultCountry?.countryid;
}

async function getAvailablePins(request) {
  const countryid = getCountry();
  if (!request) {
    request = { countryid };
  } else {
    request.countryid = countryid;
  }
  const response = await postRequest("/getPARAvailableMSLPINs", request || {});
  return response || [];
}

async function exportPARStaffPlans(request) {
  const countryid = getCountry();
  if (!request) {
    request = { countryid };
  } else {
    request.countryid = countryid;
  }

  const response = await postRequest("/exportPARStaffPlans", request || {});

  return response || [];
}

async function getPARWorkflows() {
  const response = await postRequest("/getPARWorkflows");
  return response || [];
}

async function actionOnPARRequest(data) {
  return postRequest("/actionOnPARRequest", data);
}

async function getPARRequest(data) {
  return postRequest("/getPARRequest", data);
}

async function savePARRequest(data) {
  if (!data) {
    return;
  }

  if (!data.par_country_id) {
    const countryid = getCountry();
    data.par_country_id = countryid;
  }
  return postRequest("/savePARRequest", data);
}

async function uploadPARFile(fileName, base64FileData, par_request_id) {
  let response = null;

  response = await postRequest("/uploadPARFile", {
    fileName,
    base64FileData,
    par_request_id
  });

  return response;
}

async function exportPARQueue(requestObj) {
  const input = { par_search_filters: requestObj };
  return postRequest("/exportPARQueue", input);
}

async function getParRequestList(requestObj) {
  const input = { par_search_filters: requestObj };
  return postRequest("/getPARRequestList", input);
}

async function getS3PresignedURL(filePath) {
  const input = { filePath };
  return postRequest("/getS3PresignedURL", input);
}

function getPARAlertsForUser() {
  return postRequest("/getPARAlertsForUser", {});
}

async function getParHistory(data) {
  return postRequest("/getPARHistory", data);
}

async function bulkUploadPARPayRangesData(fileName, base64FileData) {
  let response = RapApi.bulkUpload(
    fileName,
    base64FileData,
    "bulkUploadPARPayRangesData"
  );

  return response;
}
async function getPARPayRanges() {
  const response = await postRequest("/getPARPayRanges");
  return response || [];
}

async function deletePARAttachment(filePath, parRequestId) {
  const input = { file_path: filePath, par_request_id: parRequestId };
  return postRequest("/deletePARAttachment", input);
}
async function deletePARRequest(par_request_id) {
  const input = { par_request_id };
  return postRequest("/deletePARRequest", input);
}

async function getGlobalCostData() {
  const response = await postRequest("/getGlobalCostData");
  return response || [];
}

async function bulkUploadPARPINData(fileName, base64FileData) {
  const countryid = getCountry();
  return await RapApi.bulkUpload(
    fileName,
    base64FileData,
    "bulkUploadPARPINData",
    countryid
  );
}

export default {
  getPARRequest,
  getAvailablePins,
  exportPARStaffPlans,
  getPARWorkflows,
  actionOnPARRequest,
  savePARRequest,
  uploadPARFile,
  getParRequestList,
  exportPARQueue,
  getS3PresignedURL,
  getPARAlertsForUser,
  getParHistory,
  bulkUploadPARPayRangesData,
  getPARPayRanges,
  deletePARAttachment,
  deletePARRequest,
  getGlobalCostData,
  bulkUploadPARPINData
};

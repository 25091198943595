import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Cookies from "./helpers/Cookies";
import Constants from "./common/Constants";
import ParConstants from "./common/ParConstants";
import ProgressOverlay from "./components/shared/ProgressOverlay";

// Basic component with logout button
const OktaLogout = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [logoffMsg, setLogoffMsg] = useState(<></>);
  const revokeToken = async (e) => {
    e.preventDefault();
    try {
      // Signout from the application
      await oktaAuth.signOut({
        accessToken: authState.accessToken,
        idToken: authState.idToken,
        postLogoutRedirectUri: `${window.location.origin}/#/logout`
      });
      // Revoke access token
      await oktaAuth.revokeAccessToken(authState.accessToken);
      // Close/destroy the session
      await oktaAuth
        .closeSession()
        .then((sessionClosed) => {
          if (sessionClosed) {
            window.location.reload();
          }
        })
        .catch((ex) => {
          console.error(ex);
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  const logout = async (e) => {
    setIsLoggingOut(true);
    const msg = <>Please wait, signing out..</>;
    setLogoffMsg(msg);

    // Delete the custom cookies
    Cookies.setCookie("X-ats-application", "", null, true);
    Cookies.setCookie(Constants.FINOPS_SESSION_KEY, "", null, true);
    Cookies.setCookie(Constants.FINOPS_LOGIN_KEY, "", null, true);
    Cookies.setCookie(Constants.FINOPS_LOGIN_KEY_APPEND, "", null, true);
    Cookies.setCookie(Constants.FINOPS_LOGIN_USER, "", null, true);

    // get pageSize from localStorage before clearing
    const staffPageSize = localStorage.getItem(
      ParConstants.STAFF_PAGE_LOCAL_STORAGE
    );
    const parPageSize = localStorage.getItem(
      ParConstants.PAR_SUBMISSION_LOCAL_STORAGE
    );
    // Clear localStorage in case there is any
    localStorage.clear();
    // set pageSize back to the localStorage
    localStorage.setItem(ParConstants.STAFF_PAGE_LOCAL_STORAGE, staffPageSize);
    localStorage.setItem(
      ParConstants.PAR_SUBMISSION_LOCAL_STORAGE,
      parPageSize
    );
    // Revoke tokens and sessions
    await revokeToken(e);
    setIsLoggingOut(false);
    setLogoffMsg(<></>);
  };
  return (
    <>
      <a
        onClick={logout}
        alt="Logout User"
        className="nav-logout"
        href=" "
        title="Logout"
      >
        <i className="fas fa-power-off" />
      </a>
      {isLoggingOut && <ProgressOverlay message={logoffMsg} />}
    </>
  );
};

export default OktaLogout;

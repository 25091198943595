import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import StatusChip from "./StatusChip";
import ParConstants from "../../../common/ParConstants";

const TicketHistoryCard = ({ item }) => {
  const [showComments, setShowComments] = useState(false);

  return (
    <>
      <div className="ticket-history" key={item.next_queue}>
        <div className="row history-card-row mr-1 ml-1">
          <div>
            <div className="action">
              {item.next_queue && item.submitted && (
                <strong>Moved to {item.next_queue}</strong>
              )}
              {!item.submitted &&
                (item.current_queue ? (
                  <strong>Comments added in {item?.current_queue}</strong>
                ) : (
                  <strong>{ParConstants.SUBMITTER_COMMENT}</strong>
                ))}
              {!item.next_queue &&
                item.submitted &&
                item.action === ParConstants.PAR_ACTIONS.PAR_COMPLETE && (
                  <strong>PAR is Completed</strong>
                )}
              {!item.next_queue &&
                item.submitted &&
                item.action !== ParConstants.PAR_ACTIONS.PAR_COMPLETE && (
                  <strong style={{ textWrap: "nowrap" }}>
                    Moved back to Submitter
                  </strong>
                )}
            </div>
            <div className="creater">By {item.created_by}</div>
          </div>
          <div className="text-right">
            <div className="queue">
              {item.submitted && (
                <span className="par-status mr-1">
                  <StatusChip action={item.action} />
                </span>
              )}
              <span
                className="ml-1 expand-collapse-comment"
                onClick={() => setShowComments(!showComments)}
              >
                {showComments ? (
                  <span>
                    <i className="fa fa-minus" aria-hidden="true" />
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-plus" aria-hidden="true" />
                  </span>
                )}
              </span>
            </div>
            <div className="time mr-4">
              {item.created_date &&
                format(new Date(item.created_date), "hh:mm a")}
            </div>
          </div>
        </div>
        {showComments && (
          <div className="row comment-container">
            <div className="col-12 comment mt-2">
              <div className="comment-text pt-1">
                {item.comment || "No comments"}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

TicketHistoryCard.propTypes = {
  item: PropTypes.shape({
    action: PropTypes.string,
    current_queue: PropTypes.string,
    next_queue: PropTypes.string,
    created_date: PropTypes.string,
    created_by: PropTypes.string,
    comment: PropTypes.string,
    submitted: PropTypes.bool
  })
};

TicketHistoryCard.defaultProps = {
  item: {
    action: null,
    current_queue: null,
    next_queue: null,
    created_date: null,
    created_by: null,
    comment: null,
    submitted: false
  }
};

export default TicketHistoryCard;

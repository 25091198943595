const AUTH_SUCCESS = "success";
const AUTH_NO_TOKEN = "no_token";
const AUTH_INVALID_TOKEN = "invalid_token";

// eslint-disable-next-line prefer-destructuring
const API_ROOT_URL = process.env.API_ROOT_URL;
// eslint-disable-next-line prefer-destructuring
const DEFAULT_PIN_STATUS = "Eliminated";
const DEFAULT_DV_SGA_VALUE = "SG & A";

const EMPTY_RECORD_ID = "-empty-";

const FORM_FIELDS = {
  pin: "PIN",
  mslPin: "MSL PIN",
  workdayId: "Workday Id",
  workdayTitle: "Workday Title",
  adpId: "ADP ID",
  status: "Employee Status",
  employeeType: "Employee Type",
  givenName: "Given Name",
  familyName: "Family Name",
  location: "Location",
  payrollCompany: "Payroll - Company",
  payrollProfitCenter: "Payroll - Profit Center",
  level: "Level",
  pinApproved: "Approved PIN",
  dvSga: "DV/SG&A",
  summaryDepartment: "Summary Department",
  serviceLineDepartment: "Service Line Department",
  payrollDepartment: "Payroll Department",
  requisitionId: "Requisition Id",
  lastUpdatedAt: "Date of Last Change",
  changeReason: "Change Reason",
  notes: "Notes",
  allocations: "Allocations",
  tbhCategory: "TBH Category",
  startDate: "Start Date",
  endDate: "End Date",
  globalKey: "Global Key",
  workdayPositionId: "workdayPositionId",
  oprId: "PAR#",
  onshoreManager: "Onshore Manager"
};

const LIST_FIELDS = [
  "workdayTitle",
  "client",
  "clientBrand",
  "clientLob",
  "location",
  "payrollCompany",
  "payrollDepartment",
  "payrollProfitCenter",
  "level",
  "tbhCategory",
  "status",
  "employeeType",
  "serviceLineDepartment",
  "summaryDepartment"
];

const DROPDOWN_TYPE_LIST = [
  "Location",
  "Payroll - Company",
  "Payroll - Profit Center",
  "Workday Title",
  "Summary Department",
  "Service Line Department",
  "Payroll Department",
  "Client Brand",
  "Client LOB"
];

const PROPERTY_NAME_LIST = {
  Location: "locationList",
  "Payroll - Company": "payrollCompanyList",
  "Payroll - Profit Center": "payrollProfitCenterList",
  "Workday Title": "roleTitleList",
  "Summary Department": "summaryDepartmentList",
  "Service Line Department": "serviceLineDepartmentList",
  "Payroll Department": "payrollDepartmentList",
  "Client Brand": "clientList",
  "Client LOB": "clientLobList",
  "TBH Category": "tbhCategory"
};

const LIST_TYPES = {
  globalPerm: "globalPerm",
  tempContingent: "tempContingent",
  activeOptions: "activeOptions",
  otherOptions: "otherOptions"
};

const EMPLOYEE_STATUS_VALUES = ["Active", "TBH", "Termed", "On Leave"];

const EMPLOYEE_TYPE_VALUES = [
  "Permanent",
  "Global",
  "Temporary",
  "Contingent",
  "Permanent (PT)"
];

const PIN_STATUS_VALUES = ["Active", "Eliminated", "TBH"];

const BOOLEAN_VALUES = ["Yes", "No"];

const EMPLOYEE_PIN_STATUS = {
  Active: "Active",
  "On Leave": "Active",
  Termed: "TBH",
  TBH: "TBH"
};

const TBH_CATEGORY = {
  pendingPAR: "Pending PAR",
  holdForPair: "Hold for Pair",
  hold: "Hold",
  recruiting: "Recruiting",
  global: "Global Recruiting",
  activePAR: "Active PAR",
  internalFill: "Internal Fill",
  filled: "Filled"
};

export default {
  AUTH_SUCCESS,
  AUTH_NO_TOKEN,
  AUTH_INVALID_TOKEN,
  API_ROOT_URL,

  DEFAULT_PIN_STATUS,
  DEFAULT_DV_SGA_VALUE,

  EMPTY_RECORD_ID,
  EMPLOYEE_STATUS_VALUES,
  EMPLOYEE_TYPE_VALUES,
  PIN_STATUS_VALUES,
  EMPLOYEE_PIN_STATUS,
  TBH_CATEGORY,

  FORM_FIELDS,
  LIST_FIELDS,
  BOOLEAN_VALUES,
  DROPDOWN_TYPE_LIST,
  PROPERTY_NAME_LIST,
  LIST_TYPES
};

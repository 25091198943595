/**
 * Security script, to hold handy user permission checks.
 *
 * Note: with a session, the index script should set the user object to the window.
 * A valid user object will have a 'groups' array property with allowed permission groups.
 */

// Function names can be whatever makes the most sense with the implementing code readability.
import ParConstants from "../common/ParConstants";

function checkPermission(perm) {
  // check for the user on the window
  let winUser = window.userSession;
  if (!winUser || !winUser.groups) return false;

  // map to avoid array scans for performance
  if (!winUser.groupMap) {
    winUser.groupMap = winUser.groups.reduce(
      (agg, group) => (agg[group] = true) && agg,
      {}
    );
  }

  // return if the property is there
  return winUser.groupMap[perm] || false;
}

export default {
  // Super User
  isAdmin: () => checkPermission("ats_admin-group"),
  // Overall FinOps permissions
  canFinOps: () => checkPermission("finOps_app"),
  // RAP Page permissions
  canEditRAPData: () => checkPermission("finOps_RAP-approle"),
  // RAP Report Page Permissions
  canViewRAPReports: () => checkPermission("finOps_RAP-reports-approle"),
  // Edit CTS Data Permissions, screen after scenarios page
  canEditCTSData: () => checkPermission("finOps_CTS-approle"),
  // Load Scenarios Page
  canLoadCTSData: () => checkPermission("finOps_CTS-dataLoad-approle"),
  canViewCTSReports: () => checkPermission("finOps_CTS-reports-approle"),
  // Modify RAP Records
  canModifyRapRecords: () =>
    checkPermission("finOps_RAP-RM-approle") ||
    checkPermission("ats_admin-group"),
  canEditPARData: () => checkPermission("finOps_PAR-approle"),
  canUploadPARData: () => checkPermission("finOps_PAR-HR-userRole"),
  isSubmitterRole: () =>
    window.userSession?.parPermissions?.permission_name ===
    ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter
};

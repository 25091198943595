/* eslint-disable no-return-assign */
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { isBefore } from "date-fns";
import * as Yup from "yup";
import ParForm from "./ParForm";
import parApi from "../../../services/ParApi";
import rapApi from "../../../services/RapApi";
import ParConstants from "../../../common/ParConstants";
import LoadingSpinner from "../../shared/LoadingSpinner";
import "../../../styles/shared/collapsible-card.scss";
import RapConstants from "../../../common/RapConstants";
import DateTime from "../../../helpers/DateTime";
import {
  isGlobalEmployee,
  isValidNumberWithCommas
} from "../../../helpers/ParHelpers";
import useWorkflowStatus from "../../hooks/par/useWorkflowStatus";
import security from "../../../services/Security";

const CreatePAR = () => {
  let location = useLocation();

  // Fetch parRequestId from route parameters
  const { parRequestIdParam } = useParams();
  const navigate = useNavigate();

  const [validations, setValidations] = useState(
    location.state && location.state.validations
  );
  const [initialData, setInitialData] = useState(null);
  const [parType, setParType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const [skipValidations, setSkipValidations] = useState([
    "par_request_id",
    "current_queue_id",
    "business_title",
    "attachment_paths",
    "target_effective_date",
    "comment",
    "employee_workdayid",
    "employee_workdaytitle",
    "employee_location",
    "submitter",
    "par_position_title",
    "par_axcompany",
    "par_bpc",
    "par_brand",
    "par_payroll_company",
    "current_queue_name",
    "related_pars",
    "target_budget",
    "ticket_status",
    "par_billable",
    "submitted_date",
    "submitter_comments",
    "backfill_name",
    "backfill_workday_id",
    "backfill_employee_type",
    "backfill_location",
    "promotion_business_title",
    "promotion_position_title",
    "employee_partner_cost",
    "employee_partner",
    "decision"
  ]);
  const isSubmitterRole = security.isSubmitterRole();

  const {
    isUrgentCounterOfferWorkflow,
    isBackfillWorkflow,
    isAddToStaffRequestWorkflow,
    isReallocationActive,
    isReallocationTBH,
    isPromotionInPlace,
    isSalaryAdjustmentWorkflow,
    isOpenRequestTBHWorkflow
  } = useWorkflowStatus(parType);

  const prepareDataFromRAP = (record) => {
    let newData = {};
    const workflowName = parType?.name || location?.state?.workflowType?.name;
    const { BACKFILL_REQUEST, REALLOCATION_TBH, OPEN_REQUEST_TBH } =
      ParConstants.PAR_WORKFLOW;
    if (record) {
      newData = {
        existing_msl_pin: record.mslPin,
        summary_department: record.summaryDepartment,
        serviceline_department: record.serviceLineDepartment,
        par_level: (record.level || "").toUpperCase(),
        employee_type: record.employeeType,
        fill_employee_type: record.employeeType,
        fill_location: "",
        par_billable: record.dvSga,
        is_exempt: "",
        profit_center: record.payrollProfitCenter,
        job_code: "",
        business_title: record.workdayTitle,
        employee_partner: "",
        employee_partner_cost: null
      };

      if (workflowName === BACKFILL_REQUEST) {
        newData.open_requisition_reason = "Backfill";
        newData.backfill_name = `${record.givenName} ${record.familyName}`;
        newData.backfill_workday_id = record.workdayId;
        newData.backfill_employee_type = record.employeeType;
        newData.backfill_location = record.location;
      } else if (
        record.workdayId &&
        record.workdayId !== "" &&
        workflowName !== OPEN_REQUEST_TBH
      ) {
        newData.employee_workdayid = record.workdayId;
        newData.employee_name = `${record.givenName} ${record.familyName}-${record.workdayId}`;
        newData.employee_workdaytitle = record.workdayTitle;
        newData.employee_location = record.location;
        newData.employee_type = record.employeeType;
      }
      if (workflowName === REALLOCATION_TBH) {
        newData.reallocation_level = record.reallocation_level || record.level;
        newData.reallocation_employee_type =
          record.reallocation_serviceline_department || record.employeeType;
        newData.reallocation_serviceline_department =
          record.reallocation_serviceline_department ||
          record.serviceLineDepartment;
      }

      // Skip eliminated allocations, only show active or tbh
      const allocations = record.allocations.filter(
        (a) => a.status !== "Eliminated"
      );
      if (allocations && allocations.length > 0) {
        newData.allocations = allocations.map((x) => ({
          allocation: x.allocation,
          client_brand: x.client,
          client_lob: x.clientLob,
          rate_card: x.rateCard,
          reconcilable: x.reconcilable,
          status: x.status,
          disableAllocation: true
        }));
      }
    }
    return newData;
  };

  const loadInitialData = async (isRefresh = false, inputPAR = null) => {
    setIsLoading(true);
    if (!isRefresh) {
      setLoadingMsg("Please wait, PAR Details are being loaded...");
    } else {
      setLoadingMsg("Please wait, re-loading saved PAR details");
    }
    if (location.state || parRequestIdParam || inputPAR) {
      let parRequestId = inputPAR;
      let mslData = null;
      let workflowType = null;

      if (location && location.state && !inputPAR) {
        parRequestId = location.state.parRequestId;
        mslData = location.state.mslData;
        workflowType = location.state.workflowType;
      } else if (parRequestIdParam && !inputPAR) {
        parRequestId = parRequestIdParam;
      }

      // If its a details screen, par request id will be present
      if (parRequestId) {
        const response = await parApi.getPARRequest({
          par_request_id: parRequestId
        });
        if (!response.par_request_id) {
          // Show error page in case of an invalid URL
          navigate("/error", {
            state: {
              errorTitle: "Invalid URL",
              errorMessage:
                "Invalid PAR Ticket Link! Please fix the URL and reload the page."
            }
          });
        }
        if (
          response.workflow_name === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH
        ) {
          response.reallocation_level =
            response.reallocation_level || response.par_level;
          response.reallocation_employee_type =
            response.reallocation_serviceline_department ||
            response.employee_type;
          response.reallocation_serviceline_department =
            response.reallocation_serviceline_department ||
            response.serviceline_department;
        }
        if (response.allocations && response.allocations.length > 0) {
          response.allocations = response.allocations.map((allocation) => {
            allocation.rate_card = Number(
              allocation.rate_card
            ).toLocaleString();
            return allocation;
          });
        }

        if (
          isSubmitterRole &&
          response.workflow_name ===
            ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST
        ) {
          response.ats_employee_type = response.employee_type;
        }
        response.comment = "";
        setInitialData(response);
        // Always require a comment if a request is in a specific QUEUE
        if (response.current_queue_id) {
          setSkipValidations(
            Object.keys(ParConstants.FORM_FIELDS).filter((x) => x !== "comment")
          );
        }
        // Get workflow info to be set as PAR Type
        const workflowInfo = {
          workflow_id: response.workflow_id,
          name: response.workflow_name
        };
        setParType(workflowInfo);
      } else if (mslData && mslData.mslPin) {
        // If create par is called from existing PIN
        const response = await rapApi.getRecord(mslData.mslPin);
        const inputData = prepareDataFromRAP(response);
        setInitialData(inputData);
        setParType(workflowType);
      } else {
        // For Add To Staff workflow
        setParType(location?.state?.workflowType);
      }
    }
    setIsLoading(false);
  };

  const loadValidationValues = async () => {
    try {
      await rapApi
        .getValidations({ activeInPAR: "true" })
        .then((response) => setValidations(response));
    } catch (ex) {
      console.log(ex);
    }
  };

  const getValidationSchema = () => {
    const errorMsg = "Please enter a value";
    const errorMsgSelect = "Please select a value";
    const schemaObj = {};
    Object.keys(ParConstants.FORM_FIELDS).forEach((field) => {
      if (!schemaObj[field]) {
        if (field === "allocations") {
          // Only validatate client and allocation, and do not validate the fields
          schemaObj[field] = Yup.array()
            .of(
              Yup.object().shape({
                allocation: Yup.number()
                  .min(0, "should be greater than 0")
                  .max(1, "should not be more than 1")
                  .typeError("should be a number")
                  .required(errorMsg),
                client_brand: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect),
                    value: Yup.string().required(errorMsgSelect)
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                rate_card: Yup.string()
                  .test(
                    "is-valid-number",
                    "Rate Card should be a valid number",
                    (value) => {
                      if (!value || value === "") {
                        return true;
                      }
                      return (
                        isValidNumberWithCommas(value) &&
                        !Number.isNaN(parseFloat(value?.replace(/,/g, "")))
                      );
                    }
                  )
                  .test(
                    "rate-card-req",
                    "Please enter a valid value for Rate Card",
                    (value) => {
                      let isValid = true;
                      // An empty record is allowed for a submitter role
                      if (!value || value === "") {
                        isValid =
                          !!isSubmitterRole || !!isUrgentCounterOfferWorkflow;
                      }
                      return isValid;
                    }
                  ),
                reconcilable: Yup.string().test(
                  "reconcilable-req",
                  "Select a value for Reconcilable",
                  (value) => {
                    let isValid = true;
                    // An empty record is allowed for a submitter role
                    if (!value || value === "") {
                      isValid = !!isSubmitterRole;
                    }
                    return isValid;
                  }
                )
              })
            )
            .test(
              "alloc-req",
              "Please add at least one allocation",
              (allocArr) => allocArr.length >= 1
            );
        } else if (field === "partner") {
          schemaObj[field] = Yup.object().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltypeval, emptypeval) =>
                filltypeval &&
                filltypeval === ParConstants.FILL_TYPES[0] &&
                emptypeval &&
                emptypeval === RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                !isSubmitterRole,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            }
          );
        } else if (field === "backfill_reason") {
          schemaObj[field] = Yup.object().when([], {
            is: () => isBackfillWorkflow,
            then: Yup.object()
              .shape({
                label: Yup.string().required(errorMsgSelect).nullable(),
                value: Yup.string().required(errorMsgSelect).nullable()
              })
              .required(errorMsgSelect)
              .nullable(),
            otherwise: Yup.object()
              .shape({
                label: Yup.string().nullable(),
                value: Yup.string().nullable()
              })
              .nullable()
          });
        } else if (field === "offshore_role") {
          schemaObj[field] = Yup.object().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltypeval, emptypeval) =>
                filltypeval &&
                filltypeval === ParConstants.FILL_TYPES[0] &&
                emptypeval &&
                emptypeval === RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                !isSubmitterRole,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            }
          );
        } else if (field === "job_code") {
          schemaObj[field] = Yup.object().when(
            ["employee_type.value", "current_queue_name"],
            {
              is: (emptypeval, queuename) =>
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE &&
                emptypeval &&
                !isGlobalEmployee(emptypeval),
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            }
          );
        } else if (field === "profit_center") {
          schemaObj[field] = Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string()
            })
            .test(
              "profitcenter-req",
              "Select a value for Profit Center",
              (profitCenterVal) => {
                let isValid = true;
                // An empty record is allowed for a submitter role
                if (
                  !profitCenterVal ||
                  !profitCenterVal.value ||
                  profitCenterVal.value === ""
                ) {
                  isValid = !!isSubmitterRole;
                }
                return isValid;
              }
            )
            .nullable();
        } else if (field === "open_requisition_reason") {
          schemaObj[field] =
            isAddToStaffRequestWorkflow ||
            isBackfillWorkflow ||
            isOpenRequestTBHWorkflow
              ? Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable()
              : Yup.object().nullable();
        } else if (field === "par_level") {
          schemaObj[field] =
            isAddToStaffRequestWorkflow || isOpenRequestTBHWorkflow
              ? Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable()
              : Yup.object().nullable();
        } else if (field === "target_effective_date") {
          schemaObj[field] = Yup.string().when(["current_queue_name"], {
            is: (queuename) =>
              // Target Effective Date is editable at Submitter and HR Intake level
              // and shows all workflows except ATS, Open Req TBH, Backfill
              (isSubmitterRole ||
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE) &&
              (isUrgentCounterOfferWorkflow ||
                isPromotionInPlace ||
                isReallocationTBH ||
                isSalaryAdjustmentWorkflow ||
                isReallocationActive),
            then: Yup.string()
              .test(
                "is-required",
                `${field} is a required field`,
                (value) =>
                  !(
                    isUrgentCounterOfferWorkflow &&
                    (!value || value.trim() === "")
                  )
              )
              .test(
                "date-format",
                "Date should be in MM/dd/yyyy format",
                (value) => DateTime.isValidDateFormat(value)
              )
              .test(
                "future date",
                `${field} should be a future date`,
                (value) => {
                  if (field === "target_effective_date") {
                    if (!value || value === "") {
                      return true;
                    }
                    return !isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                }
              )
              .required(`is a required field`)
              .nullable()
          });
        } else if (field === "approved_effective_date") {
          schemaObj[field] = Yup.string().when(["current_queue_name"], {
            is: (queuename) =>
              !isSubmitterRole &&
              (queuename === ParConstants.PAR_QUEUE.FINANCE ||
                queuename === ParConstants.PAR_QUEUE.BRAND_CEO ||
                queuename === ParConstants.PAR_QUEUE.EXCECUTIVE),
            then: Yup.string()
              .test(
                "is-required",
                `${field} is a required field`,
                (value) => !(!value || value.trim() === "")
              )
              .test(
                "date-format",
                "Date should be in MM/dd/yyyy format",
                (value) => DateTime.isValidDateFormat(value)
              )
              .test(
                "future date",
                `${field} should be a future date`,
                (value) => {
                  if (field === "approved_effective_date") {
                    if (!value || value === "") {
                      return true;
                    }
                    return !isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                }
              )
              .required(`is a required field`)
              .nullable()
          });
        } else if (field === "fill_date") {
          schemaObj[field] = Yup.string().when(["current_queue_name"], {
            is: (queuename) =>
              // Target Fill Date is editable at Submitter and HR Intake level
              // and shows in ATS, Open Req TBH, Backfill
              (isSubmitterRole ||
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE) &&
              (isAddToStaffRequestWorkflow ||
                isOpenRequestTBHWorkflow ||
                isBackfillWorkflow),
            then: Yup.string()
              .test(
                "is-required",
                `${field} is a required field`,
                (value) => !(!value || value.trim() === "")
              )
              .test(
                "date-format",
                "Date should be in MM/dd/yyyy format",
                (value) => DateTime.isValidDateFormat(value)
              )
              .test(
                "future date",
                `${field} should be a future date`,
                (value) => {
                  if (field === "fill_date") {
                    if (!value || value === "") {
                      return true;
                    }
                    return !isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                }
              )
              .required(`is a required field`)
              .nullable()
          });
        } else if (field === "reallocation_details") {
          schemaObj[field] = Yup.string().when([], {
            is: () => isReallocationActive,
            then: Yup.string().trim().required(errorMsg).nullable(),
            otherwise: Yup.string().nullable()
          });
        } else if (field === "current_performance_rating") {
          schemaObj[field] = Yup.object().when(
            ["current_queue_name", "employee_type.value", "fill_type"],
            {
              is: (queuename, emptype, fillType) =>
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE &&
                (fillType?.value === ParConstants.FILL_TYPES[1] ||
                  fillType?.value === ParConstants.FILL_TYPES[1]) &&
                !isGlobalEmployee(emptype),
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            }
          );
        } else if (field === "date_of_lastpromotion") {
          schemaObj[field] = Yup.string().when(
            [
              "current_queue_name",
              "employee_type.value",
              "fill_type",
              "is_enabled_date_of_lastpromotion"
            ],
            {
              is: (
                queuename,
                emptype,
                fillType,
                isEnabledDateOfLastPromotion
              ) =>
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE &&
                (fillType?.value === ParConstants.FILL_TYPES[1] ||
                  fillType?.value === ParConstants.FILL_TYPES[1]) &&
                !isGlobalEmployee(emptype) &&
                !isEnabledDateOfLastPromotion,
              then: Yup.string()
                .required(`is a required field`)
                .test(
                  "date-format",
                  "Date should be in MM/dd/yyyy format",
                  (value) => DateTime.isValidDateFormat(value)
                )
                .test("past date", `should be a past date`, (value) => {
                  if (field === "date_of_lastpromotion") {
                    if (!value || value === "") {
                      return true;
                    }
                    return isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                }),
              otherwise: Yup.string()
                .test(
                  "date-format",
                  "Date should be in MM/dd/yyyy format",
                  (value) => DateTime.isValidDateFormat(value)
                )
                .test("past date", `should be a past date`, (value) => {
                  if (field === "date_of_lastpromotion") {
                    if (!value || value === "") {
                      return true;
                    }
                    return isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                })
                .nullable()
            }
          );
        } else if (field === "date_of_last_compensation_change") {
          schemaObj[field] = Yup.string().when(
            [
              "current_queue_name",
              "employee_type.value",
              "fill_type",
              "is_enabled_date_of_last_compensation_change"
            ],
            {
              is: (
                queuename,
                emptype,
                fillType,
                isEnabledDateOfLastCompensationChange
              ) =>
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE &&
                (fillType?.value === ParConstants.FILL_TYPES[1] ||
                  fillType?.value === ParConstants.FILL_TYPES[1]) &&
                !isGlobalEmployee(emptype) &&
                !isEnabledDateOfLastCompensationChange,
              then: Yup.string()
                .required(`is a required field`)
                .test(
                  "date-format",
                  "Date should be in MM/dd/yyyy format",
                  (value) => DateTime.isValidDateFormat(value)
                )
                .test("past date", `should be a past date`, (value) => {
                  if (field === "date_of_last_compensation_change") {
                    if (!value || value === "") {
                      return true;
                    }
                    return isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                }),
              otherwise: Yup.string()
                .test(
                  "date-format",
                  "Date should be in MM/dd/yyyy format",
                  (value) => DateTime.isValidDateFormat(value)
                )
                .test("past date", `should be a past date`, (value) => {
                  if (field === "date_of_last_compensation_change") {
                    if (!value || value === "") {
                      return true;
                    }
                    return isBefore(new Date(value), new Date(new Date()));
                  }
                  return true;
                })
                .nullable()
            }
          );
        } else if (field === "attachment_paths") {
          schemaObj[field] = Yup.string().when("allocations", {
            is: (value) =>
              value.length > 1 &&
              [
                ...new Set(
                  value
                    .filter((x) => x.client_brand)
                    .map((x) => x.client_brand.value.split(" - ")[1])
                )
              ].length > 1,
            then: Yup.string()
              .required(
                "Allocations have been added for different brands. Please attach files with approvals from other brands"
              )
              .nullable(),
            otherwise: Yup.string().nullable()
          });
        } else if (field === "current_base_salary") {
          schemaObj[field] = Yup.number().when(
            ["current_queue_name", "employee_type.value", "fill_type"],
            {
              is: (queuename, emptype, fillType) =>
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE &&
                (fillType?.value === ParConstants.FILL_TYPES[1] ||
                  fillType?.value === ParConstants.FILL_TYPES[1]) &&
                !isGlobalEmployee(emptype),
              then: Yup.number()
                .min(0, "Value must be non-negative")
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.number().min(0, "Value must be non-negative")
            }
          );
        } else if (field === "requested_offer") {
          // Requested offer is required
          schemaObj[field] = Yup.number().when(
            ["current_queue_name", "employee_type.value"],
            {
              is: (queuename, emptype) =>
                (isAddToStaffRequestWorkflow ||
                  isOpenRequestTBHWorkflow ||
                  isUrgentCounterOfferWorkflow ||
                  isBackfillWorkflow ||
                  isSalaryAdjustmentWorkflow ||
                  isPromotionInPlace) &&
                queuename &&
                queuename === ParConstants.PAR_QUEUE.HR_INTAKE &&
                !isGlobalEmployee(emptype),
              then: Yup.number()
                .min(0, "Value must be non-negative")
                .required(errorMsgSelect)
                .nullable()
            }
          );
        } else if (field === "approved_offer") {
          // Approved Offer is required for queue levels finance, brand ceo, executive
          // for all workflows except Reallocation Active & TBH
          schemaObj[field] = Yup.number().when(["current_queue_name"], {
            is: (queuename) =>
              !(isReallocationActive || isReallocationTBH) &&
              (queuename === ParConstants.PAR_QUEUE.FINANCE ||
                queuename === ParConstants.PAR_QUEUE.BRAND_CEO ||
                queuename === ParConstants.PAR_QUEUE.EXCECUTIVE),
            then: Yup.number()
              .min(0, "Value must be non-negative")
              .required(errorMsg)
              .nullable(),
            otherwise: Yup.number().nullable()
          });
        } else if (
          ParConstants.LIST_FIELDS.includes(field) &&
          !skipValidations.includes(field)
        ) {
          // Skip validations for some fields that are not required
          if (field === "employee_type") {
            schemaObj[field] = Yup.object().when("fill_type.value", {
              is: ParConstants.FILL_TYPES[0], // condition based on the value property of the object
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "ats_employee_type") {
            schemaObj[field] = Yup.object().when([], {
              is: () => isAddToStaffRequestWorkflow && isSubmitterRole,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "fill_location" || field === "location_reason") {
            schemaObj[field] = Yup.object().when(
              ["fill_type.value", "employee_type.value"],
              {
                is: (filltypeval, emptypeval) =>
                  filltypeval &&
                  filltypeval === ParConstants.FILL_TYPES[0] &&
                  emptypeval &&
                  emptypeval !== RapConstants.EMPLOYEE_TYPE_VALUES[1],
                then: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                otherwise: Yup.object()
                  .shape({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable()
                  })
                  .nullable()
              }
            );
          } else if (field === "employee_name") {
            schemaObj[field] = Yup.object().when("fill_type.value", {
              is: (value) =>
                [
                  ParConstants.FILL_TYPES[1],
                  ParConstants.FILL_TYPES[2]
                ].includes(value),
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "cc_emails") {
            schemaObj[field] = Yup.array()
              .of(
                Yup.object().shape({
                  label: Yup.string(),
                  value: Yup.string()
                })
              )
              .test(
                "email",
                "Please enter valid email address(es)",
                (value) => {
                  let anyobj = [];
                  if (!value) {
                    return true;
                  }
                  if (value && value.length > 0) {
                    const regex =
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
                    anyobj = value.filter((x) => regex.test(x && x.value));
                  }
                  return anyobj.length === value.length;
                }
              )
              .test(
                "email",
                "Only dentsu and merkle email addresses are allowed",
                (value) => {
                  let anyobj = [];
                  if (!value) {
                    return true;
                  }
                  if (value && value.length > 0) {
                    anyobj = value.filter((x) =>
                      ParConstants.ALLOWED_EMAILS.includes(
                        x.value.split("@")[1]
                      )
                    );
                  }
                  return anyobj.length === value.length;
                }
              )
              .nullable();
          } else if (field === "fill_type" || field === "hiring_manager") {
            schemaObj[field] = Yup.object().when([], {
              is: () =>
                isUrgentCounterOfferWorkflow ||
                isPromotionInPlace ||
                isReallocationTBH ||
                isSalaryAdjustmentWorkflow ||
                isReallocationActive,
              then: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .nullable()
                .required(errorMsgSelect)
            });
          } else if (field === "reallocation_reason") {
            schemaObj[field] = Yup.object().when([], {
              is: () => isReallocationTBH || isReallocationActive,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "reallocation_level") {
            schemaObj[field] = Yup.object().when(
              ["reallocation_reason.value"],
              {
                is: (reallocationReason) =>
                  (reallocationReason ===
                    ParConstants.REALLOCATION_REASONS[0] ||
                    reallocationReason ===
                      ParConstants.REALLOCATION_REASONS[1]) &&
                  isReallocationTBH,
                then: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                otherwise: Yup.object()
                  .shape({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable()
                  })
                  .nullable()
              }
            );
          } else if (field === "reallocation_serviceline_department") {
            schemaObj[field] = Yup.object().when(
              ["reallocation_reason.value"],
              {
                is: (reallocationReason) =>
                  reallocationReason === "Service Line Change" &&
                  isReallocationTBH,
                then: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                otherwise: Yup.object()
                  .shape({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable()
                  })
                  .nullable()
              }
            );
          } else if (field === "reallocation_reassign_allocation_to") {
            schemaObj[field] = Yup.object().when([], {
              is: () => isReallocationActive,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "reallocation_employee_type") {
            schemaObj[field] = Yup.object().when(
              ["reallocation_reason.value"],
              {
                is: (reallocationReason) =>
                  (reallocationReason ===
                    ParConstants.REALLOCATION_REASONS[2] ||
                    reallocationReason ===
                      ParConstants.REALLOCATION_REASONS[3]) &&
                  isReallocationTBH,
                then: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                otherwise: Yup.object()
                  .shape({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable()
                  })
                  .nullable()
              }
            );
          } else if (field === "requested_level") {
            schemaObj[field] = Yup.object().when([], {
              is: () => isPromotionInPlace,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object().nullable()
            });
          } else {
            schemaObj[field] = Yup.object()
              .shape({
                label: Yup.string().required(errorMsgSelect),
                value: Yup.string().required(errorMsgSelect)
              })
              .required(errorMsgSelect)
              .nullable();
          }
        } else if (field === "partner_cost") {
          schemaObj[field] = Yup.number().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltype, emptype) =>
                filltype &&
                filltype === ParConstants.FILL_TYPES[0] &&
                emptype &&
                emptype === RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                !isSubmitterRole,
              then: Yup.number()
                .typeError("should be a number")
                .required(errorMsg)
            }
          );
        } else if (field === "is_exempt") {
          schemaObj[field] = Yup.string().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltype, emptype) =>
                filltype &&
                filltype === ParConstants.FILL_TYPES[0] &&
                emptype &&
                emptype !== RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                parType &&
                (isBackfillWorkflow ||
                  isOpenRequestTBHWorkflow ||
                  isAddToStaffRequestWorkflow),
              then: Yup.string().trim().required(errorMsgSelect)
            }
          );
        } else if (field === "requisition_id") {
          const queueNames = [
            ParConstants.PAR_QUEUE.HR_ACTION,
            ParConstants.PAR_QUEUE.RM_ACTION
          ];
          schemaObj[field] = Yup.string().when(
            ["employee_type.value", "current_queue_name"],
            {
              is: (emptype, queuename) =>
                (isBackfillWorkflow ||
                  isOpenRequestTBHWorkflow ||
                  isAddToStaffRequestWorkflow) &&
                queuename &&
                queueNames.includes(queuename) &&
                emptype &&
                emptype !== RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
                !isSubmitterRole,
              then: Yup.string()
                .trim()
                .required("Please enter a Requisition ID")
                .max(10, "Requisition ID must not be more than 10 characters.")
                .matches(/^R/, 'Requisition ID must start with "R"')
            }
          );
        } else if (field === "position_id") {
          schemaObj[field] = Yup.string().when(
            ["employee_type.value", "current_queue_name"],
            {
              is: (emptype, queuename) =>
                (isBackfillWorkflow ||
                  isPromotionInPlace ||
                  isAddToStaffRequestWorkflow ||
                  isOpenRequestTBHWorkflow) &&
                queuename &&
                queuename === ParConstants.PAR_QUEUE.RM_ACTION &&
                !isGlobalEmployee(emptype) &&
                !isSubmitterRole,
              then: Yup.string()
                .trim()
                .required("Please enter a Position ID")
                .max(10, "Position ID must not be more than 10 characters.")
                .matches(
                  /^P\d{8}$/,
                  'Position ID must start with "P" and have 8 digits'
                )
            }
          );
        } else if (field === "mslPin") {
          schemaObj[field] = Yup.string()
            .trim()
            .test(
              "only-alphabets",
              "Only uppercase letters and numbers are allowed.",
              (value) => {
                // An empty record is allowed
                if (!value || value === "") {
                  return true;
                }
                // Only upper case letters and numbers are allowed
                return /^[A-Z-0-9]+$/.test(value);
              }
            );
        } else if (field === "promotion_business_title" && isPromotionInPlace) {
          schemaObj[field] = Yup.string().required(errorMsg);
        } else if (!skipValidations.includes(field)) {
          // Skip validations for some fields that are not required
          schemaObj[field] = Yup.string().required(errorMsg);
        }
        if (["pinApproved", "dvSga"].includes(field)) {
          schemaObj[field] = Yup.string().trim().required(errorMsg);
        }
      }
    });
    return Yup.object(schemaObj);
  };

  const refreshFormData = (parRequestId) => {
    loadInitialData(true, parRequestId);
    if (!validations) {
      // Reloading validation values if not passed
      loadValidationValues();
    }
  };

  useEffect(() => {
    loadInitialData();
    if (!validations) {
      // Reloading validation values if not passed
      loadValidationValues();
    }
  }, [parRequestIdParam]);

  return (
    <div className="par-container">
      {(!validations || isLoading) && (
        <LoadingSpinner status="Loading" textMessage={loadingMsg} />
      )}
      {validations && !isLoading && (
        <ParForm
          getValidationSchema={getValidationSchema}
          skipValidations={skipValidations}
          validations={validations}
          initialData={initialData}
          parType={parType}
          queueType={location.state && location.state.queueType}
          parAvailablePins={location.state && location.state.parAvailablePins}
          parPayRanges={location.state && location.state.parPayRanges}
          refreshForm={refreshFormData}
          activeTab={location?.state?.activeTab}
        />
      )}
    </div>
  );
};

export default CreatePAR;

/* eslint-disable no-return-assign */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isValid, format } from "date-fns";
import { toast } from "react-toastify";
import "../../../styles/shared/collapse-panel.scss";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import security from "../../../services/Security";
import api from "../../../services/ParApi";
import Constants from "../../../common/Constants";
import RapConstants from "../../../common/RapConstants";
import ParConstants from "../../../common/ParConstants";
import ParStatus from "./ParStatus";
import PositionJustification from "./PositionJustification";
import RequisitionInstructions from "./RequisitionInstructions";
import ReallocationInstructions from "./ReallocationInstructions";
import PositionDetails from "./PositionDetails";
import Modal from "../../shared/Modal";
import ConfirmationModal from "../../shared/ConfirmationModal";

import TicketAction from "./TicketAction";
import Sidebar from "../../shared/Sidebar";
import TicketHistory from "../ticket_history/TicketHistory";
import EmployeeDetails from "./EmployeeDetails";

import ParHelpers, {
  getLevelInNumeric,
  getPercentageIncrease,
  isExternalFill,
  isGlobalEmployee,
  isInternalFill
} from "../../../helpers/ParHelpers";
import PromotionInstructions from "./PromotionInstructions";
import useQueueStatus from "../../hooks/par/useQueueStatus";
import useRoleStatus from "../../hooks/par/useRoleStatus";
import useWorkflowStatus from "../../hooks/par/useWorkflowStatus";
import useSectionVisibility from "../../hooks/par/useSectionVisibility";

const ParForm = ({
  validations,
  parType,
  queueType,
  getValidationSchema,
  initialData,
  parAvailablePins,
  parPayRanges,
  refreshForm,
  activeTab
}) => {
  const navigate = useNavigate();
  const DEFAULT_VALUES = {
    allocations: []
  };
  // Set PAR ID in case its an existing PAR Record
  const [parRequestId, setParRequestId] = useState(
    (initialData && initialData.par_request_id) || null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [doNavigate, setDoNavigate] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRefreshForm, setRefreshForm] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState(null);
  // Added this state to check if the comment is added and then saved
  // To show empty comment box and advance button enabled
  const [isFormValidAfterSave, setIsFormValidAfterSave] = useState(false);

  const [modalMessage, setModalMessage] = useState("");
  const [availablePINs, setAvailablePINs] = useState(parAvailablePins || []);
  const [payRanges, setPayRanges] = useState(parPayRanges || []);
  const [globalCostData, setGlobalCostData] = useState([]);
  const canModifyPARRecords = security.canEditPARData();
  const [userRole] = useState(
    window.userSession.parPermissions.permission_name
  );

  const {
    isRMIntake,
    isHRIntake,
    isHRAction,
    isRMAction,
    isScaledService,
    isFinance,
    isBrandCEO,
    isExecutive,
    isNoQueue
  } = useQueueStatus(initialData);

  const {
    isSubmitterRole,
    isRMRole,
    isHRRole,
    isScaledServiceRole,
    isFinanceRole,
    isBrandCEORole,
    isExecutiveRole,
    isScaledExRole,
    isFinCEORMHRRole
  } = useRoleStatus(userRole);

  const {
    isUrgentCounterOfferWorkflow,
    isBackfillWorkflow,
    isAddToStaffRequestWorkflow,
    isOpenRequestTBHWorkflow,
    isReallocationActive,
    isReallocationTBH,
    isPromotionInPlace,
    isSalaryAdjustmentWorkflow
  } = useWorkflowStatus(parType);

  const checkSectionVisibility = useSectionVisibility(parType);
  let successMessage =
    "PAR <<PARID>> has been advanced to level - <<nextQueueMsg>>";
  let completeMessage = "PAR <<PARID>> is now Complete. <<nextQueueMsg>>";

  // Queues
  const isPARSubmissionsQueue =
    queueType === ParConstants.PAR_SUBMISSIONS_QUEUE;

  // Only if PAR is complete OR has permissions to be viewed,
  // we will show it as editable in the current user login
  const showCancelOnly = () => {
    let isCancelOnly = false;
    if (initialData && !initialData.existing_msl_pin) {
      // Check if PAR is complete
      isCancelOnly =
        initialData.ticket_status === ParConstants.PAR_ACTIONS.PAR_COMPLETE ||
        (isSubmitterRole && isHRAction);
      // Check if user has opened the PAR But does not actually have access to it
      // in a queue user
      if (ParConstants.PAR_QUEUE_PERMISSION_MAP[userRole]) {
        isCancelOnly =
          isCancelOnly ||
          !ParConstants.PAR_QUEUE_PERMISSION_MAP[userRole].includes(
            initialData.current_queue_name
          );
      } else {
        // Its the client submitter role that means a client submitter is trying
        // to view a PAR that is not created by the viewing user
        isCancelOnly =
          isCancelOnly ||
          initialData.created_by !== window?.userSession?.userId ||
          isPARSubmissionsQueue;
      }
    } else if (queueType) {
      isCancelOnly = isPARSubmissionsQueue;
    }
    return isCancelOnly;
  };

  const isCancelOnly = showCancelOnly();
  // By default always show read only fields if the request is in a queue
  // OR if its already complete
  const showDescription =
    initialData && (initialData.current_queue_id != null || isCancelOnly);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isRefreshForm) {
      const timer = setTimeout(() => {
        setRefreshForm(false);
        refreshForm(parRequestId);
      }, 1000); // Auto-close after 1 seconds
      return () => clearTimeout(timer);
    }
    return false;
  }, [isRefreshForm]);

  useEffect(() => {
    if (doNavigate) {
      const timer = setTimeout(() => {
        setDoNavigate(false);
        navigate("/par", {
          state: { currentTab: activeTab }
        });
      }, 2000); // Auto-close after 2 seconds

      return () => clearTimeout(timer);
    }
    return false;
  }, [doNavigate]);

  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        closeModal();
      }, 3000); // Auto-close after 3 seconds

      return () => clearTimeout(timer);
    }
    return false;
  }, [isModalOpen]);

  let abuseFormikProps = null;

  // Class Object that determines which fields are shown as read only in from
  let showFieldsReadOnly = {
    allocClient: true,
    allocLOB: true,
    allocVal: true,
    allocRateCard: true,
    allocReconcilable: true,
    profit_center: true,
    related_pars: true,
    cc_emails: true,
    is_exempt: true,
    partner: true,
    partner_cost: true,
    offshore_role: true,
    par_brand: true,
    par_axcompany: true,
    par_payroll_company: true,
    par_bpc: true,
    job_code: true,
    requisition_id: true,
    current_performance_rating: true,
    current_base_salary: true,
    date_of_last_compensation_change: true,
    date_of_lastpromotion: true,
    requested_offer: true,
    business_title: true,
    salary_budget: true,
    open_requisition_reason: true,
    backfill_reason: true,
    employee_name: true,
    position_id: true,
    fill_date: true,
    summary_department: true,
    serviceline_department: true,
    reallocation_reassign_allocation_to: true,
    file_upload: true,
    approved_offer: true,
    approved_effective_date: true,
    target_effective_date: true
  };

  // Class Object that determines which fields are hidden in the form
  let hideFields = {
    allocRateCard: true,
    allocReconcilable: true,
    profit_center: true,
    is_exempt: true,
    partner: true,
    partner_cost: true,
    offshore_role: true,
    par_brand: true,
    par_axcompany: true,
    par_payroll_company: true,
    par_bpc: true,
    job_code: true,
    requisition_id: true,
    open_requisition_reason: true,
    par_level: true,
    current_performance_rating: true,
    current_base_salary: true,
    date_of_last_compensation_change: true,
    date_of_lastpromotion: true,
    requested_offer: true,
    compa_ratio_of_offer: true,
    percent_increase: true,
    midpoint_budget: true,
    salary_budget: true,
    business_title: true,
    addAllocationBtn: true,
    relatedPARTickets: true,
    par_billable: true,
    backfill_reason: true,
    deleteAllocationBtn: true,
    position_id: true,
    ticket_status: false,
    decision: true,
    approved_offer: true,
    approved_effective_date: true,
    submitter_comments: false
  };

  const hideSections = {
    positionJustification: false
  };

  const setFieldVisibilityAndStatus = (empType) => {
    const isEmpTypeGlobal = isGlobalEmployee(empType);
    const inputFormVals = {
      isRMIntake,
      isHRIntake,
      isHRAction,
      isRMAction,
      isScaledService,
      isFinance,
      isBrandCEO,
      isExecutive,
      isNoQueue,
      isSubmitterRole,
      isRMRole,
      isHRRole,
      isScaledServiceRole,
      isFinanceRole,
      isBrandCEORole,
      isExecutiveRole,
      isScaledExRole,
      isFinCEORMHRRole,
      showDescription,
      isPARSubmissionsQueue,
      isEmpTypeGlobal
    };
    if (isAddToStaffRequestWorkflow) {
      ParHelpers.addToStaffWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isOpenRequestTBHWorkflow) {
      ParHelpers.openRequestTBHWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isUrgentCounterOfferWorkflow) {
      ParHelpers.urgentCounterWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isBackfillWorkflow) {
      ParHelpers.backfillRequestWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isReallocationTBH) {
      ParHelpers.reallocationTBHWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isPromotionInPlace) {
      ParHelpers.promotionInPlaceWorkFlowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isSalaryAdjustmentWorkflow) {
      ParHelpers.salaryAdjustmentWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    } else if (isReallocationActive) {
      ParHelpers.reallocationActiveWorkflowFieldVisibility(
        showFieldsReadOnly,
        hideFields,
        inputFormVals,
        hideSections
      );
    }
  };

  const getSalaryBudget = (data, index) => {
    let allocations = data?.allocations;
    if (index >= 0) {
      allocations = data?.allocations?.filter((_, i) => i !== index);
    }

    return parseFloat(
      allocations?.reduce(
        (sum, currentValue) =>
          sum +
          ((currentValue.rate_card || "").toString().replace(",", "")
            ? (currentValue.rate_card || "").toString().replace(",", "") *
              currentValue.allocation
            : 0),
        0
      )
    ).toFixed(2);
  };

  const getInitialValues = (inputData) => {
    if (initialData) {
      inputData = initialData;
    }
    setFieldVisibilityAndStatus(inputData?.employee_type);

    if (!inputData) {
      inputData = {};
    }
    if (isAddToStaffRequestWorkflow) {
      inputData.open_requisition_reason =
        ParConstants.REQUISITION_PRACTICE_INVST;
    }
    const formInitialValues = {};
    formInitialValues.allocations = [];
    const formFields = Object.keys(ParConstants.FORM_FIELDS);
    formFields.forEach((field) => {
      if (ParConstants.LIST_FIELDS.includes(field)) {
        if (inputData[field]) {
          if (field === "cc_emails") {
            formInitialValues[field] = inputData[field].split(",").map((x) => ({
              value: x,
              label: x
            }));
          } else if (field === "related_pars") {
            // Related RAP PIN(s) are saved separated by ~ sign, as they can have a
            // comma in the workday title
            formInitialValues[field] = inputData[field].split("~").map((x) => ({
              value: x,
              label: x
            }));
          } else {
            formInitialValues[field] = {
              value: inputData[field],
              label: inputData[field]
            };
          }
        }
      } else if (field === "allocations") {
        formInitialValues[field] = [];
        if (inputData[field] && inputData[field].length > 0) {
          inputData[field].forEach((item, count) => {
            let allocationRow = {
              client_brand: null,
              client_lob: null,
              allocation: item.allocation || "",
              rate_card: item.rateCard || item.rate_card || "",
              id: item.id,
              allocation_id: count
            };
            if (item.client_brand) {
              allocationRow.client_brand = {
                value: item.client_brand,
                label: item.client_brand
              };
            }
            if (item.client_lob) {
              allocationRow.client_lob = {
                value: item.client_lob || "",
                label: item.client_lob
              };
            }
            if (item.status) {
              allocationRow.status = {
                value: item.status,
                label: item.status
              };
            }
            allocationRow.reconcilable = item.reconcilable || "";
            allocationRow.rate_card = item.rate_card || "";
            allocationRow.disableAllocation = item.disableAllocation || false;
            formInitialValues[field].push(allocationRow);
          });
        }
      } else if (
        field === "fill_date" ||
        field === "date_of_last_compensation_change" ||
        field === "date_of_lastpromotion" ||
        field === "target_effective_date" ||
        field === "approved_effective_date"
      ) {
        formInitialValues[field] = "";
        if (inputData[field]) {
          // Convert to date which is required for the datePicker field
          const convertedDate = new Date(inputData[field]);
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
        } else if (isFinance && field === "approved_effective_date") {
          // Approved Date is pre-populated with fill_date or target_effective_date only if the queue is isFinance
          const applicableDate =
            inputData.fill_date || inputData.target_effective_date;
          const convertedDate = applicableDate
            ? new Date(applicableDate)
            : new Date();
          formInitialValues[field] = isValid(convertedDate)
            ? convertedDate
            : "";
        }
      } else if (field === "approved_offer") {
        if (isFinance) {
          // For global employees and external fills the approved_offer is pre-populated with partner_cost
          if (
            isGlobalEmployee(inputData?.employee_type) &&
            isExternalFill(inputData?.fill_type)
          ) {
            formInitialValues[field] = inputData?.partner_cost;
          } else if (
            isGlobalEmployee(inputData?.employee_type) &&
            isInternalFill(inputData?.fill_type)
          ) {
            formInitialValues[field] = inputData?.employee_partner_cost;
          } else {
            // Approved Offer is pre-populated with requested_offer Only if the queue is isFinance
            formInitialValues[field] = inputData?.requested_offer;
          }
        } else {
          // Else set it for the actual approved_offer
          formInitialValues[field] = inputData?.approved_offer || null;
        }
      } else if (
        field === "is_enabled_date_of_last_compensation_change" ||
        field === "is_enabled_date_of_lastpromotion"
      ) {
        // added separate condition as inputData[field] was evaluating false due to the value of field when false
        formInitialValues[field] = !inputData[field];
        if (inputData[field] === undefined) {
          formInitialValues[field] = inputData[field];
        }
      } else if (inputData[field]) {
        formInitialValues[field] = inputData[field];
      } else if (DEFAULT_VALUES[field] !== undefined) {
        formInitialValues[field] = DEFAULT_VALUES[field];
      } else if (field === "current_queue_id") {
        formInitialValues[field] = null;
      } else if (
        field === "decision" &&
        (isRMAction || isHRAction || (!isSubmitterRole && isNoQueue))
      ) {
        // Decision Field is only displayed for RM Action OR HR Action Levels
        // OR if the request is already complete
        formInitialValues[field] = `${ParConstants.APPROVED_STATUS}, ${
          inputData.approvedeffectivedate
            ? format(new Date(inputData.approvedeffectivedate), "MM/dd/yyyy")
            : ""
        }, ${inputData?.approvedby}`;
      } else {
        formInitialValues[field] = "";
      }
    });
    // Do not show comments for a submitter when the ticket is already
    // in a queue, so that they can withdraw the ticket correctly
    // after entering a comment
    if (
      isSubmitterRole &&
      inputData.ticket_status &&
      inputData.ticket_status !== ParConstants.PAR_ACTIONS.WITHDRAWN
    ) {
      formInitialValues.comment = "";
    }
    formInitialValues.current_queue_name = inputData.current_queue_name;
    const budgetValue = parseFloat(inputData.salary_budget);
    const salaryBudget = Number.isNaN(budgetValue) ? null : budgetValue;
    formInitialValues.salary_budget =
      salaryBudget || getSalaryBudget(inputData);
    return formInitialValues;
  };

  const getPARAvailableMSLPINs = async () => {
    try {
      const res = await api.getAvailablePins({ countryId: 1 });
      return res;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };

  const getPARPayRanges = async () => {
    try {
      const res = await api.getPARPayRanges();
      return res;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };

  const getGlobalCostCardData = async () => {
    try {
      const data = await api.getGlobalCostData();
      return data;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (!parAvailablePins || parAvailablePins.length === 0) {
      getPARAvailableMSLPINs().then((data) => {
        if (isMounted) setAvailablePINs(data || []);
      });
    }
    if (!parPayRanges || parPayRanges.length === 0) {
      getPARPayRanges().then((data) => {
        if (isMounted) setPayRanges(data || []);
      });
    }
    if (
      (!globalCostData || globalCostData.length === 0) &&
      (isRMIntake || isSubmitterRole)
    ) {
      getGlobalCostCardData().then((data) => {
        if (isMounted) setGlobalCostData(data || []);
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const isExceptionPAR = (formData) => {
    let isException = false;
    // Check if Exception Request
    const levelNum = getLevelInNumeric(
      abuseFormikProps.values?.par_level?.value
    );
    // the PIN has only one allocation with "Service Line"
    const isServiceLine =
      (formData.allocations || []).length === 1 &&
      (formData.allocations || []).filter((a) =>
        a.client_brand.includes(ParConstants.BRAND_SERVICE_LINE)
      ).length === 1;
    if (levelNum >= 55 || isServiceLine) {
      isException = true;
    }
    // Check percentChange and compaRatio, which is
    // calculated only after BrandCEO level
    if (
      !isException &&
      (isBrandCEO || isHRAction || isRMAction || isExecutive)
    ) {
      const {
        requested_offer: requestedOffer,
        current_base_salary: currentBaseSalary,
        approved_offer: approvedOffer
      } = abuseFormikProps.values;
      const offerValue = approvedOffer || requestedOffer;
      const percentageChange = getPercentageIncrease(
        currentBaseSalary,
        offerValue
      );
      const compaRatioOfOffer = ParHelpers.getCompRatioOfOffer(
        validations?.parGeoRange,
        parPayRanges,
        abuseFormikProps
      ).compaRatio;
      if (percentageChange > 20 || compaRatioOfOffer > 1.2) {
        isException = true;
      }
    }

    // Mark isException as true if workflow is Urgent Counter Offer
    if (isUrgentCounterOfferWorkflow) {
      isException = true;
    }

    return isException;
  };

  const getFormData = (data) => {
    let newData = {};
    for (let prop in data) {
      if (ParConstants.LIST_FIELDS.indexOf(prop) > -1) {
        if (prop === "cc_emails") {
          newData[prop] = data[prop]?.map((x) => x.value).join();
        } else if (prop === "related_pars") {
          newData[prop] = data[prop]?.map((x) => x.value).join("~");
        } else {
          newData[prop] = data[prop]?.value;
        }
      } else if (prop === "allocations") {
        const allocations = JSON.parse(JSON.stringify(data[prop]));
        allocations.forEach((allocation) => {
          allocation.client_brand = allocation.client_brand.value;
          allocation.client_lob = allocation.client_lob?.value;
          allocation.status = allocation.status?.value;
          allocation.rate_card = allocation?.rate_card?.replace(/,/g, "") || 0;
          allocation.reconcilable = allocation.reconcilable || "";
        });
        newData[prop] = allocations;
      } else if (
        prop === "date_of_last_compensation_change" ||
        prop === "requested_offer" ||
        prop === "current_base_salary" ||
        prop === "date_of_lastpromotion" ||
        prop === "fill_date" ||
        prop === "target_effective_date" ||
        prop === "approved_effective_date" ||
        prop === "approved_offer"
      ) {
        newData[prop] = data[prop] !== "" ? data[prop] : null;
      } else if (
        prop === "is_enabled_date_of_last_compensation_change" ||
        prop === "is_enabled_date_of_lastpromotion"
      ) {
        newData[prop] = !data[prop];
      } else {
        newData[prop] = data[prop];
      }
    }
    return newData;
  };

  const handleSubmit = async () => {
    const formData = getFormData(abuseFormikProps.values);
    setSaved(false);
    let response = {};
    try {
      // By default workflow_id is always for "Add To Staff"
      let workflowId = 1;
      if (parType && parType.workflow_id) {
        workflowId = parType.workflow_id;
      }
      // Set the incoming MSL PIN as request_id if present
      if (
        !formData.par_request_id &&
        initialData &&
        initialData.existing_msl_pin
      ) {
        formData.par_request_id = initialData.existing_msl_pin;
      }
      if (parType.name === ParConstants.PAR_WORKFLOW.REALLOCATION_TBH) {
        if (
          formData.reallocation_reason ===
            ParConstants.REALLOCATION_REASONS[0] ||
          formData.reallocation_reason === ParConstants.REALLOCATION_REASONS[1]
        ) {
          formData.reallocation_employee_type = null;
          formData.reallocation_serviceline_department = null;
        }
        if (
          formData.reallocation_reason ===
            ParConstants.REALLOCATION_REASONS[2] ||
          formData.reallocation_reason === ParConstants.REALLOCATION_REASONS[3]
        ) {
          formData.reallocation_level = null;
          formData.reallocation_serviceline_department = null;
        }
        if (
          formData.reallocation_reason === ParConstants.REALLOCATION_REASONS[4]
        ) {
          formData.reallocation_employee_type = null;
          formData.reallocation_level = null;
        }
      }

      // Check if exception request or not
      // For all PINs that DO NOT have employee_type = Global -
      //  1. Check if level is 55 or more
      //  2. Check if allocations list has an allocation containing brand “Service Line”
      //  3. Compa Ratio is > 1.2
      //  4. Salary Percent Change > 20%
      const isGlobal = isGlobalEmployee(
        abuseFormikProps.values?.employee_type?.value
      );
      if (!isGlobal) {
        formData.is_exception = isExceptionPAR(formData);
      } else {
        formData.is_exception = false;
      }
      response = await api.savePARRequest({
        par_request_data: formData,
        par_workflow_id: workflowId,
        par_queue_id: abuseFormikProps.values.current_queue_id
      });
      if (response.success) {
        setParRequestId(response.par_request_id);
        abuseFormikProps.setFieldValue("par_request_id", {
          label: response.par_request_id,
          value: response.par_request_id
        });

        // After Save, if the form is valid, then set the form as valid after save
        if (abuseFormikProps.isValid) {
          setIsFormValidAfterSave(true);
        }
        abuseFormikProps.setFieldValue("comment", "");
        if (isSubmitterRole) {
          setRefreshForm(true);
        }
        setIsModalOpen(true);
        setModalMessage(
          `Your position action request (${response.par_request_id}) has been saved.`
        );
      } else {
        // Show error
        console.log("Error in saving PAR");
        console.log(response.error);
        toast.error(
          "There was a problem in saving the PAR",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an unexpected error in saving the PAR - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
    setSaved(false);
    if (abuseFormikProps.isValid) {
      setSaved(true);
    }
  };

  const onSubmitPAR = async ({
    actionName = null,
    paramSuccessMessage = null,
    title = "Thank You"
  }) => {
    setSubmitting(true);
    if (
      abuseFormikProps.isValid ||
      isFormValidAfterSave ||
      ParHelpers.isReturnedAction(actionName)
    ) {
      let finalAction =
        actionName || ParConstants.PAR_ACTIONS.NEW_PAR_SUBMITTED;

      if (
        isGlobalEmployee(abuseFormikProps.values?.employee_type?.value) &&
        isUrgentCounterOfferWorkflow &&
        actionName === null
      ) {
        finalAction = ParConstants.PAR_ACTIONS.ADVANCED_GLOBAL;
      }
      // default sucess message for Add To Staff PAR
      let finalSuccessMsg = "PAR Request Submitted Successfully";

      let comment = "";
      if (abuseFormikProps.values.comment) {
        comment = abuseFormikProps.values.comment;
      }
      await api
        .actionOnPARRequest({
          par_request_id: parRequestId,
          action: finalAction,
          comment
        })
        .then(
          (response) => {
            if (response.success) {
              // if nextqueue is returned and request is new PAR submission
              finalSuccessMsg = paramSuccessMessage || successMessage;
              finalSuccessMsg = finalSuccessMsg
                .replace("<<PARID>>", parRequestId)
                .replace("<<nextQueueMsg>>", response.nextQueueMsg || "");
              setModalMessage(finalSuccessMsg);
              setModalTitle(title);
              setIsModalOpen(true);
              setDoNavigate(true);
            } else {
              setModalTitle("Exception Occured");
              setModalMessage(response.error);
              setIsModalOpen(true);
            }
          },
          (error) => {
            setModalTitle("Exception Occured");
            setModalMessage(error);
            setIsModalOpen(true);
          }
        );
    }
    setSubmitting(false);
  };

  const onWithdrawPAR = async () => {
    // Call actionOnPARRequest API Method with withdrawn action
    await onSubmitPAR({
      actionName: ParConstants.PAR_ACTIONS.WITHDRAWN,
      paramSuccessMessage: "Your Position action request has been withdrawn",
      title: "Withdrawal Successful"
    });
  };

  const onDelete = () => {
    setModalType(Constants.MODAL_TYPE.DELETE);
    setIsConfirmModalOpen(true);
  };

  const onWithdraw = () => {
    setModalType(Constants.MODAL_TYPE.WITHDRAW);
    setIsConfirmModalOpen(true);
  };

  const onDeletePAR = async () => {
    setSubmitting(true);
    if (abuseFormikProps.isValid) {
      await api.deletePARRequest(parRequestId).then(
        (response) => {
          if (response.success) {
            setModalTitle("Ticket Deleted");
            setModalMessage(
              `PAR ${parRequestId} has been deleted successfully`
            );
            setIsModalOpen(true);
            setDoNavigate(true);
          } else {
            setModalMessage(response.error);
            setIsModalOpen(true);
          }
        },
        (error) => {
          setModalMessage(error);
          setIsModalOpen(true);
        }
      );
    }
    setSubmitting(false);
  };

  // Captures all business rules/actions based on the workflow
  const getActionTypeForWorkflow = () => {
    let actionName = ParConstants.PAR_ACTIONS.ADVANCED;

    const isEmpTypeGlobal = isGlobalEmployee(
      abuseFormikProps.values?.employee_type?.value
    );

    const reqReason = abuseFormikProps.values?.open_requisition_reason?.value;
    const isClientCenter =
      abuseFormikProps.values?.summary_department?.value ===
      ParConstants.CLIENT_CENTER;

    // Remove all alphabets and convert to number
    const levelNum = getLevelInNumeric(
      abuseFormikProps.values?.par_level?.value
    );

    if (isRMIntake) {
      actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED;
      if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
        actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_PRACTICE_INVT;
      }
      // Flag as client center request, so that the request skips scaled service
      if (isClientCenter) {
        actionName = ParConstants.PAR_ACTIONS.PIN_CONFIRMED_CLIENTCENTER;
        if (isEmpTypeGlobal) {
          actionName =
            ParConstants.PAR_ACTIONS.PIN_CONFIRMED_CLIENTCENTER_GLOBAL;
        }
      }
    } else if (isScaledService) {
      // If employeeType Global, skip HR and go to Finance
      if (isEmpTypeGlobal && !(isReallocationActive || isReallocationTBH)) {
        actionName = ParConstants.PAR_ACTIONS.ADVANCED_GLOBAL;
      }
      if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
        actionName = !isEmpTypeGlobal
          ? ParConstants.PAR_ACTIONS.ADVANCED_PRACTICEINVST
          : ParConstants.PAR_ACTIONS.ADVANCED_PRACTICEINVST_GLOBAL;
      }
    } else if (isHRIntake || isFinance) {
      if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
        actionName = ParConstants.PAR_ACTIONS.ADVANCED_PRACTICEINVST;
      }
      // If Finance Queue, for global employees the request needs to
      // move right ahead to the RM Action level
      if (
        isFinance &&
        isEmpTypeGlobal &&
        !(isReallocationActive || isReallocationTBH)
      ) {
        actionName = ParConstants.PAR_ACTIONS.APPROVED_GLOBAL;
      }
      // For Reallocation levels, PAR is approved at Finance level
      // and it goes to RM with this action
      if (isReallocationActive || isReallocationTBH) {
        actionName = ParConstants.PAR_ACTIONS.APPROVED_REALLOCATION;
      }
    } else if (isBrandCEO) {
      const {
        requested_offer: requestedOffer,
        current_base_salary: currentBaseSalary,
        approved_offer: approvedOffer
      } = abuseFormikProps.values;
      const offerValue = approvedOffer || requestedOffer;
      const percentageChange = getPercentageIncrease(
        currentBaseSalary,
        offerValue
      );
      const compaRatioOfOffer = ParHelpers.getCompRatioOfOffer(
        validations?.parGeoRange,
        parPayRanges,
        abuseFormikProps
      ).compaRatio;
      // Check if Exception Request
      if (reqReason === ParConstants.REQUISITION_PRACTICE_INVST) {
        actionName = ParConstants.PAR_ACTIONS.ADVANCED_PRACTICEINVST;
      } else if (percentageChange > 20) {
        actionName =
          ParConstants.PAR_ACTIONS.ADVANCED_PERCENT_INCREASE_EXCEPTION;
      } else if (compaRatioOfOffer > 1.2) {
        actionName = ParConstants.PAR_ACTIONS.ADVANCED_COMPA_RATIO_EXCEPTION;
      } else {
        actionName = ParConstants.PAR_ACTIONS.APPROVED_BELOW_50;

        if (levelNum >= 55) {
          actionName = ParConstants.PAR_ACTIONS.ADVANCED_OVER_55;
        }
      }
    } else if (isRMAction) {
      // default action name
      actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE_RMACTION;
      // If Employee Type is Global (non us employee) we skip going to
      // HR Action and RM Action directly marks the ticket as complete
      if (isEmpTypeGlobal || isReallocationTBH || isReallocationActive) {
        actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE;
        successMessage = completeMessage;
      }
    } else if (isHRAction) {
      actionName = ParConstants.PAR_ACTIONS.PAR_COMPLETE;
      successMessage = completeMessage;
    } else if (isExecutive) {
      actionName = ParConstants.PAR_ACTIONS.APPROVED_EXCEPTION;
    }
    return {
      actionName,
      successMessage
    };
  };

  const onAdvancePAR = async () => {
    if (
      !isUrgentCounterOfferWorkflow &&
      isExecutiveRole &&
      !isConfirmModalOpen
    ) {
      setIsConfirmModalOpen(true);
      setModalType(Constants.MODAL_TYPE.EXECUTIVEALERT);
      return;
    }
    const actionObj = getActionTypeForWorkflow();
    // Call actionOnPARRequest API Method with relevant action
    await onSubmitPAR({
      actionName: actionObj.actionName,
      paramSuccessMessage: actionObj.successMessage
    });

    setSaved(false);
  };

  const onReturnPAR = async () => {
    if (abuseFormikProps.values?.comment) {
      // Call actionOnPARRequest API Method with relevant action
      let actionName = ParConstants.PAR_ACTIONS.RETURNED;
      let returnSuccessMessage =
        "PAR Request has been returned to the Submitter";

      if (isExecutive) {
        actionName = ParConstants.PAR_ACTIONS.RETURNED_TO_CEO;
        returnSuccessMessage =
          "PAR Request has been returned to Brand CEO Queue";

        // handles action name for Urgent counter workflow
        if (isUrgentCounterOfferWorkflow) {
          actionName = ParConstants.PAR_ACTIONS.RETURNED_TO_HR;
          returnSuccessMessage =
            "PAR Request has been returned to HR intake Queue";

          // for Employee type global return ticket to submitter
          // as HR intake queue is not applicable
          if (
            abuseFormikProps.values.employee_type?.value ===
            RapConstants.EMPLOYEE_TYPE_VALUES[1]
          ) {
            actionName = ParConstants.PAR_ACTIONS.RETURNED;
            returnSuccessMessage = "PAR Request has been returned to submitter";
          }
        }
      }
      await onSubmitPAR({
        actionName,
        paramSuccessMessage: returnSuccessMessage
      });
      setSaved(false);
    } else {
      abuseFormikProps.setFieldTouched("comment");
    }
  };

  const onViewHistory = async () => {
    setIsSidebarOpen(true);
  };

  const getInternalEmployeeWarning = () => {
    let message = null;
    const differentServicelineWarning = (
      <>
        The selected Employee’s <strong>Service Line Department</strong> does
        not match the value in PAR.
      </>
    );
    const differentLevelWarning = (
      <>
        The selected Employee’s <strong>Level</strong> does not match the value
        in PAR.
      </>
    );
    const levelSkipWarning = (
      <>The selected PAR Level skips the next available level for promotion.</>
    );
    const notaPromotionWarning = (
      <>The selected PAR Level is same/lesser than employee current level.</>
    );
    const fillType = abuseFormikProps.values.fill_type;
    const employeeName = abuseFormikProps.values.employee_name;
    let employee = null;
    if (employeeName) {
      employee = availablePINs.filter(
        (x) => x.employeeName === employeeName.value
      );
    }
    const parLevel = abuseFormikProps.values.par_level;
    const servicelineDepartment =
      abuseFormikProps.values.serviceline_department;
    if (
      fillType &&
      employee &&
      employee[0] &&
      servicelineDepartment &&
      parLevel
    ) {
      const isDifferentDepartment =
        employee[0].serviceLineDepartment !== servicelineDepartment.value;

      // Fill Type - Internal Fill, Lateral Move
      // Level needs to be exactly the same
      const isDifferentLevel = employee[0].level !== parLevel.value;
      if (fillType.value === ParConstants.FILL_TYPES[1]) {
        if (isDifferentLevel && isDifferentDepartment) {
          message = (
            <>
              The selected Employee’s <strong>Level</strong> and{" "}
              <strong>Service Line Department</strong> does not match the value
              in PAR.
            </>
          );
        } else if (isDifferentLevel) {
          message = differentLevelWarning;
        } else if (isDifferentDepartment) {
          message = differentServicelineWarning;
        } else {
          message = null;
        }
      }
      // Fill Type - Internal Fill, Promotion
      if (fillType.value === ParConstants.FILL_TYPES[2]) {
        const employeeLevel = employee[0].level.match(/\d+/g);
        const selectedParLevel = parLevel.value.match(/\d+/g);
        // Selected level needs to be more than employee's level
        const levelDiff = selectedParLevel[0] - employeeLevel[0];
        const isInValidPromotionLevel = levelDiff > 5;
        let isNotPromotionWarning = levelDiff < 5;
        // Check the Level Change from A → B for the same numeric level, if yes its still a promotion
        const isNoLevelDiff = levelDiff === 0;
        if (isNoLevelDiff) {
          // PAR Level ends with B which is greater than employee's level A, so its a promotion
          if (employee[0].level.endsWith("A") && parLevel.value.endsWith("B")) {
            isNotPromotionWarning = false;
          }
        }
        if (isDifferentDepartment && isInValidPromotionLevel) {
          message = (
            <>
              {differentServicelineWarning} {levelSkipWarning}
            </>
          );
        } else if (isDifferentDepartment && isNotPromotionWarning) {
          message = (
            <>
              {differentServicelineWarning} {notaPromotionWarning}
            </>
          );
        } else if (isInValidPromotionLevel) {
          message = levelSkipWarning;
        } else if (isNotPromotionWarning) {
          message = notaPromotionWarning;
        } else if (isDifferentDepartment) {
          message = differentServicelineWarning;
        } else {
          message = null;
        }
      }
    }
    return message;
  };

  const onConfirm = () => {
    setIsConfirmModalOpen(false);
    switch (modalType) {
      case Constants.MODAL_TYPE.DELETE:
        onDeletePAR();
        break;
      case Constants.MODAL_TYPE.WITHDRAW:
        onWithdrawPAR();
        break;
      case Constants.MODAL_TYPE.WARN:
        handleSubmit();
        break;
      case Constants.MODAL_TYPE.CONFIRM:
        break;
      case Constants.MODAL_TYPE.EXECUTIVEALERT:
        onAdvancePAR();
        break;
      default:
        break;
    }
  };

  const OnCancel = () => {
    navigate("/par", {
      state: { currentTab: activeTab }
    });
  };

  const getAdvanceButtonText = () => {
    let buttonText = "Advance";
    if (isHRAction || (isRMAction && isGlobalEmployee)) {
      // Set button text as “Complete” since for global RM Action is the last step
      // or its at HRAction
      buttonText = "Complete";
    }
    return buttonText;
  };

  const handleSaveButton = (formikProps) => {
    let isFormValid = true;
    const { touched, values, errors } = formikProps;
    /* eslint-disable guard-for-in */
    for (let key in touched) {
      const value = values[key]?.value ?? values[key];
      const error = errors[key];

      if (value && error !== undefined) {
        isFormValid = false;
      }
    }

    // If a field has a value and an error exists, it is most likely due to an invalid value.
    // Mark the form as invalid in such cases.
    if (isFormValid) {
      if (isSubmitterRole) {
        // Show the warning only for submitter role
        let message = null;
        const isExternalFillType = isExternalFill(
          abuseFormikProps.values?.fill_type?.value
        );
        if (!isExternalFillType) {
          message = getInternalEmployeeWarning();
        }
        if (message) {
          setWarningMessage(message);
          setModalType(Constants.MODAL_TYPE.WARN);
          setIsConfirmModalOpen(true);
        } else {
          handleSubmit();
        }
      } else {
        handleSubmit();
      }
    }
  };

  const isSavePARDisabled = () => {
    // Save Button is only disabled for ATS, if employee type is not selected
    let isDisabled = false;
    if (isAddToStaffRequestWorkflow && isSubmitterRole) {
      isDisabled = abuseFormikProps?.values?.ats_employee_type === undefined;
    }
    return isDisabled;
  };

  const isSubmitBtnDisabled = () =>
    !parRequestId ||
    !abuseFormikProps?.isValid ||
    isSubmitting ||
    abuseFormikProps?.dirty;

  const isButtonDisabled = () =>
    !parRequestId ||
    (!isFormValidAfterSave && !abuseFormikProps?.isValid) ||
    !isSaved ||
    isSubmitting;

  const isReturnButtonDisabled = (formVal) => !(formVal.values?.comment !== "");

  // Set the Title of the form with the workflow name
  const workflowTitle =
    parType?.name === ParConstants.PAR_WORKFLOW.URGENT_COUNTER_OFFER &&
    initialData?.business_title
      ? `${parType?.name} - ${initialData?.business_title}`
      : parType?.name;

  return (
    <>
      <div className="row sticky-header" id="sticky-header">
        <div className="col-md-12">
          {initialData &&
          initialData.par_request_id &&
          !initialData.existing_msl_pin
            ? `View PAR Details - ${workflowTitle}`
            : parType
            ? `Create PAR - ${workflowTitle}`
            : "Create PAR - Add To Staff Request"}
        </div>
      </div>
      <div className="row col-md-12 page-title" id="page-title">
        <div className="col-md-6">
          {initialData &&
          initialData.par_request_id &&
          !initialData.existing_msl_pin
            ? `${workflowTitle} - ` + initialData.par_request_id
            : initialData && initialData.existing_msl_pin
            ? `${workflowTitle} - ${initialData.existing_msl_pin}`
            : `Add to Staff Form`}
        </div>
        {initialData && initialData.ticket_status && (
          <div className="col-md-6  d-flex align-self-center justify-end pr-0">
            <button
              type="button"
              id="btnViewTicketHistory"
              name="btnViewTicketHistory"
              className="btn btn-sm btn-secondary par-btn"
              onClick={onViewHistory}
            >
              View Ticket History
            </button>
          </div>
        )}
      </div>
      <div className="par-form-container">
        <Formik
          enableReinitialize
          initialValues={getInitialValues(initialData)}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form id="CreateForm">
              {(abuseFormikProps = formikProps) && <></>}
              {checkSectionVisibility("PositionDetails") && (
                <PositionDetails
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  validations={validations || {}}
                  showDescription={showDescription}
                  workflowName={parType && parType.name}
                  showFieldsReadOnly={showFieldsReadOnly}
                  hideFields={hideFields}
                  getSalaryBudget={getSalaryBudget}
                  isSubmitterRole={isSubmitterRole}
                />
              )}
              {checkSectionVisibility("EmployeeDetails") && (
                <EmployeeDetails
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  showFieldsReadOnly={showFieldsReadOnly}
                  workflowName={parType && parType.name}
                  hideFields={hideFields}
                  parPayRanges={payRanges || []}
                  parGeoRange={validations?.parGeoRange || []}
                />
              )}

              {checkSectionVisibility("ReallocationInstructions") && (
                <ReallocationInstructions
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  showFieldsReadOnly={showFieldsReadOnly}
                  workflowName={parType && parType.name}
                  availablePINs={availablePINs || []}
                  validations={validations}
                  hideFields={hideFields}
                />
              )}

              {checkSectionVisibility("RequisitionInstructions") && (
                <RequisitionInstructions
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  availablePINs={availablePINs || []}
                  validations={validations || {}}
                  showDescription={showDescription}
                  workflowName={parType && parType.name}
                  showFieldsReadOnly={showFieldsReadOnly}
                  hideFields={hideFields}
                  parPayRanges={payRanges || []}
                  globalCostData={globalCostData}
                  isSubmitterRole={isSubmitterRole}
                />
              )}

              {checkSectionVisibility("PromotionInstructions") && (
                <PromotionInstructions
                  formikProps={formikProps}
                  canModifyPARRecords={canModifyPARRecords}
                  availablePARRequests={availablePINs}
                  showDescription={showDescription}
                  showFieldsReadOnly={showFieldsReadOnly}
                  isSubmitterRole={isSubmitterRole}
                  hideFields={hideFields}
                  validations={validations || {}}
                  parPayRanges={payRanges || []}
                />
              )}

              {checkSectionVisibility("PositionJustification") &&
                !hideSections.positionJustification && (
                  <PositionJustification
                    formikProps={formikProps}
                    canModifyPARRecords={canModifyPARRecords}
                    availablePARRequests={availablePINs}
                    showDescription={showDescription}
                    showFieldsReadOnly={showFieldsReadOnly}
                    isSubmitterRole={isSubmitterRole}
                    isRMIntake={isRMIntake}
                    hideFields={hideFields}
                  />
                )}

              {checkSectionVisibility("ParStatus") && (
                <ParStatus
                  fillType={abuseFormikProps.values.fill_type}
                  parType={parType && parType.name}
                  formikProps={formikProps}
                  showFieldsReadOnly={showFieldsReadOnly}
                  hideFields={hideFields}
                  parGeoRange={validations?.parGeoRange || []}
                  parPayRanges={parPayRanges || []}
                />
              )}

              {checkSectionVisibility("TicketAction") && (
                <TicketAction
                  formikProps={formikProps}
                  isDraft={!showDescription}
                  queueType={queueType}
                  isFormValidAfterSave={isFormValidAfterSave}
                />
              )}

              <div className="row  d-flex align-self-center justify-end">
                <div className="mr-3">
                  <button
                    type="button"
                    id="btnCancel"
                    name="btnCancel"
                    className="btn btn-sm btn-link par-btn"
                    onClick={OnCancel}
                  >
                    Cancel
                  </button>
                </div>
                {/* Show Buttons only if PAR is not complete */}
                {!isCancelOnly && (
                  <>
                    {showDescription && isSubmitterRole ? (
                      <div className="mr-3">
                        <button
                          type="button"
                          id="btnWithdraw"
                          name="btnWithdraw"
                          className="btn btn-sm btn-primary par-btn"
                          onClick={onWithdraw}
                          disabled={
                            !abuseFormikProps.values.current_queue_id ||
                            !abuseFormikProps.isValid ||
                            isSubmitting
                          }
                        >
                          Withdraw
                        </button>
                        &nbsp;
                        {isSubmitting && (
                          <i className="fas fa-spinner fa-pulse fa-lg" />
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="mr-3">
                          <button
                            type="button"
                            id="btnSavePAR"
                            name="btnSavePAR"
                            className="btn btn-sm btn-secondary par-btn"
                            onClick={() => handleSaveButton(formikProps)}
                            disabled={isSavePARDisabled()}
                          >
                            Save PAR
                          </button>
                        </div>
                        {isSubmitterRole &&
                          parRequestId &&
                          !abuseFormikProps.values.ticket_status && (
                            <div className="mr-3">
                              <button
                                type="button"
                                id="btnSubmitPAR"
                                name="btnSubmitPAR"
                                className="btn btn-sm btn-secondary par-btn"
                                onClick={onDelete}
                                disabled={
                                  !abuseFormikProps.isValid || isSubmitting
                                }
                              >
                                Delete PAR
                              </button>
                            </div>
                          )}
                        <div>
                          {isSubmitterRole && (
                            <button
                              type="button"
                              id="btnSubmitPAR"
                              name="btnSubmitPAR"
                              className={`btn btn-sm ${
                                isSubmitBtnDisabled()
                                  ? "btn-disabled"
                                  : "btn-primary"
                              } par-btn`}
                              onClick={onSubmitPAR}
                              disabled={isSubmitBtnDisabled()}
                            >
                              Submit PAR
                            </button>
                          )}
                          {!isSubmitterRole && (
                            <button
                              type="button"
                              id="btnAdvancePAR"
                              name="btnAdvancePAR"
                              className={`btn btn-sm ${
                                isButtonDisabled()
                                  ? "btn-disabled"
                                  : "btn-primary"
                              } par-btn`}
                              onClick={onAdvancePAR}
                              disabled={isButtonDisabled()}
                            >
                              {getAdvanceButtonText()}
                            </button>
                          )}
                        </div>
                        <div className="ml-3 mr-3">
                          {!isSubmitterRole && !isHRAction && !isRMAction && (
                            <button
                              type="button"
                              id="btnReturnPAR"
                              name="btnReturnPAR"
                              className={`btn btn-sm ${
                                isReturnButtonDisabled(formikProps)
                                  ? "btn-disabled"
                                  : "btn-primary"
                              } par-btn`}
                              onClick={onReturnPAR}
                              disabled={isReturnButtonDisabled(formikProps)}
                            >
                              Return
                            </button>
                          )}
                          &nbsp;
                          {isSubmitting && (
                            <i className="fas fa-spinner fa-pulse fa-lg" />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
        <Modal isOpen={isModalOpen} title={modalTitle}>
          <div>{modalMessage}</div>
        </Modal>
      </div>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => onConfirm()}
        modalType={modalType}
      >
        <div>{warningMessage}</div>
      </ConfirmationModal>

      {initialData && initialData.ticket_status && (
        <Sidebar
          isOpen={isSidebarOpen}
          header="Ticket History"
          onClose={() => setIsSidebarOpen(false)}
          id="viewHistory"
        >
          <TicketHistory parRequestId={initialData?.par_request_id} />
        </Sidebar>
      )}
    </>
  );
};

ParForm.propTypes = {
  getValidationSchema: PropTypes.func,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array),
    parGeoRange: PropTypes.instanceOf(Array)
  }).isRequired,
  initialData: PropTypes.shape({
    par_request_id: PropTypes.string,
    current_queue_id: PropTypes.number,
    existing_msl_pin: PropTypes.string,
    current_queue_name: PropTypes.string,
    ticket_status: PropTypes.string,
    created_by: PropTypes.number,
    business_title: PropTypes.string
  }),
  parType: PropTypes.shape({
    workflow_id: PropTypes.number,
    name: PropTypes.string
  }),
  queueType: PropTypes.string,
  parAvailablePins: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  refreshForm: PropTypes.func,
  activeTab: PropTypes.string
};

ParForm.defaultProps = {
  parType: null,
  queueType: null,
  getValidationSchema: {},
  initialData: {
    par_request_id: null,
    current_queue_id: null,
    current_queue_name: null
  },
  parAvailablePins: [],
  parPayRanges: [],
  refreshForm: {},
  activeTab: ""
};

export default ParForm;

import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import UserForm from "./UserForm";
import api from "../../services/UserAdminApi";
import Countries from "../hooks/Countries";
import Groups from "../hooks/Groups";
import Constants from "../../common/Constants";
import ParConstants from "../../common/ParConstants";

import Validations from "../hooks/Validations";
import Array from "../../helpers/Array";

const initialValues = {
  displayName: "",
  email: "",
  groups: [],
  countries: [],
  enabled: true,
  parGroup: [],
  summaryDepartment: [],
  serviceLineDepartment: [],
  payrollProfitCenter: [],
  clientBrandList: []
};

const UserManagerAction = ({ action }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const groupData = Groups();
  const parGroupData = Groups("par");
  const countryData = Countries();
  const validationData = Validations();

  let initialState = {
    ...state
  };

  const getFieldName = (parGroup) => {
    let key = "";

    if (
      parGroup === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_BrandCEO ||
      parGroup === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_Finance
    ) {
      key = "payrollProfitCenter";
    } else if (
      parGroup === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ScaledServiceLead
    ) {
      key = "serviceLineDepartment";
    } else if (
      parGroup === ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter
    ) {
      key = "clientBrandList";
    }

    return key;
  };

  const createPARGroupArr = (
    fieldData,
    parGroup,
    fielType,
    fieldTypeParent,
    fieldParentValue
  ) => {
    let finalData = [];

    if (fieldData.length > 0) {
      fieldData.forEach((value) => {
        let arrPAR = [];
        let objPAR = {};
        let fieldValue = value;
        let parentValue = "";
        if (value.indexOf(" ~ ") > -1) {
          fieldValue = value.split(" ~ ")[0].trim();
          parentValue = value.split(" ~ ")[1].trim();
        }

        if (
          parentValue === "" ||
          (parentValue !== "" && fieldParentValue.includes(parentValue))
        ) {
          arrPAR.push(state.userId);
          arrPAR.push(parGroup);
          arrPAR.push(fielType);
          arrPAR.push(fieldValue);
          arrPAR.push(fieldTypeParent);
          arrPAR.push(parentValue || fieldParentValue);

          ParConstants.PARGROUPADDKEYS.forEach((key, index) => {
            objPAR[key] = arrPAR[index];
          });

          finalData.push(objPAR);
        }
      });
    } else {
      let arrPAR = [];
      let objPAR = {};

      arrPAR.push(state.userId);
      arrPAR.push(parGroup);
      arrPAR.push(null);
      arrPAR.push(null);
      arrPAR.push(null);
      arrPAR.push(null);

      ParConstants.PARGROUPADDKEYS.forEach((key, index) => {
        objPAR[key] = arrPAR[index];
      });

      finalData.push(objPAR);
    }

    return finalData;
  };

  const createPARGroupData = (finalPARData) => {
    let finalPARArray = [];

    if (
      finalPARData.parGroup ===
      ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ScaledServiceLead
    ) {
      finalPARArray = createPARGroupArr(
        finalPARData.serviceLineDepartment,
        finalPARData.parGroup,
        ParConstants.PAR_FIELD_NAME.serviceline_department,
        ParConstants.PAR_FIELD_NAME.summary_department,
        finalPARData.summaryDepartment
      );
    } else if (
      finalPARData.parGroup ===
        ParConstants.PAR_PERMISSIONS_MAPPING.PAR_Finance ||
      finalPARData.parGroup ===
        ParConstants.PAR_PERMISSIONS_MAPPING.PAR_BrandCEO
    ) {
      finalPARArray = createPARGroupArr(
        finalPARData.payrollProfitCenter,
        finalPARData.parGroup,
        ParConstants.PAR_FIELD_NAME.profit_center,
        null,
        null
      );
    } else if (
      finalPARData.parGroup ===
      ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter
    ) {
      finalPARArray = createPARGroupArr(
        finalPARData.clientBrandList,
        finalPARData.parGroup,
        ParConstants.PAR_FIELD_NAME.par_brand,
        null,
        null
      );
    } else {
      finalPARArray = createPARGroupArr(
        [],
        finalPARData.parGroup,
        null,
        null,
        null
      );
    }

    return finalPARArray;
  };

  const getPARFormData = (formData) => {
    let finalParData = {};

    let parGroup = Array.getValueFromArray(formData?.parGroup);
    let summaryDepartment =
      formData?.summaryDepartment !== undefined
        ? formData?.summaryDepartment
        : [];
    let serviceLineDepartment =
      formData?.serviceLineDepartment !== undefined
        ? formData?.serviceLineDepartment
        : [];
    let payrollProfitCenter =
      formData?.payrollProfitCenter !== undefined
        ? formData?.payrollProfitCenter
        : [];
    let clientBrandList =
      formData?.clientBrandList !== undefined ? formData?.clientBrandList : [];

    finalParData = {
      parGroup,
      summaryDepartment,
      serviceLineDepartment,
      payrollProfitCenter,
      clientBrandList
    };

    return finalParData;
  };

  const isPARGroupChanged = (parGroupDataObject) => {
    let objData = {};
    let permissionsToAdd = [];
    let permissionsToDelete = [];
    let statePermissionsList = state.parPermissions || [];

    const statePermissions = statePermissionsList.map(
      (item) => item.permission_name
    );
    const formPermissions = parGroupDataObject.map(
      (item) => item.permission_name
    );
    // Permissions to add
    permissionsToAdd = parGroupDataObject.filter(
      (item) => !statePermissions.includes(item.permission_name)
    );
    // Permissions to delete
    permissionsToDelete = statePermissionsList.filter(
      (item) => !formPermissions.includes(item.permission_name)
    );

    let selectedPermission = [...new Set(formPermissions)][0];

    if (permissionsToAdd.length === 0) {
      if (
        selectedPermission ===
        ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ScaledServiceLead
      ) {
        // Permissions to add
        permissionsToAdd = [
          ...permissionsToAdd,
          ...parGroupDataObject.filter(
            (objPar) =>
              !statePermissionsList.some(
                (objState) =>
                  objState.field_value === objPar.field_value &&
                  objState.field_parent_value === objPar.field_parent_value
              )
          )
        ];

        permissionsToAdd = [...new Set(permissionsToAdd)];

        // Permissions to delete
        permissionsToDelete = [
          ...permissionsToDelete,
          ...statePermissionsList.filter(
            (objState) =>
              !parGroupDataObject.some(
                (objPar) =>
                  objPar.field_value === objState.field_value &&
                  objPar.field_parent_value === objState.field_parent_value
              )
          )
        ];

        permissionsToDelete = [...new Set(permissionsToDelete)];
      } else {
        const stateFieldValues = statePermissionsList.map(
          (item) => item.field_value
        );
        const formFieldValues = parGroupDataObject.map(
          (item) => item.field_value
        );

        if (stateFieldValues.length > 0) {
          // Permissions to add
          permissionsToAdd = [
            ...permissionsToAdd,
            ...parGroupDataObject.filter(
              (item) => !stateFieldValues.includes(item.field_value)
            )
          ];

          // Permissions to delete
          permissionsToDelete = [
            ...permissionsToDelete,
            ...statePermissionsList.filter(
              (item) => !formFieldValues.includes(item.field_value)
            )
          ];
        }
      }
      // this is done to avoid removing of permission from ats_user_permission table
      if (permissionsToDelete.length > 0) {
        (permissionsToDelete || []).forEach((item) => {
          item.permission_name = null;
        });
      }
    }

    permissionsToAdd = permissionsToAdd.filter(
      (item) => item.permission_name !== ""
    );

    objData = { permissionsToAdd, permissionsToDelete };

    return objData;
  };

  const createFinalState = (objState) => {
    if (objState?.parPermissions) {
      const result = objState.parPermissions.reduce((acc, curr) => {
        let currentFieldValue = curr.field_value;
        let currentFieldParentValue = curr.field_parent_value;
        let concatinatedFieldValue =
          currentFieldParentValue !== null
            ? currentFieldValue + " ~ " + currentFieldParentValue
            : currentFieldValue;
        if (!acc) {
          acc = {
            ...curr,
            field_value:
              currentFieldValue !== null ? [concatinatedFieldValue] : [],
            field_parent_value:
              currentFieldParentValue !== null ? [currentFieldParentValue] : []
          };
        } else {
          if (currentFieldValue !== null) {
            acc.field_value.push(concatinatedFieldValue);
          }
          if (currentFieldParentValue !== null) {
            acc.field_parent_value.push(currentFieldParentValue);
          }
        }
        acc.field_parent_value = [...new Set(acc.field_parent_value)];
        return acc;
      }, null);

      const fieldName = getFieldName(result.permission_name);

      initialState = {
        ...objState,
        parGroup: [result.permission_name],
        [fieldName]: result.field_value.length > 0 ? result.field_value : [],
        summaryDepartment:
          result.field_parent_value.length > 0 ? result.field_parent_value : [],
        parPermissions: undefined
      };
    } else {
      initialState = {
        ...state
      };
    }

    return initialState;
  };

  const submitHandler = async (formData) => {
    let resp = null;
    let groupAdd = formData.groups;
    let groupDelete = [];

    // PAR specific fields
    let parGroupAdd = [];
    let parGroupDelete = [];

    let countryAdd = formData.countries.map((c) => c.id);
    let countryDelete = [];

    if (action === "Create") {
      delete formData.groups;
      delete formData.countries;

      resp =
        (await api.createUser({
          ...formData,
          groupAdd,
          countryAdd,
          parGroupAdd
        })) || {};
    } else if (action === "Edit") {
      if (!formData.userId) return;

      // Set User Groups
      if (state.groups && state.groups.length > 0) {
        groupAdd = formData.groups.filter(
          (item) => !state.groups.includes(item)
        );
        groupDelete = state.groups.filter(
          (item) => !formData.groups.includes(item)
        );
      }
      delete formData.groups;

      // Create PAR permissions objects
      if (formData.parGroup && formData.parGroup.length > 0) {
        const parGroupDataObject = createPARGroupData(getPARFormData(formData));
        const { permissionsToAdd, permissionsToDelete } =
          isPARGroupChanged(parGroupDataObject);

        if (permissionsToAdd.length > 0) {
          parGroupAdd = permissionsToAdd;
        }

        if (permissionsToDelete.length > 0) {
          parGroupDelete = permissionsToDelete;
        }
      }

      let isParAppRoleDelete =
        groupDelete.filter((item) =>
          item.includes(ParConstants.PAR_PERMISSIONS_MAPPING.finOps_PAR_approle)
        ).length > 0;

      let isParPermissionDelete =
        groupDelete.filter((item) =>
          item.includes(getPARFormData(formData).parGroup)
        ).length > 0;

      if (isParAppRoleDelete || isParPermissionDelete) {
        parGroupDelete = state.parPermissions;
      }
      delete formData.parGroup;

      // Set User Countries
      if (state.countries && state.countries.length > 0) {
        countryAdd = formData.countries.filter(
          (item) => !state.countries.map((c) => c.id).includes(item.id)
        );
        countryAdd = countryAdd.map((c) => c.id);
        countryDelete = state.countries.filter(
          (item) => !formData.countries.map((c) => c.id).includes(item.id)
        );
        countryDelete = countryDelete.map((c) => c.id);
      }
      delete formData.countries;
      resp =
        (await api.updateUser({
          ...formData,
          groupAdd,
          groupDelete,
          countryAdd,
          countryDelete,
          parGroupAdd,
          parGroupDelete
        })) || {};
    }

    if (resp && resp.result === "success") {
      toast.success("Record saved successfully", Constants.TOAST_OPTIONS);
      navigate(`/user-manager`);
    } else {
      const error = resp.error
        ? `${resp.message} - ${resp.error}`
        : resp.message;
      toast.error(error, Constants.TOAST_OPTIONS);
    }
  };
  if (state) {
    initialState.countries = initialState.countries || [];
  }
  return (
    <div className="user_action_page">
      <div className="card">
        <div className="card_header">
          <h4> {action} User</h4>
        </div>
        <div className="card-body">
          <UserForm
            groupData={groupData}
            parGroupData={parGroupData}
            countryData={countryData}
            handleSubmit={submitHandler}
            initialValues={createFinalState(state) || initialValues}
            validationData={validationData}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagerAction;

UserManagerAction.propTypes = {
  action: PropTypes.string.isRequired
};

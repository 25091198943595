/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import TextBoxField from "../../shared/TextBoxField";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import Tooltip from "../../shared/Tooltip";
import DatePickerBoxField from "../../shared/DatePickerBoxField";
import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";
import CollapsibleCard from "../../shared/CollapsibleCard";
import Checkbox from "../../shared/Checkbox";
import helpers, {
  getPercentageIncrease,
  isGlobalEmployee
} from "../../../helpers/ParHelpers";

const InternalFill = ({
  formikProps,
  canModifyPARRecords,
  availablePINs,
  showDescription,
  showFieldsReadOnly,
  hideFields,
  parGeoRange,
  parPayRanges,
  workflowName,
  globalCostData
}) => {
  const employeeType =
    formikProps.values?.employee_type?.value ||
    formikProps.values?.ats_employee_type?.value;

  const isGlobal = isGlobalEmployee(employeeType);

  const setEmployeeDetails = (value) => {
    let details = availablePINs.filter((x) => x.employeeName === value);
    if (details && details[0]) {
      formikProps.setFieldValue("employee_workdayid", details[0]?.workdayId);
      formikProps.setFieldValue(
        "employee_workdaytitle",
        details[0]?.workdayTitle
      );
      formikProps.setFieldValue("employee_type", {
        label: details[0]?.employeeType,
        value: details[0]?.employeeType
      });
      formikProps.setFieldValue("employee_location", {
        label: details[0]?.location,
        value: details[0]?.location
      });
      if (isGlobalEmployee(details[0]?.employeeType)) {
        const globalRecord = globalCostData.filter(
          (x) => x.global_key === details[0]?.globalKey
        )[0];
        formikProps.setFieldValue("employee_partner", "");
        // Create partner value by combining payrollcompany + location
        if (globalRecord) {
          formikProps.setFieldValue(
            "employee_partner",
            `${globalRecord.payrollcompany} - ${globalRecord.location}`
          );
        }
        formikProps.setFieldValue(
          "par_payroll_company",
          globalRecord?.payrollcompany || null
        );
        formikProps.setFieldValue(
          "employee_partner_cost",
          globalRecord?.annual_cost || null
        );
        formikProps.setFieldValue("offshore_role", {
          value: globalRecord?.offshore_role_type || "",
          label: globalRecord?.offshore_role_type || ""
        });
      } else {
        formikProps.setFieldValue("employee_partner", "");
        formikProps.setFieldValue("employee_partner_cost", null);
        formikProps.setFieldValue("offshore_role", "");
        formikProps.setFieldValue("par_payroll_company", "");
      }
    }
    if (value === RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
      formikProps.setFieldValue("job_code", null);
    }
  };

  const isFieldRequired = () =>
    formikProps?.values?.current_queue_name ===
      ParConstants.PAR_QUEUE.HR_INTAKE && !isGlobal;

  const filterAvailablePINs = () => {
    let filteredPINs = availablePINs;
    const empType =
      formikProps.initialValues.employee_type?.value ||
      formikProps.values.ats_employee_type?.value;

    if (
      workflowName === ParConstants.PAR_WORKFLOW.BACKFILL_REQUEST ||
      workflowName === ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST ||
      workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH
    ) {
      if (empType !== RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
        // Employee Type = Permanent, Temporary, or Contingent
        filteredPINs = filteredPINs.filter(
          (x) =>
            x.employeeType !== RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
            x.pinStatus === RapConstants.EMPLOYEE_PIN_STATUS.Active
        );
      } else {
        // Employee Type = Global
        filteredPINs = filteredPINs.filter(
          (x) =>
            x.employeeType === RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
            x.pinStatus === RapConstants.EMPLOYEE_PIN_STATUS.Active
        );
      }
    }

    return filteredPINs;
  };

  const resetDateFields = (fieldName, value) => {
    if (value) {
      formikProps.setFieldValue(fieldName, "");
    }
  };

  return (
    <CollapsibleCard
      className="highlighted-panel"
      title="Internal Fill Requirements"
    >
      <div className="row">
        <div className="col-md-3 mt-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={filterAvailablePINs().map((x) => x.employeeName)}
            fieldName="employee_name"
            label="Internal Fill Employee"
            disable={!canModifyPARRecords}
            onValueChange={setEmployeeDetails}
            showDescription={
              showDescription && showFieldsReadOnly.employee_name
            }
          />
        </div>
      </div>
      {formikProps.values.employee_name &&
        formikProps.values.employee_name.value && (
          <>
            <div className="row" id="internal-emp-details">
              <div className="col-md-10 mt-3">
                <div className="row par-table par-table-highlight ml-0">
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>Employee Workday ID</strong>
                  </div>
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>Business Title</strong>
                  </div>
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>Employee Type</strong>
                  </div>
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>Employee Location</strong>
                  </div>
                  {isGlobal && (
                    <>
                      <div className="col-md-2">
                        <strong>
                          {ParConstants.FORM_FIELDS.employee_partner}
                        </strong>
                      </div>
                      <div className="col-md-2">
                        <strong>
                          {ParConstants.FORM_FIELDS.employee_partner_cost}
                        </strong>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-10">
                <div className="row par-table  par-table-content ml-0">
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>{formikProps.values?.employee_workdayid}</strong>
                  </div>
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>{formikProps.values?.employee_workdaytitle}</strong>
                  </div>
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>{formikProps.values?.employee_type?.value}</strong>
                  </div>
                  <div className={`${isGlobal ? "col-md-2" : "col-md-3"}`}>
                    <strong>
                      {formikProps.values?.employee_location?.value}
                    </strong>
                  </div>
                  {isGlobal && (
                    <>
                      <div className="col-md-2">
                        <strong>{formikProps.values?.employee_partner}</strong>
                      </div>
                      <div className="col-md-2">
                        <strong>
                          {formikProps.values?.employee_partner_cost}
                        </strong>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {!isGlobal && (
              <>
                <div className="row">
                  {!hideFields.current_base_salary && (
                    <div className="col-md-2 mt-3">
                      <TextBoxField
                        className="ml-1"
                        type="number"
                        formikProps={formikProps}
                        fieldName="current_base_salary"
                        label="Current Base Salary"
                        disable={!canModifyPARRecords}
                        isOptional={!isFieldRequired()}
                        showDescription={showFieldsReadOnly.current_base_salary}
                      />
                    </div>
                  )}
                  {!hideFields.requested_offer && (
                    <div className="col-md-2 mt-3">
                      <TextBoxField
                        className="ml-1"
                        type="number"
                        formikProps={formikProps}
                        fieldName="requested_offer"
                        label="Requested Offer"
                        disable={!canModifyPARRecords}
                        isOptional={!isFieldRequired()}
                        showDescription={showFieldsReadOnly.requested_offer}
                      />
                    </div>
                  )}
                  {!hideFields.date_of_last_compensation_change && (
                    <div className="col-md-2 mt-3">
                      <DatePickerBoxField
                        className="ml-0"
                        formikProps={formikProps}
                        label="Date Of Last Compensation Change"
                        isOptional={!isFieldRequired()}
                        fieldName="date_of_last_compensation_change"
                        disable={
                          !canModifyPARRecords ||
                          formikProps.values
                            .is_enabled_date_of_last_compensation_change
                        }
                        showDescription={
                          showFieldsReadOnly.date_of_last_compensation_change
                        }
                      />
                    </div>
                  )}
                  {!hideFields.date_of_lastpromotion && (
                    <div className="col-md-2 mt-3">
                      <DatePickerBoxField
                        className="ml-0"
                        formikProps={formikProps}
                        label="Date Of Last Promotion"
                        isOptional={!isFieldRequired()}
                        fieldName="date_of_lastpromotion"
                        disable={
                          !canModifyPARRecords ||
                          formikProps.values.is_enabled_date_of_lastpromotion
                        }
                        showDescription={
                          showFieldsReadOnly.date_of_lastpromotion
                        }
                      />
                    </div>
                  )}
                  {!hideFields.current_performance_rating && (
                    <div className="col-md-2 mt-3">
                      <TypeAheadBoxField
                        formikProps={formikProps}
                        suggestions={ParConstants.PERFORMANCE_RATINGS}
                        fieldName="current_performance_rating"
                        label="Current Performance Rating"
                        disable={!canModifyPARRecords}
                        isOptional={!isFieldRequired()}
                        showDescription={
                          showFieldsReadOnly.current_performance_rating
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  {!hideFields.current_base_salary && (
                    <div className="col-md-2 mt-1">&nbsp;</div>
                  )}
                  {!hideFields.requested_offer && (
                    <div className="col-md-2 mt-1">&nbsp;</div>
                  )}
                  {!hideFields.date_of_last_compensation_change && (
                    <div className="col-md-2 mt-1">
                      <Checkbox
                        fieldName="is_enabled_date_of_last_compensation_change"
                        label="Not Applicable"
                        checked={
                          formikProps?.values
                            ?.is_enabled_date_of_last_compensation_change
                        }
                        onChange={(value) => {
                          formikProps.setFieldValue(
                            "is_enabled_date_of_last_compensation_change",
                            value
                          );
                          resetDateFields(
                            "date_of_last_compensation_change",
                            value
                          );
                        }}
                        disable={
                          !canModifyPARRecords ||
                          showFieldsReadOnly.date_of_last_compensation_change
                        }
                      />
                    </div>
                  )}
                  {!hideFields.date_of_lastpromotion && (
                    <div className="col-md-2 mt-1">
                      <Checkbox
                        fieldName="is_enabled_date_of_lastpromotion"
                        label="Not Applicable"
                        checked={
                          formikProps?.values?.is_enabled_date_of_lastpromotion
                        }
                        onChange={(value) => {
                          formikProps.setFieldValue(
                            "is_enabled_date_of_lastpromotion",
                            value
                          );
                          resetDateFields("date_of_lastpromotion", value);
                        }}
                        disable={
                          !canModifyPARRecords ||
                          showFieldsReadOnly.date_of_lastpromotion
                        }
                      />
                    </div>
                  )}
                  {!hideFields.current_performance_rating && (
                    <div className="col-md-2 mt-1">&nbsp;</div>
                  )}
                </div>
              </>
            )}
            {employeeType && !isGlobal && (
              <div className="row">
                {!hideFields.percent_increase && (
                  <div className="mt-3 col-md-2">
                    <div className="topTooltipContainer ml-1">
                      <div className="textLabel">Percent Increase</div>
                      <div className="tooltip-cell">
                        <Tooltip
                          className="tooltip-content"
                          text="Please enter the Requested Offer and Current Base Salary to see the Percent Increase value"
                        >
                          <i className="fas fa-info-circle fa-lg info-color" />
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      id="percent_increase"
                      className="form-description mt-2 ml-1"
                    >
                      <strong>
                        {getPercentageIncrease(
                          formikProps.values.current_base_salary,
                          formikProps.values.approved_offer ||
                            formikProps.values.requested_offer
                        )}{" "}
                        %
                      </strong>
                    </div>
                  </div>
                )}
                {!hideFields.compa_ratio_of_offer && (
                  <div className="mt-3 col-md-2">
                    <div className="topTooltipContainer">
                      <div className="textLabel">Compa Ratio of Offer</div>
                      <div className="tooltip-cell">
                        <Tooltip
                          className="tooltip-content"
                          text="Please Enter the Requested Offer and Select Job Profile to see this Calculated Ratio"
                        >
                          <i className="fas fa-info-circle fa-lg info-color" />
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      id="compa_ratio_of_offer"
                      className="form-description mt-2"
                    >
                      <strong>
                        {
                          helpers.getCompRatioOfOffer(
                            parGeoRange,
                            parPayRanges,
                            formikProps
                          ).compaRatio
                        }
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
    </CollapsibleCard>
  );
};

InternalFill.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      employee_name: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      current_queue_name: PropTypes.string,
      current_base_salary: PropTypes.string,
      requested_offer: PropTypes.string,
      approved_offer: PropTypes.string,
      employee_location: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      ats_employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_partner: PropTypes.string,
      employee_partner_cost: PropTypes.string,
      is_enabled_date_of_last_compensation_change: PropTypes.bool,
      is_enabled_date_of_lastpromotion: PropTypes.bool
    }),
    initialValues: PropTypes.shape({
      backfill_workday_id: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  availablePINs: PropTypes.instanceOf(Array),
  parGeoRange: PropTypes.instanceOf(Array),
  parPayRanges: PropTypes.instanceOf(Array),
  globalCostData: PropTypes.instanceOf(Array),
  showFieldsReadOnly: PropTypes.shape({
    current_performance_rating: PropTypes.bool,
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    requested_offer: PropTypes.bool,
    employee_name: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    current_performance_rating: PropTypes.bool,
    current_base_salary: PropTypes.bool,
    date_of_last_compensation_change: PropTypes.bool,
    date_of_lastpromotion: PropTypes.bool,
    requested_offer: PropTypes.bool,
    compa_ratio_of_offer: PropTypes.bool,
    percent_increase: PropTypes.bool
  }),
  workflowName: PropTypes.string
};

InternalFill.defaultProps = {
  availablePINs: [],
  parGeoRange: [],
  parPayRanges: [],
  globalCostData: [],
  canModifyPARRecords: false,
  showDescription: false,
  showFieldsReadOnly: {},
  hideFields: {},
  workflowName: ""
};

export default InternalFill;

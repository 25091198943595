import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import "../../styles/shared/input-box.scss";
import Tooltip from "./Tooltip";

const TextBoxField = ({
  formikProps,
  fieldName,
  label,
  isOptional,
  hideHeader,
  className,
  focus,
  focusRef,
  disable,
  validateOnChange,
  showDescription,
  onChange,
  type,
  maxLength,
  showAsCurrency,
  showTooltip,
  toolTipText
}) => {
  const classFinal = !className ? "mt-2 mr-2 mb-2" : className;
  const onBlurHandler = (e, field, form) => {
    let trimmedValue = e.target.value.trim();
    if (showAsCurrency && trimmedValue !== "") {
      const numericValue = trimmedValue.replace(/,/g, "");
      trimmedValue = !Number.isNaN(parseFloat(numericValue).toFixed(2))
        ? Number(numericValue).toLocaleString()
        : trimmedValue;
    }
    form.setFieldValue(field.name, trimmedValue);
    formikProps.handleBlur(e);
    onChange(trimmedValue);
  };
  return (
    <div className={classFinal}>
      {!hideHeader && (
        <label htmlFor={fieldName}>
          {label}
          {!isOptional && <span className="red-text">&nbsp;*</span>}
          {showTooltip && (
            <div className="topTooltipContainer">
              <div className="tooltip-cell">
                <Tooltip className="tooltip-content" text={toolTipText}>
                  <i className="fas fa-info-circle fa-lg info-color" />
                </Tooltip>
              </div>
            </div>
          )}
        </label>
      )}
      {!showDescription && (
        <Field name={fieldName} validateOnChange={validateOnChange}>
          {({ field, form, meta }) => (
            <>
              {(type === "number" || showAsCurrency) && (
                <div className="currency-symbol">$</div>
              )}
              <input
                {...field}
                type={type || "text"}
                name={fieldName}
                id={fieldName}
                placeholder={`Type ${label}`}
                className="form-control"
                onBlur={(e) => onBlurHandler(e, field, form)}
                style={meta.error ? { border: "1px solid #DC2F23" } : {}}
                ref={focus ? focusRef : null}
                disabled={disable}
                maxLength={maxLength}
              />
            </>
          )}
        </Field>
      )}
      {showDescription && (
        <div id={`${fieldName}-description`} className="form-description">
          <strong>
            {(type === "number" || showAsCurrency) && <span>$ </span>}
            {fieldName.indexOf(".") === -1
              ? formikProps?.values[fieldName]
              : formikProps?.values[fieldName.split(".")[0]][
                  fieldName.split(".")[1]
                ][fieldName.split(".")[2]]}
          </strong>
        </div>
      )}
      <div className="mt-1 error-message">
        <ErrorMessage name={fieldName} />
      </div>
    </div>
  );
};

TextBoxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  isOptional: PropTypes.bool,
  hideHeader: PropTypes.bool,
  showDescription: PropTypes.bool,
  className: PropTypes.string,
  focus: PropTypes.string,
  focusRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  disable: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  showAsCurrency: PropTypes.bool,
  showTooltip: PropTypes.bool,
  toolTipText: PropTypes.string
};

TextBoxField.defaultProps = {
  isOptional: false,
  hideHeader: false,
  className: null,
  focus: null,
  focusRef: null,
  disable: false,
  showDescription: false,
  validateOnChange: false,
  onChange: () => {},
  type: "text",
  maxLength: 100,
  showAsCurrency: false,
  showTooltip: false,
  toolTipText: ""
};
export default TextBoxField;

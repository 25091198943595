import React from "react";
import Modal from "react-modal";
import "../../styles/shared/backdrop-loader.scss";
import "../../styles/shared/confirmation-modal.scss";
import PropTypes from "prop-types";
import Constants from "../../common/Constants";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  modalType
}) =>
  isOpen ? (
    <div className="container">
      <Modal
        id="ConfirmationModal"
        role="dialog"
        isOpen={isOpen}
        contentLabel="Delete User Confirmation"
        className="contry-selection-modal"
        overlayClassName="modaloverlay"
        ariaHideApp={false}
      >
        <div className="text-center">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="modal-icon">
                  {modalType === Constants.MODAL_TYPE.WARN ? (
                    <i className="fas fa-exclamation-triangle custom-warning-icon" />
                  ) : null}
                  {modalType === Constants.MODAL_TYPE.DELETE ? (
                    <i className="fas fa-exclamation-triangle custom-delete-icon" />
                  ) : null}
                  {modalType === Constants.MODAL_TYPE.WITHDRAW ? (
                    <h3>Ticket Withdrawal</h3>
                  ) : null}
                  {modalType === Constants.MODAL_TYPE.EXECUTIVEALERT ? (
                    <h3>Americas Approval</h3>
                  ) : null}
                </div>
              </div>
            </div>
            {modalType === Constants.MODAL_TYPE.WARN ? (
              <>
                <div className="row mb-3">
                  <div className="col-md-12 modal-title">
                    <strong>Are you Sure?</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="warning-message">{children}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 warning-message">
                    Do you still want to proceed?
                  </div>
                </div>
              </>
            ) : null}
            {modalType === Constants.MODAL_TYPE.WITHDRAW ? (
              <div className="row">
                <div className="col-md-12 mb-3 mt-2">
                  Are you sure about withdrawing the Ticket?
                </div>
              </div>
            ) : null}

            {modalType === Constants.MODAL_TYPE.DELETE ? (
              <>
                <div className="row mb-2">
                  <div className="col-md-12 modal-title">
                    <strong>Delete Ticket?</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    Are you sure about Deleting the Ticket?
                  </div>
                </div>
              </>
            ) : null}

            {modalType === Constants.MODAL_TYPE.EXECUTIVEALERT ? (
              <>
                <div className="row">
                  <div className="col-md-12 executive-alert">
                    Please confirm receipt of AMERICAS APPROVAL for this
                    position action request.
                  </div>
                </div>
              </>
            ) : null}

            <div className="row  modal-footer-container mt-3">
              <button
                type="button"
                data-testid="confirmation-modal-cancel-btn"
                className="btn btn-sm btn-secondary par-btn"
                onClick={onClose}
              >
                {modalType === Constants.MODAL_TYPE.WITHDRAW
                  ? "No, I don't want to withdraw"
                  : modalType === Constants.MODAL_TYPE.EXECUTIVEALERT
                  ? "No"
                  : "Cancel"}
              </button>
              <button
                type="button"
                data-testid="confirmation-modal-confirm-btn"
                className="btn btn-sm btn-success ml-3  par-btn"
                onClick={onConfirm}
              >
                {modalType === Constants.MODAL_TYPE.WITHDRAW
                  ? "Withdraw"
                  : null}
                {modalType === Constants.MODAL_TYPE.DELETE ? "Delete" : null}
                {modalType === Constants.MODAL_TYPE.WARN ? "Proceed" : null}
                {modalType === Constants.MODAL_TYPE.EXECUTIVEALERT
                  ? "Yes, Approval confirmed"
                  : null}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <></>
  );

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
  modalType: PropTypes.string
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
  children: <></>,
  modalType: Constants.MODAL_TYPE.WARN
};
export default ConfirmationModal;

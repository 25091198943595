/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FieldArray, Field } from "formik";

import ParConstants from "../../../common/ParConstants";
import TextBoxField from "../../shared/TextBoxField";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import CollapsibleCard from "../../shared/CollapsibleCard";
import ButtonGroup from "../../shared/ButtonGroup";

const Allocation = ({
  formikProps,
  validations,
  canModifyPARRecords,
  showFieldsReadOnly,
  hideFields,
  getSalaryBudget,
  workflowName,
  isSubmitterRole
}) => {
  let abuseAllocations = null;

  const [removedAllocationIndex, setRemovedAllocationIndex] = useState(null);
  const [clientLobList, setClientLobList] = useState([]);
  const lastAllocationInput = React.createRef();
  const [disableAddAllocationBtn, setDisableAddAllocationBtn] = useState(false);

  const isAddToStaffRequestWorkflow =
    workflowName === ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST;

  const showDeleteAllocationBtn = (disableAllocation) =>
    !disableAllocation && !hideFields.deleteAllocationBtn;

  const addAllocation = (formValues) => {
    const currentAllocations = formValues.allocations;
    // making a fake lastAllocation as needed to make the next id from
    const lastAllocation = currentAllocations[
      currentAllocations.length - 1
    ] || { allocation_id: 0 };
    if (abuseAllocations) {
      abuseAllocations.push({
        client_brand: null,
        client_lob: null,
        allocation: "",
        rate_card: "",
        status: { value: "", label: "" },
        allocation_id: lastAllocation.allocation_id + 1,
        id: null,
        startDate: "",
        endDate: "",
        reconcilable: "",
        disableAllocation: false
      });

      // reset removedAllocationIndex to avoid latest added allocation from excluding the sum
      setRemovedAllocationIndex(removedAllocationIndex);
      setTimeout(() => {
        if (lastAllocationInput.current !== null) {
          lastAllocationInput.current.focus();
        }
      }, 100);

      if (
        workflowName === ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST &&
        isSubmitterRole
      ) {
        setDisableAddAllocationBtn(true);
      }
    }
  };

  const calculateSalaryBudget = (index) => {
    const salaryBudget = getSalaryBudget(formikProps.values, index);

    formikProps.setFieldValue("salary_budget", salaryBudget);
  };
  const sumOfAllocations = (removedIndex = null) => {
    let sum = 0;
    formikProps?.values?.allocations?.forEach((resource, index) => {
      if (
        resource.status !== null &&
        resource.status?.value !== "Eliminated" &&
        resource.allocation !== "" &&
        resource.allocation !== "." &&
        index !== removedIndex
      ) {
        sum += parseFloat(resource.allocation);
      }
    });
    return (sum * 100).toFixed(0);
  };
  const removeAllocation = (index) => {
    abuseAllocations.remove(index);
    calculateSalaryBudget(index);
    if (
      workflowName === ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST &&
      isSubmitterRole
    ) {
      setDisableAddAllocationBtn(false);
    }
  };

  const filterClientLOB = (selectedClient, index) => {
    if (selectedClient) {
      // Filter the clientLob based on client value
      const list = validations.clientLobList.filter(
        (clientLob) => clientLob.parentValue === selectedClient
      );
      if (
        formikProps &&
        !list.filter((x) => x.value === formikProps.values.client_lob?.value)
          .length > 0
      ) {
        // Clear selection from clientLob dropdown if not in filtered values
        formikProps.setFieldValue(`allocations.${index}.client_lob`, "");
      }
      setClientLobList(list);
    }
  };

  const renderResourceAllocations = (clientList) => {
    const fieldName = "allocations";
    return (
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => {
          abuseAllocations = arrayHelpers;
          return (
            <div className="mt-1" id="par-allocations">
              {formikProps?.values[fieldName]?.map((resource, index) => {
                const keyIndex = `resource_row_${index}`;
                const indexValue =
                  formikProps.values[fieldName]?.length === 1 ? "" : index + 1;
                // Get the value of status
                let statusVal = formikProps.values[fieldName][index].status;
                let { disableAllocation } =
                  formikProps.values[fieldName][index];
                if (statusVal) {
                  statusVal = statusVal.value || statusVal;
                }

                // Do not display allocation rows with Eliminated Status
                if (statusVal !== "Eliminated") {
                  return (
                    <div data-testid="allocation" key={keyIndex}>
                      <div className="row">
                        <div className="col-md-3 mt-2">
                          <div className="display-flex  center-flex-vertical title-text">
                            Position Funding {indexValue}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className=" col-md-2 mt-1">
                          <TypeAheadBoxField
                            formikProps={formikProps}
                            suggestions={clientList || []}
                            fieldName={`${fieldName}.${index}.client_brand`}
                            label="Client"
                            disable={!canModifyPARRecords}
                            showDescription={
                              showFieldsReadOnly.allocClient ||
                              disableAllocation
                            }
                            updateChildDropdown={(val) =>
                              filterClientLOB(val, index)
                            }
                            isParent
                          />
                        </div>
                        <div className=" col-md-2 mt-1">
                          <TypeAheadBoxField
                            formikProps={formikProps}
                            suggestions={clientLobList.map((x) => x.value || x)}
                            fieldName={`${fieldName}.${index}.client_lob`}
                            label="Client LOB"
                            disable={!canModifyPARRecords}
                            showDescription={
                              showFieldsReadOnly.allocLOB || disableAllocation
                            }
                            isOptional
                          />
                        </div>
                        <div className="col-md-2 mt-1">
                          <TextBoxField
                            formikProps={formikProps}
                            fieldName={`${fieldName}.${index}.allocation`}
                            label="Allocation"
                            data-testid={`allocation_${index}`}
                            className="ml-1"
                            focus="lastAllocationInput"
                            showDescription={
                              showFieldsReadOnly.allocVal || disableAllocation
                            }
                            focusRef={lastAllocationInput}
                            disable={!canModifyPARRecords}
                            onChange={() => calculateSalaryBudget()}
                            maxLength={5}
                          />
                        </div>
                        {!hideFields.allocRateCard && (
                          <div className="col-md-2 mt-1">
                            <TextBoxField
                              className="ml-1"
                              formikProps={formikProps}
                              fieldName={`${fieldName}.${index}.rate_card`}
                              showDescription={
                                showFieldsReadOnly.allocRateCard ||
                                disableAllocation
                              }
                              label="Rate Card"
                              isOptional={false}
                              onChange={() => calculateSalaryBudget()}
                              showAsCurrency
                            />
                          </div>
                        )}
                        {!hideFields.allocReconcilable && (
                          <div className="col-md-2 mt-1">
                            {!showFieldsReadOnly.allocReconcilable && (
                              <Field
                                name={`${fieldName}.${index}.reconcilable`}
                              >
                                {({ meta }) => (
                                  <ButtonGroup
                                    fieldName={`${fieldName}.${index}.reconcilable`}
                                    metaObj={meta}
                                    fieldValue={
                                      formikProps.values.allocations[index]
                                        .reconcilable
                                    }
                                    label="Reconcilable"
                                    buttonValues={ParConstants.BOOLEAN_VALUES}
                                    setButtonValue={(value) => {
                                      formikProps.setFieldValue(
                                        `${fieldName}.${index}.reconcilable`,
                                        value
                                      );
                                    }}
                                    inError={!!meta.error}
                                    isRequired
                                  />
                                )}
                              </Field>
                            )}
                            {(showFieldsReadOnly.allocReconcilable ||
                              disableAllocation) && (
                              <>
                                <label
                                  htmlFor={`btnGrpForm-${fieldName}.${index}.reconcilable`}
                                >
                                  Reconcilable
                                  <span className="red-text"> *</span>
                                </label>
                                <div
                                  id={`btnGrpForm-${fieldName}.${index}.reconcilable`}
                                  className="form-description"
                                >
                                  <strong>
                                    {
                                      formikProps.values[fieldName][index]
                                        .reconcilable
                                    }
                                  </strong>
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {showDeleteAllocationBtn(disableAllocation) && (
                          <div className="mt-3 col-md-2 display-flex  center-flex-vertical">
                            <div className="remove-button col-1 pl-2 ">
                              <button
                                className="btn btn-xsmall delete"
                                type="button"
                                data-testid={`btnRemoveAllocationItem_${index}`}
                                id={`btnRemoveAllocationItem_${index}`}
                                name={`btnRemoveAllocationItem_${index}`}
                                onClick={() => removeAllocation(index)}
                                disabled={!canModifyPARRecords}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        }}
      />
    );
  };

  useEffect(() => {
    if (
      formikProps.values.allocations?.filter(
        (x) => x.status?.value !== "Eliminated"
      ).length > 0 &&
      workflowName === ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST &&
      isSubmitterRole
    ) {
      setDisableAddAllocationBtn(true);
    }
  }, []);

  const visibleClientList = isAddToStaffRequestWorkflow
    ? ParConstants.OVERHEAD_SERVICE_LINE
    : validations?.clientList;

  return (
    <>
      <CollapsibleCard
        className={
          sumOfAllocations(formikProps) > 100
            ? "highlighted-panel allocation-exceeded"
            : "highlighted-panel"
        }
        title={"Allocation Total: " + sumOfAllocations(formikProps) + " %"}
      >
        <>
          {visibleClientList && renderResourceAllocations(visibleClientList)}
          {!hideFields.addAllocationBtn &&
            workflowName !== ParConstants.PAR_WORKFLOW.REALLOCATION_TBH &&
            workflowName !== ParConstants.PAR_WORKFLOW.PROMOTION_IN_PLACE &&
            workflowName !== ParConstants.PAR_WORKFLOW.SALARY_ADJUSTMENT && (
              <div className="row d-flex align-self-center justify-start">
                <div className="mt-3 ml-3">
                  <button
                    type="button"
                    id="btnAddResource"
                    name="btnAddResource"
                    onClick={() => addAllocation(formikProps.values)}
                    className="btn btn-sm btn-secondary par-btn"
                    disabled={!canModifyPARRecords || disableAddAllocationBtn}
                  >
                    Add Allocation <i className="fa fa-plus-circle" />
                  </button>
                </div>
              </div>
            )}
          {formikProps.errors &&
            formikProps.errors.allocations &&
            typeof formikProps.errors.allocations === "string" && (
              <div id="err-alloc" className="ml-1 mt-2 error-message">
                {formikProps.errors.allocations}
              </div>
            )}
        </>
      </CollapsibleCard>
    </>
  );
};

Allocation.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      allocations: PropTypes.instanceOf(Array),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      client_lob: PropTypes.string
    }),
    errors: PropTypes.any,
    initialValues: PropTypes.shape({
      allocations: PropTypes.instanceOf(Array),
      employee_workdayid: PropTypes.string,
      employee_workdaytitle: PropTypes.string,
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      client_lob: PropTypes.string
    }),
    setFieldValue: PropTypes.func
  }).isRequired,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array)
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showFieldsReadOnly: PropTypes.shape({
    allocClient: PropTypes.bool,
    allocLOB: PropTypes.bool,
    allocRateCard: PropTypes.bool,
    allocVal: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    allocRateCard: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    addAllocationBtn: PropTypes.bool,
    deleteAllocationBtn: PropTypes.bool
  }),
  getSalaryBudget: PropTypes.func,
  workflowName: PropTypes.string,
  isSubmitterRole: PropTypes.bool
};

Allocation.defaultProps = {
  canModifyPARRecords: false,
  showFieldsReadOnly: {},
  hideFields: {},
  getSalaryBudget: () => {},
  workflowName: "",
  isSubmitterRole: false
};

export default Allocation;

import PropTypes from "prop-types";
import React from "react";

const StatusChip = ({ action }) => {
  const submitted = <i className="fa fa-check-circle" aria-hidden="true" />;
  const confirmed = <i className="fa fa-check-circle" aria-hidden="true" />;
  const approved = <i className="fa fa-info-circle" aria-hidden="true" />;
  const complete = <i className="fa fa-info-circle" aria-hidden="true" />;
  const returned = (
    <i className="fa fa-exclamation-triangle" aria-hidden="true" />
  );
  const withdrawn = (
    <i className="fa fa-exclamation-triangle" aria-hidden="true" />
  );

  const getIcon = (status) => {
    const iconMap = {
      Submitted: submitted,
      Confirmed: confirmed,
      Advanced: approved,
      Approved: approved,
      Complete: complete,
      Returned: returned,
      Withdrawn: withdrawn
    };

    for (const key in iconMap) {
      if (status && status.indexOf(key) > -1) {
        return iconMap[key];
      }
    }
    return "";
  };

  const getStatusClass = (status) => {
    const statusClasses = {
      Submitted: "submitted",
      Confirmed: "confirmed",
      Advanced: "approved",
      Approved: "approved",
      Complete: "complete",
      Returned: "returned",
      Withdrawn: "withdrawn"
    };

    for (const key in statusClasses) {
      if (status && status.indexOf(key) > -1) {
        return statusClasses[key];
      }
    }
    return "";
  };
  return (
    <span className={getStatusClass(action)}>
      <span className="mr-1">{getIcon(action)}</span>
      <span>{action}</span>
    </span>
  );
};

StatusChip.propTypes = {
  action: PropTypes.string
};

StatusChip.defaultProps = {
  action: null
};
export default StatusChip;

import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

const Option = ({ isDisabled, isFocused, isSelected, innerProps, ...rest }) => {
  // prop assignment
  const props = {
    ...innerProps
  };

  return (
    <div>
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        innerProps={props}
      >
        <div className="form-check">
          <input
            id={rest.id}
            type="checkbox"
            className="form-check-input input-label"
            checked={isSelected}
            onChange={() => {}}
          />
          {"  "}
          <label htmlFor={rest.id} className="form-check-label">
            {rest.label}
          </label>
        </div>
      </components.Option>
    </div>
  );
};

const SelectBox = ({
  name,
  isMultiSelect,
  className,
  options,
  placeholder,
  onSelectHandler,
  defaultValue,
  isDisabled
}) => {
  const colourStyles = {
    option: (styles) => ({
      ...styles,
      color: "#5b5b65",
      backgroundColor: "white"
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#0a6adf",
      "&:hover": {
        color: "#0a6adf"
      }
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none"
    })
  };

  const changeHandler = (e) => {
    if (Array.isArray(e)) {
      onSelectHandler(e.map((opt) => opt.value));
    } else {
      onSelectHandler(e?.value || "");
    }
  };

  let props = {
    name,
    className,
    options,
    placeholder,
    isDisabled,
    backspaceRemovesValue: true,
    isClearable: true,
    isSearchable: true,
    styles: colourStyles,
    onChange: changeHandler,
    "aria-label": name,
    defaultValue: defaultValue.map((v) => {
      if (typeof v === "string" && v.indexOf("~") > -1)
        return { label: v.split("~")[0].trim(), value: v };
      if (typeof v === "string") return { label: v, value: v };
      return { label: v.name, value: v };
    })
  };

  if (isMultiSelect) {
    props = {
      ...props,
      isMulti: isMultiSelect,
      components: { Option },
      closeMenuOnSelect: false,
      hideSelectedOptions: true
    };
  }

  return (
    <>
      <Select {...props} />
    </>
  );
};

SelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  isMultiSelect: PropTypes.bool,
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onSelectHandler: PropTypes.func,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(Array),
  isDisabled: PropTypes.bool
};

SelectBox.defaultProps = {
  isMultiSelect: false,
  placeholder: "",
  label: "",
  className: "",
  isClearable: false,
  isSearchable: false,
  onSelectHandler: () => {},
  defaultValue: [],
  isDisabled: false
};

Option.propTypes = {
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.shape({}).isRequired
};

Option.defaultProps = {
  isDisabled: false,
  isFocused: false,
  isSelected: false
};

export default SelectBox;

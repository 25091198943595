import React from "react";
import PropTypes from "prop-types";
import "../../styles/shared/checkbox.scss";

const Checkbox = ({
  fieldName,
  checked,
  label,
  disable,
  isRequired,
  onChange
}) => (
  <div className="form-group checkbox-container">
    <label htmlFor={fieldName} className="checkbox-label">
      <input
        type="checkbox"
        id={fieldName}
        name={fieldName}
        checked={checked}
        disabled={disable}
        onChange={(e) => onChange(e.target.checked)}
        className="checkboxElement mr-2"
      />
      <span className="checkmark mr-2" />
      {label}
      {isRequired && <span className="text-danger">*</span>}
    </label>
  </div>
);

Checkbox.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  disable: PropTypes.bool,
  isRequired: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  fieldName: "",
  label: "",
  disable: false,
  isRequired: false,
  checked: false
};

export default Checkbox;

const PAR_STATUS = {
  withdraw: "Withdrawn",
  draft: "Draft",
  complete: "PAR Complete"
};

const SHOW_PER_PAGE = [100, 500, 1000, "Show All"];

const STAFF_PAGE_LOCAL_STORAGE = "staffPageSize";

const PAR_SUBMISSION_LOCAL_STORAGE = "ParPageSize";

const REQUISITION_NEW_CLIENT = "New Client (New Logo)";
const REQUISITION_PRACTICE_INVST = "Practice Investment";

const REASON_FOR_REQUEST = [
  REQUISITION_NEW_CLIENT,
  "Active Client Incremental",
  REQUISITION_PRACTICE_INVST
];

const REASON_FOR_REQUEST_TBH = [
  REQUISITION_NEW_CLIENT,
  "Active Client Incremental",
  "Unserviced Role",
  "Backfill Existing Position"
];

const BACKFILL_REASON = [
  "Leaver, Notice Given",
  "Mover, Lateral",
  "Mover, Promotion"
];

const FILL_TYPES = [
  "External Fill",
  "Internal Fill, Lateral Move",
  "Internal Fill, Promotion"
];
const PERFORMANCE_RATINGS = [
  "Exceptional",
  "Very Strong",
  "Successful",
  "Developing",
  "Lack of Performance Fit",
  "/"
];
const LOCATION_REASON = [
  "Client Location",
  "Onsite Team Location",
  "Client & Onsite Team",
  "Available Talent Market",
  "Location not Mandatory"
];

const GLOBAL_PARTNERS_LIST = [
  "DGS - India",
  "DGS - Philippines",
  "Global Mind - Argentina",
  "Ugam - India"
];

const FORM_FIELDS = {
  par_request_id: "PAR ID",
  current_queue_id: "Current Queue Id",
  open_requisition_reason: "Reason for Open Request",
  summary_department: "Summary Department",
  serviceline_department: "Service Line Department",
  par_level: "Level",
  allocations: "Allocations",
  fill_type: "Fill Type",
  fill_date: "Target Fill Date",
  hiring_manager: "Hiring Manager (Supervisory Organization)",
  justification: "Position Action Justification",
  cc_emails: "CC for PAR Notifications",
  comment: "Comments",
  employee_type: "Employee Type",
  ats_employee_type: "Employee Type",
  fill_location: "Fill Location",
  location_reason: "Location Reason",
  current_performance_rating: "Current Performance Rating",
  employee_name: "employee_name",
  par_position_title: "Position Title",
  business_title: "Business Title",
  par_billable: "Billable",
  attachment_paths: "Upload Supporting Files",
  date_of_last_compensation_change: "Date Of Last Compensation Change",
  date_of_lastpromotion: "Date Of Last Promotion",
  current_base_salary: "Current Base Salary",
  employee_workdayid: "Employee Workday ID",
  employee_workdaytitle: "Employee Title",
  employee_location: "Employee Location",
  requested_offer: "Requested Offer",
  submitter: "Submitter",
  is_exempt: "Exempt",
  profit_center: "Profit Center",
  partner: "Partner",
  partner_cost: "Partner Cost",
  par_brand: "Brand",
  par_axcompany: "D365 Company",
  par_payroll_company: "Payroll Company",
  par_bpc: "BPC",
  job_code: "Job Code",
  requisition_id: "Requisition ID",
  related_pars: "Related RAP PIN(s)",
  target_effective_date: "Target Effective Date",
  offshore_role: "Offshore Role",
  backfill_reason: "Reason for backfill",
  position_id: "Position ID",
  requested_level: "Requested Level",
  reallocation_reason: "Reallocation Reason",
  reallocation_level: "Level",
  reallocation_employee_type: "Employee Type",
  reallocation_serviceline_department: "Service Line Department",
  reallocation_reassign_allocation_to: "Reassign Allocation To",
  decision: "Decision",
  ticket_status: "Ticket Status",
  approved_offer: "Approved Offer",
  approved_effective_date: "Approved Effective Date",
  submitted_date: "Submitted Date",
  submitter_comments: "Submitter Comments",
  backfill_name: "Backfill Employee Name",
  backfill_workday_id: "Backfill Workday ID",
  backfill_employee_type: "Backfill Employee Type",
  backfill_location: "Backfill Location",
  promotion_business_title: "Requested Business Title",
  promotion_position_title: "Position Title",
  reallocation_details: "Reallocation Details",
  employee_partner: "Partner",
  employee_partner_cost: "Partner Cost",
  is_enabled_date_of_last_compensation_change: "Not Applicable",
  is_enabled_date_of_lastpromotion: "Not Applicable"
};

const ALLOWED_EMAILS = ["dentsu.com", "merkle.com"];

const EXTERNAL_FILL_FIELDS = [
  "fill_location",
  "employee_type",
  "location_reason",
  "is_exempt",
  "partner",
  "partner_cost",
  "offshore_role",
  "requested_offer"
];
const INTERNAL_FILL_FIELDS = [
  "employee_name",
  "employee_type",
  "employee_workdayid",
  "employee_workdaytitle",
  "employee_location",
  "current_base_salary",
  "requested_offer",
  "date_of_last_compensation_change",
  "date_of_lastpromotion",
  "current_performance_rating"
];

const PAR_WORKFLOW = {
  ADD_TO_STAFF_REQUEST: "Add To Staff Request",
  REALLOCATION: "Reallocation",
  BACKFILL_REQUEST: "Backfill Request",
  PROMOTION_IN_PLACE: "Promotion in Place",
  SALARY_ADJUSTMENT: "Salary Adjustment",
  URGENT_COUNTER_OFFER: "Urgent Counter Offer",
  OPEN_REQUEST_TBH: "Open Request (TBH PIN)",
  REALLOCATION_TBH: "Reallocation (TBH PIN)",
  REALLOCATION_ACTIVE: "Reallocation (Active PIN)"
};

const PIN_STATUS = {
  TBH: "TBH",
  ACTIVE: "Active"
};

const BOOLEAN_VALUES = ["Yes", "No"];

const LIST_FIELDS = [
  "summary_department",
  "serviceline_department",
  "open_requisition_reason",
  "par_level",
  "par_request_id",
  "fill_type",
  "client_brand",
  "related_pars",
  "hiring_manager",
  "employee_type",
  "ats_employee_type",
  "employee_name",
  "fill_location",
  "location_reason",
  "client_lob",
  "location",
  "status",
  "cc_emails",
  "current_performance_rating",
  "profit_center",
  "partner",
  "job_code",
  "offshore_role",
  "backfill_reason",
  "employee_location",
  "requested_level",
  "reallocation_reason",
  "reallocation_level",
  "reallocation_employee_type",
  "reallocation_serviceline_department",
  "reallocation_reassign_allocation_to"
];

const DROPDOWN_TYPE_LIST = [
  "Location",
  "Payroll - Company",
  "Payroll - Profit Center",
  "Workday Title",
  "Summary Department",
  "Service Line Department",
  "Payroll Department",
  "Client Brand",
  "Client LOB"
];

const LIST_TYPES = {
  globalPerm: "globalPerm",
  tempContingent: "tempContingent",
  activeOptions: "activeOptions",
  otherOptions: "otherOptions"
};

const PAR_ACTIONS = {
  // Approval Actions, which happen just before RM action or HR Action levels
  APPROVED_GLOBAL: "Approved - Global Employee",
  APPROVED_REALLOCATION: "Approved - Reallocation",
  APPROVED_EXCEPTION: "Approved - Exception PAR",
  APPROVED_BELOW_50: "Approved - Level 50 or Below",

  // All Queue Advancement actions
  ADVANCED: "Advanced",
  ADVANCED_GLOBAL: "Advanced - Global Employee",
  ADVANCED_OVER_55: "Advanced - Level 55 or Above",
  ADVANCED_PRACTICEINVST: "Advanced - Practice Investment",
  ADVANCED_PRACTICEINVST_GLOBAL:
    "Advanced - Practice Investment (Global Employee)",
  ADVANCED_PERCENT_INCREASE_EXCEPTION: "Advanced - Percent Increase Exception",
  ADVANCED_COMPA_RATIO_EXCEPTION: "Advanced - Compa Ratio Exception",

  // All PAR Completion Actions
  PAR_COMPLETE_RMACTION: "Complete - RM Action",
  PAR_COMPLETE: "PAR Complete",

  // Initial PIN Submission Advancement actions
  NEW_PAR_SUBMITTED: "New PAR Submitted",
  PIN_CONFIRMED_PRACTICE_INVT: "PIN Confirmed - Practice Investment",
  PIN_CONFIRMED: "PIN Confirmed",
  PIN_CONFIRMED_CLIENTCENTER: "PIN Confirmed - Client Center",
  PIN_CONFIRMED_CLIENTCENTER_GLOBAL:
    "PIN Confirmed - Client Center (Global Employee)",

  // Return and Withdraw actions
  RETURNED: "Returned",
  RETURNED_TO_CEO: "Returned to CEO",
  WITHDRAWN: "Withdrawn",
  RETURNED_TO_HR: "Returned to HR"
};

const PAR_PERMISSIONS_MAPPING = {
  PAR_ScaledServiceLead: "finOps_PAR-ScaledServiceLead-userRole",
  PAR_BrandCEO: "finOps_PAR-BrandCEO-userRole",
  PAR_Finance: "finOps_PAR-Finance-userRole",
  PAR_ClientBrandSubmitter: "finOps_PAR-ClientBrandSubmitter-userRole",
  PAR_RM: "finOps_PAR-RM-userRole",
  PAR_HR: "finOps_PAR-HR-userRole",
  PAR_Executive: "finOps_PAR-Executive-userRole",
  finOps_PAR_approle: "finOps_PAR-approle"
};

const PAR_QUEUE = {
  RM_INTAKE: "RM Intake",
  RM_ACTION: "RM Action",
  HR_INTAKE: "HR Intake",
  HR_ACTION: "HR Action",
  SCALED_SVC: "Scaled Service",
  FINANCE: "Brand Finance",
  BRAND_CEO: "Brand CEO",
  EXCECUTIVE: "Executive Approval"
};

const PAR_QUEUE_PERMISSION_MAP = {
  "finOps_PAR-ScaledServiceLead-userRole": [PAR_QUEUE.SCALED_SVC],
  "finOps_PAR-BrandCEO-userRole": [PAR_QUEUE.BRAND_CEO],
  "finOps_PAR-Finance-userRole": [PAR_QUEUE.FINANCE],
  "finOps_PAR-RM-userRole": [PAR_QUEUE.RM_INTAKE, PAR_QUEUE.RM_ACTION],
  "finOps_PAR-HR-userRole": [PAR_QUEUE.HR_INTAKE, PAR_QUEUE.HR_ACTION],
  "finOps_PAR-Executive-userRole": [PAR_QUEUE.EXCECUTIVE]
};

const PARGROUPADDKEYS = [
  "user_id",
  "permission_name",
  "permission_for_field_type",
  "field_value",
  "field_type_parent",
  "field_parent_value"
];

const PAR_FIELD_NAME = {
  serviceline_department: "serviceline_department",
  profit_center: "profit_center",
  par_brand: "par_brand",
  summary_department: "summary_department"
};

const PAR_QUEUE_STATUS = {
  "RM Intake": "RM Intake",
  "Scaled Service": "Scaled Service Review",
  "HR Intake": "HR Intake Review",
  "Brand Finance": "Finance Review",
  "Brand CEO": "Brand CEO Review",
  "Executive Approval": "Executive Review",
  "RM Action": "Approved for RM Action",
  "HR Action": "Approved for HR Action"
};

const REALLOCATION_REASONS = [
  "Downgrade",
  "Upgrade",
  "Convert to Onshore",
  "Convert to Offshore",
  "Service Line Change",
  "Rotation",
  "Extended Leave Coverage"
];
// Different categories of queues used to render queue tabs
const PAR_INTAKE_QUEUE = "Intake";
const PAR_ACTION_QUEUE = "Action";
const PAR_REGULAR_QUEUE = "Regular";
const PAR_SUBMISSIONS_QUEUE = "Submissions";
const PAR_TICKET_HISTORY_COUNT = 10;

// "Overhead - Service Line"
const OVERHEAD_SERVICE_LINE = ["Overhead - Service Line"];

const BRAND_SERVICE_LINE = "Service Line";

const CLIENT_CENTER = "Client Center";

const APPROVED_STATUS = "Approved";

const SUBMITTER_COMMENT = "Comments added by Submitter";

export default {
  PAR_STATUS,
  PAR_WORKFLOW,
  REASON_FOR_REQUEST,
  FILL_TYPES,
  PERFORMANCE_RATINGS,
  LOCATION_REASON,
  FORM_FIELDS,
  LIST_FIELDS,
  DROPDOWN_TYPE_LIST,
  LIST_TYPES,
  PAR_ACTIONS,
  SHOW_PER_PAGE,
  STAFF_PAGE_LOCAL_STORAGE,
  PAR_SUBMISSION_LOCAL_STORAGE,
  PIN_STATUS,
  PAR_PERMISSIONS_MAPPING,
  PARGROUPADDKEYS,
  PAR_FIELD_NAME,
  PAR_INTAKE_QUEUE,
  PAR_ACTION_QUEUE,
  PAR_REGULAR_QUEUE,
  PAR_SUBMISSIONS_QUEUE,
  REASON_FOR_REQUEST_TBH,
  REQUISITION_NEW_CLIENT,
  REQUISITION_PRACTICE_INVST,
  PAR_QUEUE,
  BOOLEAN_VALUES,
  GLOBAL_PARTNERS_LIST,
  PAR_QUEUE_PERMISSION_MAP,
  EXTERNAL_FILL_FIELDS,
  INTERNAL_FILL_FIELDS,
  PAR_TICKET_HISTORY_COUNT,
  BACKFILL_REASON,
  OVERHEAD_SERVICE_LINE,
  BRAND_SERVICE_LINE,
  REALLOCATION_REASONS,
  CLIENT_CENTER,
  PAR_QUEUE_STATUS,
  APPROVED_STATUS,
  ALLOWED_EMAILS,
  SUBMITTER_COMMENT
};

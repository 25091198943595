import React, { useState } from "react";
import { Formik, Form } from "formik";
import Constants from "../../common/Constants";
import UploadFileSource from "../shared/UploadFileSource";
import security from "../../services/Security";

/* eslint-disable react/no-array-index-key */
const ParUploads = () => {
  const canUploadPARData = security.canUploadPARData();
  const isSubmitterRole = security.isSubmitterRole();
  const [warningList, setWarningList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [bulkUpdateData, setBulkUpdateData] = useState({
    rowCount: 0,
    status: "loading"
  });

  const updateBulkUploadData = async (count) => {
    setBulkUpdateData({ rowCount: count, status: "loaded" });
  };

  const updateBulkParPinData = async (result) => {
    const errLen = result.errors?.length;
    const warnLen = result.warnings?.length;
    if (errLen > 0) {
      setErrorList(result.errors);
    } else {
      setErrorList([]);
    }
    if (warnLen > 0) {
      setWarningList(result.warnings);
    } else {
      setWarningList([]);
    }
    if (errLen === 0) {
      // Success, update the count
      setBulkUpdateData({ rowCount: result.uploadCount, status: "loaded" });
    }
  };

  return (
    <Formik>
      {() => (
        <Form
          className="form-horizontal mainform-card col-sm-12"
          name="parupload"
        >
          {canUploadPARData && (
            <div className="card mt-4">
              <div className="row">
                <div className="col-md-4 mt-2 mb-1 ml-4">
                  <h5>Upload PAR Pay Range Data</h5>
                </div>
              </div>
              <div className="row ml-1 mb-2">
                <div className="col-md-4">
                  <div className="mr-2 ctsClass">
                    <UploadFileSource
                      batchId={-1}
                      isBatchSourceLoading={false}
                      isScenarioLocked={false}
                      source={bulkUpdateData}
                      sourceName={Constants.SOURCE_PAR_PAY_RANGE}
                      onSuccessfulUpload={updateBulkUploadData}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {isSubmitterRole && (
            <div className="card mt-4">
              <div className="row">
                <div className="col-md-4 mt-2 mb-1 ml-4">
                  <h5>PAR Bulk Uploads</h5>
                </div>
              </div>
              <div className="row ml-1 mb-2">
                <div className="col-md-4">
                  <div className="mr-2 ctsClass">
                    <UploadFileSource
                      batchId={-1}
                      isBatchSourceLoading={false}
                      isScenarioLocked={false}
                      source={bulkUpdateData}
                      sourceName={Constants.SOURCE_PAR_BULK_UPLOAD}
                      onSuccessfulUpload={updateBulkParPinData}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {errorList.length > 0 && (
                  <div className="col-md-5 mt-2 mb-1 ml-4">
                    <h6 className="warning-red">Errors in Upload</h6>
                    <table className="table table-sm table-bordered">
                      <thead>
                        <tr className="table-secondary">
                          <th className="col-sm-1 text-nowrap" scope="col">
                            Row No.
                          </th>
                          <th scope="col">Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {errorList.map((error) => {
                          const { row } = error;
                          return Object.values(error.errors).map((x, count) => (
                            <tr key={`key_${count}`}>
                              <td className="col-sm-1 text-center">
                                {row + 2}
                              </td>
                              <td>{x}</td>
                            </tr>
                          ));
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {warningList.length > 0 && (
                  <div className="col-md-5 mt-2 mb-1 ml-4">
                    <h6 className="par-warning-orange">Warnings in Upload</h6>
                    <table className="table table-sm table-bordered">
                      <thead>
                        <tr className="table-secondary">
                          <th className="col-sm-1 text-nowrap" scope="col">
                            Row No.
                          </th>
                          <th scope="col">Warning</th>
                        </tr>
                      </thead>
                      <tbody>
                        {warningList.map((warning) => {
                          const { row } = warning;
                          return Object.values(warning.warnings).map(
                            (x, count) => (
                              <tr key={`key_${count}`}>
                                <td className="col-sm-1 text-center">
                                  {row + 2}
                                </td>
                                <td>{x}</td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

ParUploads.propTypes = {};

ParUploads.defaultProps = {};

export default ParUploads;

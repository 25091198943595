import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import ParConstants from "../../../common/ParConstants";
import { isGlobalEmployee } from "../../../helpers/ParHelpers";

// Dropdown Menu Component
const DropdownMenu = ({ parWorkFlows, row, handleClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  // Filter REALLOCATION_TBH for v1 only
  // TODO: replace the line below with  -
  //   let dropdownOptions = parWorkFlows
  // for v2 when we show REALLOCATION_TBH
  let dropdownOptions = parWorkFlows.filter(
    (item) =>
      item.name.toLowerCase() !==
      ParConstants.PAR_WORKFLOW.REALLOCATION_TBH.toLowerCase()
  );
  if (
    row.allCells.filter((item) => item.column.id === "pinStatus").length > 0
  ) {
    const pinStatus = row.allCells.filter(
      (item) => item.column.id === "pinStatus"
    )[0].value;

    const employeeType = row.allCells.filter(
      (item) => item.column.id === "employeeType"
    )[0].value;

    if (pinStatus === ParConstants.PIN_STATUS.TBH) {
      // For TBH PIN's we show only "Open Request (TBH PIN)" and
      // "ReAllocation (TBH)" Options
      dropdownOptions = dropdownOptions.filter(
        (item) =>
          item.name.toLowerCase() ===
            ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH.toLowerCase() ||
          item.name.toLowerCase() ===
            ParConstants.PAR_WORKFLOW.REALLOCATION_TBH.toLowerCase()
      );
    } else {
      // For Active PIN's we show all options except
      // "Add to Staff" & "Open Request (TBH PIN)" & "ReAllocation (TBH)"
      dropdownOptions = dropdownOptions.filter(
        (item) =>
          item.name.toLowerCase() !==
            ParConstants.PAR_WORKFLOW.OPEN_REQUEST_TBH.toLowerCase() &&
          item.name.toLowerCase() !==
            ParConstants.PAR_WORKFLOW.ADD_TO_STAFF_REQUEST.toLowerCase() &&
          item.name.toLowerCase() !==
            ParConstants.PAR_WORKFLOW.REALLOCATION_TBH.toLowerCase()
      );

      const hasSingleAllocation =
        row.original.subRows.filter(
          (x) =>
            x.clientBrandLob &&
            x.clientBrandLob.filter((y) => y.status !== "Eliminated").length > 1
        ).length > 0;

      if (!hasSingleAllocation) {
        dropdownOptions = dropdownOptions.filter(
          (item) =>
            item.name.toLowerCase() !==
            ParConstants.PAR_WORKFLOW.REALLOCATION_ACTIVE.toLowerCase()
        );
      }

      // Do not show these for Global - Promotion in Place/Salary Adjustment/Urgent Counter Offer
      if (isGlobalEmployee(employeeType)) {
        dropdownOptions = dropdownOptions.filter(
          (item) =>
            item.name.toLowerCase() !==
              ParConstants.PAR_WORKFLOW.PROMOTION_IN_PLACE.toLowerCase() &&
            item.name.toLowerCase() !==
              ParConstants.PAR_WORKFLOW.SALARY_ADJUSTMENT.toLowerCase() &&
            item.name.toLowerCase() !==
              ParConstants.PAR_WORKFLOW.URGENT_COUNTER_OFFER.toLowerCase()
        );
      }
    }
  }

  // Function to close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      {row.depth === 0 && (
        <div ref={dropdownRef} style={{ position: "relative" }}>
          <button type="button" className="menuButton" onClick={toggleDropdown}>
            <i className="fas fa-bars fa-xs" />
          </button>
          {isOpen && (
            <div className="dropdownmenu">
              <ul style={{ listStyle: "none", padding: 0 }}>
                {dropdownOptions.map((item) => (
                  <li
                    className="options"
                    key={item.workflow_id}
                    onClick={(event) => handleClick(event, item, row.original)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

DropdownMenu.propTypes = {
  row: PropTypes.shape({
    depth: PropTypes.number,
    allCells: PropTypes.arrayOf(PropTypes.shape({})),
    values: PropTypes.shape({}),
    original: PropTypes.shape({
      subRows: PropTypes.arrayOf(PropTypes.shape({})),
      pinStatus: PropTypes.string
    })
  }),
  parWorkFlows: PropTypes.instanceOf(Array).isRequired,
  handleClick: PropTypes.func
};

DropdownMenu.defaultProps = {
  row: [],
  handleClick: () => {}
};

export default DropdownMenu;

/*
 Common method used in PAR Components to get the Compa Ratio Offer 
 calculation based on requested offer and salary mid point
*/

import ParConstants from "../common/ParConstants";
import RapConstants from "../common/RapConstants";

const getCompRatioOfOffer = (parGeoRange, parPayRanges, formikProps) => {
  /* eslint-disable camelcase */
  const {
    employee_location,
    job_code,
    requested_offer,
    par_level,
    fill_location,
    approved_offer
  } = formikProps?.values || {};

  // Internall Fill => employee_location OR External Fill => fill_location
  const location = employee_location?.value || fill_location?.value;
  const level = par_level?.value?.match(/\d+/)?.[0];
  const jobCodeValue = job_code?.value;
  const offer_value = approved_offer || requested_offer;

  // Ensure all necessary fields are present before proceeding
  if (!location || !jobCodeValue || !requested_offer || !level) {
    return "";
  }

  // Find the corresponding geo range and pay range
  const geoRange = parGeoRange.find(
    (x) => location.includes(x.parentValue) || x.parentValue.includes(location)
  );
  const parRange = parPayRanges.find(
    (parPayRange) =>
      parPayRange.job_profile === jobCodeValue &&
      parPayRange.level.includes(level)
  );
  /* eslint-enable camelcase */
  if (geoRange && parRange) {
    const marketMidpoints = {
      medium: parRange.mid_market_mid,
      high: parRange.high_market_mid,
      low: parRange.low_market_mid
    };

    const salaryMidPoint = parseFloat(marketMidpoints[geoRange.value] || 0);

    if (salaryMidPoint !== 0) {
      return {
        salaryMidPoint: salaryMidPoint.toFixed(2),
        compaRatio: parseFloat(
          (parseFloat(offer_value) / salaryMidPoint).toFixed(2)
        )
      };
    }
  }
  return {
    salaryMidPoint: "",
    compaRatio: ""
  };
};

export const isGlobalEmployee = (empType) =>
  empType && empType === RapConstants.EMPLOYEE_TYPE_VALUES[1];

export const isExternalFill = (fillType) =>
  fillType && fillType === ParConstants.FILL_TYPES[0];

export const isInternalFill = (fillType) =>
  fillType && fillType !== ParConstants.FILL_TYPES[0];

export const getLevelInNumeric = (level) =>
  parseInt(level?.replace(/\D/g, ""), 10);

export const getPercentageIncrease = (currentSalary, requestedOffer) => {
  if (!currentSalary || !requestedOffer) return 0;

  const current = parseFloat(currentSalary);
  const requested = parseFloat(requestedOffer);

  if (isNaN(current) || isNaN(requested) || current <= 0) return 0;

  const percentageIncrease = ((requested - current) / current) * 100;
  return Number(percentageIncrease.toFixed(2));
};

export const isValidNumberWithCommas = (value) => {
  if (!value?.includes(",")) return true;
  const regex = /^\d{1,3}(,\d{3})*(\.\d+)?$/;
  return regex.test(value);
};

export const getParLevelList = (validations, employeeType) => {
  if (isGlobalEmployee(employeeType)) {
    return validations?.levelListGlobal || [];
  }
  return validations?.levelList || [];
};

/**
 * The following Methods determine which fields will be visible in a PAR Form
 * and will be either editable or readonly for each of the workflow types
 * Captures the business rules from the requirements to set field status for each field
 * type per workflow.
 */
const addToStaffWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.open_requisition_reason = false;
  hideFields.relatedPARTickets = false;
  if (inputFormVals.isSubmitterRole) {
    hideFields.is_exempt = false;
    hideFields.ticket_status = true;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.par_level = false;
    hideFields.addAllocationBtn = inputFormVals.showDescription;
    hideFields.deleteAllocationBtn = inputFormVals.showDescription;
    hideFields.par_billable = false;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.is_exempt = inputFormVals.showDescription;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.business_title = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.allocClient = inputFormVals.showDescription;
    showFieldsReadOnly.allocLOB = inputFormVals.showDescription;
    showFieldsReadOnly.allocVal = inputFormVals.showDescription;
    showFieldsReadOnly.summary_department = inputFormVals.showDescription;
    showFieldsReadOnly.serviceline_department = inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visbile for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;

    hideFields.partner = false;
    hideFields.partner_cost = false;
    hideFields.offshore_role = false;
    hideFields.profit_center = false;
    hideFields.par_brand = false;
    hideFields.par_axcompany = false;
    hideFields.par_payroll_company = false;
    hideFields.par_bpc = false;
    hideFields.salary_budget = false;
    hideFields.par_level = false;
    hideFields.par_billable = false;
    hideFields.cc_emails = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.allocReconcilable = false;

    // All these fields are visible and editable for RM Intake
    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.partner = false;
    showFieldsReadOnly.offshore_role = false;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.file_upload = false;

    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledService ||
        inputFormVals.isHRIntake ||
        inputFormVals.isExecutive)) ||
    (inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    // Scaled Service Role - Rate Card is Hidden
    hideFields.allocRateCard = inputFormVals.isScaledServiceRole;

    hideFields.par_billable = false;
    hideFields.par_level = false;
    hideFields.job_code =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : !inputFormVals.isHRIntake;
    hideFields.partner =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    hideFields.partner_cost =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    hideFields.offshore_role =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    // PAR Brand, AxCompany, Payroll, BPC to be shown only for HR Intake
    hideFields.par_brand =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : !inputFormVals.isHRIntake;
    hideFields.par_axcompany =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : !inputFormVals.isHRIntake;
    hideFields.par_payroll_company =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : !inputFormVals.isHRIntake;
    hideFields.par_bpc =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : !inputFormVals.isHRIntake;
    hideFields.midpoint_budget = !(
      inputFormVals.isHRIntake ||
      !inputFormVals.isScaledService ||
      inputFormVals.isExecutive
    );
    // job_code should be editable for HRIntake
    showFieldsReadOnly.job_code = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    // Internal Fill Fields need to be enabled and editable for HRIntake Only
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;

    // Scaled Service Role - Salary Budget is Hidden
    hideFields.salary_budget = inputFormVals.isScaledServiceRole;
    hideFields.compa_ratio_of_offer =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRIntake || inputFormVals.isExecutive);
    hideFields.percent_increase =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRIntake || inputFormVals.isExecutive);
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.current_base_salary = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.date_of_lastpromotion =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.requested_offer = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    // HRIntake Queue should be able to edit business_title
    showFieldsReadOnly.business_title = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    // Executive level Hiring Manager is hidden
    hideFields.relatedPARTickets = false;
    hideFields.hiring_manager =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isExecutive;

    showFieldsReadOnly.target_effective_date =
      inputFormVals.isPARSubmissionsQueue ? true : inputFormVals.isExecutive;

    hideFields.approved_offer = !inputFormVals.isExecutive;
    hideFields.approved_effective_date = !inputFormVals.isExecutive;

    showFieldsReadOnly.approved_offer = !inputFormVals.isExecutive;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isExecutive;

    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;

    // Target Fill Date is editable for HR Intake
    showFieldsReadOnly.fill_date = !inputFormVals.isHRIntake;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isFinance ||
        inputFormVals.isBrandCEO ||
        inputFormVals.isRMAction ||
        inputFormVals.isHRAction)) ||
    (inputFormVals.isFinCEORMHRRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.allocReconcilable = false;
    hideFields.midpoint_budget =
      inputFormVals.isRMAction || inputFormVals.isEmpTypeGlobal;
    hideFields.allocRateCard = false;
    hideFields.par_level = false;
    hideFields.profit_center = false;
    hideFields.par_level = false;
    hideFields.salary_budget = inputFormVals.isRMAction;
    hideFields.addAllocationBtn = true;
    hideFields.par_billable = false;

    showFieldsReadOnly.target_effective_date = false;
    showFieldsReadOnly.fill_date = true;
    // Partner and PartnerCost is not shown in HRAction Queue, but shown in submitter view
    hideFields.partner =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;
    hideFields.partner_cost =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;
    hideFields.offshore_role =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;
    // PAR Brand, AxCompanym Payroll, BPC to be shown only for RM & HR action
    hideFields.par_brand =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    hideFields.par_axcompany =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    hideFields.par_payroll_company =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    hideFields.par_bpc =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    // job_code is displayed but is non-editable for RM & HR Action
    hideFields.job_code =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(
            (inputFormVals.isRMAction && !inputFormVals.isEmpTypeGlobal) ||
            inputFormVals.isHRAction
          );

    // requisition_id is displayed and editable for RM Action
    // requisition_id is displayed but is non-editable for HR Action
    hideFields.requisition_id =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRAction || inputFormVals.isRMAction);
    showFieldsReadOnly.requisition_id = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isRMAction;
    hideFields.position_id =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRAction || inputFormVals.isRMAction);
    showFieldsReadOnly.position_id = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isRMAction;
    hideFields.compa_ratio_of_offer = !(
      inputFormVals.isHRAction ||
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance
    );
    hideFields.percent_increase = !(
      inputFormVals.isHRAction ||
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance
    );

    // Hide internal fill fields for RM action and finance for TBH workflow
    hideFields.current_performance_rating =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.current_base_salary =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.date_of_last_compensation_change =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.date_of_lastpromotion =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.requested_offer =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isRMAction;
    // Finance, Brand CEO Hiring Manager is hidden
    hideFields.hiring_manager =
      (inputFormVals.isFinanceRole || inputFormVals.isBrandCEORole) &&
      inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isBrandCEO;

    // Constant to show if request is in PAR Submissions Tab in HR, RM Login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;
    hideFields.approved_offer = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );
    hideFields.approved_effective_date = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );

    showFieldsReadOnly.approved_offer = !(
      inputFormVals.isBrandCEO || inputFormVals.isFinance
    );
    showFieldsReadOnly.approved_effective_date = !(
      inputFormVals.isBrandCEO || inputFormVals.isFinance
    );
    // Decision is shown for RM Action Level, HR Action Level OR (Submissions Tab + Complete Status)
    hideFields.decision = !(
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );

    hideSections.positionJustification = inputFormVals.isRMAction;
    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
  }
};

const urgentCounterWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  if (inputFormVals.isSubmitterRole) {
    hideFields.ticket_status = true;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.allocReconcilable = false;
    hideFields.par_billable = false;
    hideFields.profit_center = false;
    hideFields.submitter_comments = true;

    showFieldsReadOnly.open_requisition_reason = inputFormVals.showDescription;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isHRIntake || inputFormVals.isExecutive)) ||
    (inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.par_billable = false;
    hideFields.profit_center = false;
    hideFields.allocReconcilable = false;
    hideFields.par_level = false;
    hideFields.job_code = false;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.salary_budget = false;
    hideFields.compa_ratio_of_offer = false;
    hideFields.percent_increase = false;
    hideFields.midpoint_budget = false;

    showFieldsReadOnly.current_performance_rating =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.current_base_salary = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.date_of_lastpromotion =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.requested_offer = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    showFieldsReadOnly.business_title = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    showFieldsReadOnly.job_code = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    hideFields.approved_offer = !inputFormVals.isExecutive;
    hideFields.approved_effective_date = !inputFormVals.isExecutive;

    showFieldsReadOnly.approved_offer = !inputFormVals.isExecutive;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isExecutive;
    // Target Effective Date in editable in HR Intake
    showFieldsReadOnly.target_effective_date = !inputFormVals.isHRIntake;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue && inputFormVals.isHRAction) ||
    (inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.allocReconcilable = false;
    hideFields.par_level = false;
    hideFields.profit_center = false;
    hideFields.par_level = false;
    hideFields.par_billable = false;
    hideFields.salary_budget = false;
    hideFields.compa_ratio_of_offer = false;
    hideFields.percent_increase = false;
    hideFields.job_code = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.current_performance_rating = false;
    hideFields.requested_offer = false;
    hideFields.midpoint_budget = false;

    showFieldsReadOnly.target_effective_date = true;

    // Constant to show if request is in PAR Submissions Tab in HR Login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      inputFormVals.isHRRole &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;
    hideFields.approved_offer = !(
      inputFormVals.isHRAction || isPARSubmissionsAndComplete
    );
    hideFields.approved_effective_date = !(
      inputFormVals.isHRAction || isPARSubmissionsAndComplete
    );
    // Decision is shown for HR Action Level OR (Submissions Tab + Complete Status)
    hideFields.decision = !(
      inputFormVals.isHRAction || isPARSubmissionsAndComplete
    );
  }
};

const backfillRequestWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.backfill_reason = false;
  hideFields.open_requisition_reason = false;
  hideFields.relatedPARTickets = false;
  hideFields.par_billable = false;
  if (inputFormVals.isSubmitterRole) {
    hideFields.ticket_status = true;
    hideFields.is_exempt = false;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.business_title = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.backfill_reason = inputFormVals.showDescription;
    showFieldsReadOnly.is_exempt = inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visbile for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;

    hideFields.partner = false;
    hideFields.partner_cost = false;
    hideFields.offshore_role = false;
    hideFields.profit_center = false;
    hideFields.par_brand = false;
    hideFields.par_axcompany = false;
    hideFields.par_payroll_company = false;
    hideFields.par_bpc = false;
    hideFields.salary_budget = false;
    hideFields.par_level = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.allocReconcilable = false;

    // All these fields are visible and editable for RM Intake
    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.partner = false;
    showFieldsReadOnly.offshore_role = false;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.employee_name = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.file_upload = false;
    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledService ||
        inputFormVals.isHRIntake ||
        inputFormVals.isExecutive)) ||
    (inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    // Editable For HR Intake
    const editableForHRIntake = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    // Field Visible for inputFormVals.isScaledService || inputFormVals.isExecutive
    const isVisibleForScaledExRole =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;

    hideFields.par_level = false;
    hideFields.relatedPARTickets = false;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;

    // Scaled Service Role - Salary Budget & Rate Card is Hidden
    hideFields.allocRateCard = inputFormVals.isScaledServiceRole;
    hideFields.salary_budget = inputFormVals.isScaledServiceRole;

    // Fields visible in HR Intake
    hideFields.job_code = !inputFormVals.isHRIntake;
    hideFields.par_brand = !inputFormVals.isHRIntake;
    hideFields.par_axcompany = !inputFormVals.isHRIntake;
    hideFields.par_payroll_company = !inputFormVals.isHRIntake;
    hideFields.par_bpc = !inputFormVals.isHRIntake;

    // Hide fields for HR Intake Queue
    hideFields.partner = isVisibleForScaledExRole;
    hideFields.partner_cost = isVisibleForScaledExRole;
    hideFields.offshore_role = isVisibleForScaledExRole;

    // Hide midpoint_budget in for Scaled Service Queue
    hideFields.midpoint_budget = inputFormVals.isScaledService;

    // Hide compa_ratio_of_offer for Scaled Service
    hideFields.compa_ratio_of_offer =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isScaledService;

    hideFields.percent_increase =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isScaledService;

    // Executive level Hiring Manager is hidden
    hideFields.hiring_manager =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isExecutive;

    // Fields should be editable for HRIntake
    showFieldsReadOnly.job_code = editableForHRIntake;
    showFieldsReadOnly.current_performance_rating = editableForHRIntake;
    showFieldsReadOnly.current_base_salary = editableForHRIntake;
    showFieldsReadOnly.date_of_last_compensation_change = editableForHRIntake;
    showFieldsReadOnly.date_of_lastpromotion = editableForHRIntake;
    showFieldsReadOnly.requested_offer = editableForHRIntake;
    showFieldsReadOnly.business_title = editableForHRIntake;

    hideFields.approved_offer = !inputFormVals.isExecutive;
    hideFields.approved_effective_date = !inputFormVals.isExecutive;

    showFieldsReadOnly.approved_offer = !inputFormVals.isExecutive;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isExecutive;
    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;

    // Target Fill Date is editable for HR Intake
    showFieldsReadOnly.fill_date = !inputFormVals.isHRIntake;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isFinance ||
        inputFormVals.isBrandCEO ||
        inputFormVals.isRMAction ||
        inputFormVals.isHRAction)) ||
    (inputFormVals.isFinCEORMHRRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    // Field visible for HR OR RM Action/ROle and Par Submissions tab
    const visibleForHRRMAction =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRAction || inputFormVals.isRMAction);

    // Hide Field for HR Action and visible
    const hideFieldForHRAction =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;

    // Hide Field For inputFormVals.isFinance || inputFormVals.isBrandCEO
    const hideFieldForFinanceAndBrand =
      (inputFormVals.isFinanceRole || inputFormVals.isBrandCEORole) &&
      inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isFinance || inputFormVals.isBrandCEO;

    // Field Editable for RM Action
    const editableForRMAction = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isRMAction;

    const hideFieldForRMAction =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isRMAction;

    // Visible Field For Finance || Brand CEO
    // Visible Field For Finance Role || Brand CEO Role in PAR Submission tab
    const visibleForFinanceBrand =
      (inputFormVals.isFinanceRole || inputFormVals.isBrandCEORole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isFinance || inputFormVals.isBrandCEO);

    hideFields.allocReconcilable = false;
    hideFields.allocRateCard = false;
    hideFields.midpoint_budget =
      inputFormVals.isRMAction || inputFormVals.isEmpTypeGlobal;
    hideFields.par_level = false;
    hideFields.profit_center = false;
    hideFields.salary_budget = inputFormVals.isRMAction;
    hideFields.is_exempt = false;

    // Partner and PartnerCost is not shown in HRAction Queue, but shown in submitter view
    hideFields.partner = hideFieldForHRAction;
    hideFields.partner_cost = hideFieldForHRAction;

    // Hide Field For inputFormVals.isFinance || inputFormVals.isBrandCEO
    hideFields.par_brand = hideFieldForFinanceAndBrand;
    hideFields.par_axcompany = hideFieldForFinanceAndBrand;
    hideFields.par_payroll_company = hideFieldForFinanceAndBrand;
    hideFields.par_bpc = hideFieldForFinanceAndBrand;
    hideFields.job_code =
      hideFieldForFinanceAndBrand ||
      !(inputFormVals.isRMAction && !inputFormVals.isEmpTypeGlobal);

    hideFields.requisition_id = visibleForHRRMAction;
    hideFields.position_id = visibleForHRRMAction;
    hideFields.current_performance_rating = hideFieldForRMAction;
    hideFields.current_base_salary = hideFieldForRMAction;
    hideFields.date_of_last_compensation_change = hideFieldForRMAction;
    hideFields.date_of_lastpromotion = hideFieldForRMAction;

    hideFields.requested_offer = hideFieldForRMAction;
    hideFields.compa_ratio_of_offer = hideFieldForRMAction;
    hideFields.percent_increase = hideFieldForRMAction;
    hideFields.hiring_manager = !visibleForFinanceBrand;

    showFieldsReadOnly.requisition_id = editableForRMAction;
    showFieldsReadOnly.position_id = editableForRMAction;

    // Constant to show if request is in PAR Submissions Tab in HR, RM Login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;

    hideFields.approved_offer = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );
    hideFields.approved_effective_date = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );

    showFieldsReadOnly.approved_offer = !(
      inputFormVals.isBrandCEO || inputFormVals.isFinance
    );
    showFieldsReadOnly.approved_effective_date = !(
      inputFormVals.isBrandCEO || inputFormVals.isFinance
    );

    // Decision is shown for RM Action Level, HR Action Level OR (Submissions Tab + Complete Status)
    hideFields.decision = !(
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );

    hideSections.positionJustification = inputFormVals.isRMAction;
    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
  }
};

const openRequestTBHWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.open_requisition_reason = false;
  hideFields.relatedPARTickets = false;
  if (inputFormVals.isSubmitterRole) {
    hideFields.ticket_status = true;
    hideFields.is_exempt = false;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.par_billable = false;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.business_title = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;

    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.open_requisition_reason = inputFormVals.showDescription;
    showFieldsReadOnly.is_exempt = inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visbile for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;

    hideFields.partner = false;
    hideFields.partner_cost = false;
    hideFields.offshore_role = false;
    hideFields.profit_center = false;
    hideFields.par_brand = false;
    hideFields.par_axcompany = false;
    hideFields.par_payroll_company = false;
    hideFields.par_bpc = false;
    hideFields.salary_budget = false;
    hideFields.par_level = false;
    hideFields.par_billable = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.allocReconcilable = false;

    // All these fields are visible and editable for RM Intake
    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.partner = false;
    showFieldsReadOnly.offshore_role = false;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.file_upload = false;

    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledService ||
        inputFormVals.isHRIntake ||
        inputFormVals.isExecutive)) ||
    (inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.par_billable = false;
    hideFields.par_level = false;
    hideFields.job_code = !inputFormVals.isHRIntake;
    // Hide rateCard,reconcilable, profit_center fields for Scaled Service/HR Intake Queue
    // Partner and PartnerCost is not shown in HRIntake Queue
    hideFields.partner =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    hideFields.partner_cost =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    hideFields.offshore_role =
      inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    // PAR Brand, AxCompany, Payroll, BPC to be shown only for HR Intake
    hideFields.par_brand = !inputFormVals.isHRIntake;
    hideFields.par_axcompany = !inputFormVals.isHRIntake;
    hideFields.par_payroll_company = !inputFormVals.isHRIntake;
    hideFields.par_bpc = !inputFormVals.isHRIntake;
    // Exempt is shown for all fields for the relevant queues
    hideFields.midpoint_budget = !(
      inputFormVals.isHRIntake ||
      !inputFormVals.isScaledService ||
      inputFormVals.isExecutive
    );
    // job_code should be editable for HRIntake
    showFieldsReadOnly.job_code = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    // Internal Fill Fields need to be enabled and editable for HRIntake Only
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;

    // Scaled Service Role - Salary Budget is Hidden
    hideFields.allocRateCard = inputFormVals.isScaledServiceRole;
    hideFields.salary_budget = inputFormVals.isScaledServiceRole;

    hideFields.compa_ratio_of_offer =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRIntake || inputFormVals.isExecutive);
    hideFields.percent_increase =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRIntake || inputFormVals.isExecutive);
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.current_base_salary = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.date_of_lastpromotion =
      inputFormVals.isPARSubmissionsQueue ? true : !inputFormVals.isHRIntake;
    showFieldsReadOnly.requested_offer = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    // HRIntake Queue should be able to edit business_title
    showFieldsReadOnly.business_title = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;
    // Executive level Hiring Manager is hidden
    hideFields.hiring_manager =
      inputFormVals.isExecutiveRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isExecutive;

    hideFields.approved_offer = !inputFormVals.isExecutive;
    hideFields.approved_effective_date = !inputFormVals.isExecutive;

    showFieldsReadOnly.approved_offer = !inputFormVals.isExecutive;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isExecutive;

    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
    // Target Fill Date is editable for HR Intake
    showFieldsReadOnly.fill_date = !inputFormVals.isHRIntake;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isFinance ||
        inputFormVals.isBrandCEO ||
        inputFormVals.isRMAction ||
        inputFormVals.isHRAction)) ||
    (inputFormVals.isFinCEORMHRRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.allocReconcilable = false;
    hideFields.allocRateCard = false;
    hideFields.par_level = false;
    hideFields.profit_center = false;
    hideFields.par_level = false;
    hideFields.midpoint_budget =
      inputFormVals.isRMAction || inputFormVals.isEmpTypeGlobal;
    hideFields.salary_budget = inputFormVals.isRMAction;
    hideFields.par_billable = false;

    showFieldsReadOnly.fill_date = true;
    // Partner and PartnerCost is not shown in HRAction Queue, but shown in submitter view
    hideFields.partner =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;
    hideFields.partner_cost =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;
    hideFields.offshore_role =
      inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRAction;
    // PAR Brand, AxCompanym Payroll, BPC to be shown only for RM & HR action
    hideFields.par_brand =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    hideFields.par_axcompany =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    hideFields.par_payroll_company =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    hideFields.par_bpc =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isRMAction || inputFormVals.isHRAction);
    // job_code is displayed but is non-editable for RM & HR Action
    hideFields.job_code =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(
            (inputFormVals.isRMAction && !inputFormVals.isEmpTypeGlobal) ||
            inputFormVals.isHRAction
          );

    // requisition_id is displayed and editable for RM Action
    // requisition_id is displayed but is non-editable for HR Action
    hideFields.requisition_id =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRAction || inputFormVals.isRMAction);
    hideFields.position_id =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue
        ? false
        : !(inputFormVals.isHRAction || inputFormVals.isRMAction);
    showFieldsReadOnly.requisition_id = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isRMAction;
    showFieldsReadOnly.position_id = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isRMAction;
    hideFields.compa_ratio_of_offer = !(
      inputFormVals.isHRAction ||
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance
    );
    hideFields.percent_increase = !(
      inputFormVals.isHRAction ||
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance
    );

    // Hide internal fill fields for RM action and finance for TBH workflo
    hideFields.current_performance_rating =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.current_base_salary =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.date_of_last_compensation_change =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.date_of_lastpromotion =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole
        ? true
        : inputFormVals.isRMAction;
    hideFields.requested_offer =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isRMAction;
    // Finance, Brand CEO Hiring Manager is hidden
    hideFields.hiring_manager =
      (inputFormVals.isFinanceRole || inputFormVals.isBrandCEORole) &&
      inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isFinance || inputFormVals.isBrandCEO;

    // Constant to show if request is in PAR Submissions Tab in HR, RM Login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;

    hideFields.approved_offer = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );
    hideFields.approved_effective_date = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );

    showFieldsReadOnly.approved_offer = inputFormVals.isPARSubmissionsQueue
      ? true
      : !(inputFormVals.isBrandCEO || inputFormVals.isFinance);
    showFieldsReadOnly.approved_effective_date =
      inputFormVals.isPARSubmissionsQueue
        ? true
        : !(inputFormVals.isBrandCEO || inputFormVals.isFinance);

    // Decision is shown for RM Action Level, HR Action Level OR (Submissions Tab + Complete Status)
    hideFields.decision = !(
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );

    hideSections.positionJustification = inputFormVals.isRMAction;
    // Is Exempt is readonly
    hideFields.is_exempt = false;
    showFieldsReadOnly.is_exempt = true;
  }
};

const reallocationTBHWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.deleteAllocationBtn = true;
  hideFields.par_billable = false;
  hideFields.profit_center = true;
  hideFields.relatedPARTickets = false;
  hideFields.par_level = false;
  hideFields.salary_budget = true;
  hideFields.business_title = false;

  if (inputFormVals.isSubmitterRole) {
    hideFields.ticket_status = true;
    hideFields.par_level = true;
    hideFields.allocReconcilable = true;
    showFieldsReadOnly.open_requisition_reason = inputFormVals.showDescription;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_employee_type =
      inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_level = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_reason = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_serviceline_department =
      inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visible for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;

    hideFields.profit_center = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.allocReconcilable = false;

    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.reallocation_employee_type = true;
    showFieldsReadOnly.reallocation_level = true;
    showFieldsReadOnly.reallocation_reason = true;
    showFieldsReadOnly.reallocation_serviceline_department = true;
    showFieldsReadOnly.target_effective_date = true;
    showFieldsReadOnly.file_upload = false;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledService || inputFormVals.isFinance)) ||
    (inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledExRole || inputFormVals.isFinanceRole))
  ) {
    // Scaled Service Role - Salary Budget is Hidden
    hideFields.allocRateCard = inputFormVals.isScaledServiceRole;
    hideFields.salary_budget = inputFormVals.isScaledServiceRole;
    hideFields.allocReconcilable = false;
    hideFields.profit_center = false;
    showFieldsReadOnly.allocRateCard = true;
    showFieldsReadOnly.allocVal = true;
    showFieldsReadOnly.allocReconcilable = true;
    showFieldsReadOnly.profit_center = true;
    showFieldsReadOnly.related_pars = true;
    showFieldsReadOnly.cc_emails = true;
    showFieldsReadOnly.reallocation_employee_type = true;
    showFieldsReadOnly.reallocation_level = true;
    showFieldsReadOnly.reallocation_reason = true;
    showFieldsReadOnly.reallocation_serviceline_department = true;
    showFieldsReadOnly.target_effective_date = true;
    hideFields.approved_effective_date = !inputFormVals.isFinance;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isFinance;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMAction) ||
    (inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole)
  ) {
    hideFields.allocReconcilable = false;
    hideFields.allocRateCard = false;
    hideFields.profit_center = false;
    showFieldsReadOnly.allocRateCard = true;
    showFieldsReadOnly.allocVal = true;
    showFieldsReadOnly.allocReconcilable = true;
    showFieldsReadOnly.profit_center = true;
    showFieldsReadOnly.related_pars = true;
    showFieldsReadOnly.cc_emails = true;
    showFieldsReadOnly.reallocation_employee_type = true;
    showFieldsReadOnly.reallocation_level = true;
    showFieldsReadOnly.reallocation_reason = true;
    showFieldsReadOnly.reallocation_serviceline_department = true;
    showFieldsReadOnly.target_effective_date = true;
    hideSections.positionJustification = inputFormVals.isRMAction;
    hideFields.target_effective_date = inputFormVals.isRMAction;
    // Constant to show if request is in PAR Submissions Tab in RM login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      inputFormVals.isRMRole &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;

    // Decision is shown for RM Action Level OR (Submissions Tab + Complete Status)
    hideFields.approved_effective_date = !(
      inputFormVals.isRMAction || isPARSubmissionsAndComplete
    );
    hideFields.decision = !(
      inputFormVals.isRMAction || isPARSubmissionsAndComplete
    );
  }
};

const salaryAdjustmentWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.par_billable = false;
  hideFields.profit_center = false;
  hideFields.relatedPARTickets = false;
  if (inputFormVals.isSubmitterRole) {
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.business_title = inputFormVals.showDescription;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visible for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;

    hideFields.par_level = false;
    hideFields.salary_budget = false;
    hideFields.related_pars = false;
    hideFields.cc_emails = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.allocReconcilable = false;
    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.file_upload = false;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledService ||
        inputFormVals.isHRIntake ||
        inputFormVals.isExecutive)) ||
    (inputFormVals.isScaledExRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    // Editable For HR Intake
    const editableForHRIntake = inputFormVals.isPARSubmissionsQueue
      ? true
      : !inputFormVals.isHRIntake;

    // Hide Field for Scaled Service
    const hideForScaledService =
      inputFormVals.isPARSubmissionsQueue && inputFormVals.isScaledServiceRole
        ? true
        : inputFormVals.isScaledService;

    hideFields.par_level = false;
    hideFields.business_title = false;
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.target_effective_date = false;
    hideFields.cc_emails = false;
    hideFields.midpoint_budget = inputFormVals.isScaledService;
    // Scaled Service Role - Rate card and Salary Budget is Hidden
    hideFields.salary_budget =
      inputFormVals.isScaledServiceRole || inputFormVals.isHRIntake;
    hideFields.allocRateCard =
      inputFormVals.isScaledServiceRole || inputFormVals.isHRIntake;

    hideFields.allocReconcilable =
      inputFormVals.isScaledServiceRole && inputFormVals.isPARSubmissionsQueue
        ? false
        : inputFormVals.isHRIntake;
    hideFields.job_code = hideForScaledService;
    hideFields.compa_ratio_of_offer = hideForScaledService;
    hideFields.percent_increase = hideForScaledService;

    showFieldsReadOnly.business_title = editableForHRIntake;
    showFieldsReadOnly.current_performance_rating = editableForHRIntake;
    showFieldsReadOnly.current_base_salary = editableForHRIntake;
    showFieldsReadOnly.date_of_last_compensation_change = editableForHRIntake;
    showFieldsReadOnly.date_of_lastpromotion = editableForHRIntake;
    showFieldsReadOnly.requested_offer = editableForHRIntake;
    showFieldsReadOnly.job_code = editableForHRIntake;
    showFieldsReadOnly.compa_ratio_of_offer = editableForHRIntake;
    showFieldsReadOnly.target_effective_date = editableForHRIntake;
    hideFields.approved_offer = !inputFormVals.isExecutive;
    hideFields.approved_effective_date = !inputFormVals.isExecutive;

    showFieldsReadOnly.approved_offer = !inputFormVals.isExecutive;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isExecutive;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isFinance ||
        inputFormVals.isBrandCEO ||
        inputFormVals.isRMAction ||
        inputFormVals.isHRAction)) ||
    (inputFormVals.isFinCEORMHRRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.par_level = false;
    hideFields.salary_budget = inputFormVals.isRMAction;
    hideFields.business_title = false;
    hideFields.target_effective_date = inputFormVals.isRMAction;
    hideFields.cc_emails = false;
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.midpoint_budget = false;

    hideFields.requested_offer =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue
        ? true
        : inputFormVals.isRMIntake;
    hideFields.job_code =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue;
    hideFields.compa_ratio_of_offer =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue;
    hideFields.percent_increase =
      inputFormVals.isRMRole && inputFormVals.isPARSubmissionsQueue;

    hideFields.current_performance_rating =
      (inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue) ||
      inputFormVals.isFinance
        ? false
        : !inputFormVals.isBrandCEO && !inputFormVals.isHRAction;
    hideFields.current_base_salary =
      (inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue) ||
      inputFormVals.isFinance
        ? false
        : !inputFormVals.isBrandCEO && !inputFormVals.isHRAction;
    hideFields.date_of_last_compensation_change =
      (inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue) ||
      inputFormVals.isFinance
        ? false
        : !inputFormVals.isBrandCEO && !inputFormVals.isHRAction;
    hideFields.date_of_lastpromotion =
      (inputFormVals.isHRRole && inputFormVals.isPARSubmissionsQueue) ||
      inputFormVals.isFinance
        ? false
        : !inputFormVals.isBrandCEO && !inputFormVals.isHRAction;

    showFieldsReadOnly.target_effective_date = true;

    // Constant to show if request is in PAR Submissions Tab in HR, RM Login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;
    hideFields.approved_offer = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      inputFormVals.isNoQueue
    );
    hideFields.approved_effective_date = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      inputFormVals.isNoQueue
    );

    showFieldsReadOnly.approved_offer = !(
      inputFormVals.isBrandCEO || inputFormVals.isFinance
    );
    showFieldsReadOnly.approved_effective_date = !(
      inputFormVals.isBrandCEO || inputFormVals.isFinance
    );

    // Decision is shown for RM Action Level, HR Action Level OR (Submissions Tab + Complete Status)
    hideFields.decision = !(
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );
    hideSections.positionJustification = inputFormVals.isRMAction;
  }
};

const promotionInPlaceWorkFlowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.relatedPARTickets = false;
  if (inputFormVals.isSubmitterRole) {
    hideFields.current_performance_rating = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.requested_offer = false;
    hideFields.par_billable = false;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.open_requisition_reason = inputFormVals.showDescription;
    showFieldsReadOnly.requested_level = inputFormVals.showDescription;
    showFieldsReadOnly.promotion_business_title = inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visible for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;

    hideFields.par_level = false;
    hideFields.par_billable = false;
    hideFields.profit_center = false;
    hideFields.salary_budget = false;
    hideFields.par_brand = false;
    hideFields.par_axcompany = false;
    hideFields.par_payroll_company = false;
    hideFields.par_bpc = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.allocReconcilable = false;

    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.par_axcompany = true;
    showFieldsReadOnly.par_brand = true;
    showFieldsReadOnly.par_payroll_company = true;
    showFieldsReadOnly.par_bpc = true;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.file_upload = false;
    showFieldsReadOnly.promotion_business_title = inputFormVals.showDescription;
    showFieldsReadOnly.requested_level = inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
  } else if (inputFormVals.isScaledServiceRole) {
    // Scaled Service Role - Rate card and Salary Budget is Hidden
    hideFields.salary_budget = inputFormVals.isScaledServiceRole;
    hideFields.allocRateCard = inputFormVals.isScaledServiceRole;
    hideFields.par_level = false;
    hideFields.par_billable = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.current_performance_rating = false;
    hideFields.requested_offer = false;
    showFieldsReadOnly.requested_level = inputFormVals.showDescription;
    showFieldsReadOnly.promotion_business_title = inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
  } else if (inputFormVals.isHRIntake) {
    hideFields.par_level = false;
    hideFields.par_billable = false;
    hideFields.salary_budget = false;
    hideFields.current_base_salary = false;
    hideFields.date_of_last_compensation_change = false;
    hideFields.date_of_lastpromotion = false;
    hideFields.current_performance_rating = false;
    hideFields.requested_offer = false;
    hideFields.compa_ratio_of_offer = false;
    hideFields.percent_increase = false;
    hideFields.job_code = false;
    hideFields.par_brand = false;
    hideFields.par_axcompany = false;
    hideFields.par_payroll_company = false;
    hideFields.par_bpc = false;
    hideFields.midpoint_budget = false;
    showFieldsReadOnly.business_title = false;
    showFieldsReadOnly.current_base_salary = false;
    showFieldsReadOnly.date_of_last_compensation_change = false;
    showFieldsReadOnly.date_of_lastpromotion = false;
    showFieldsReadOnly.current_performance_rating = false;
    showFieldsReadOnly.requested_level = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = false;
    showFieldsReadOnly.job_code = false;
    showFieldsReadOnly.target_effective_date = false;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue && inputFormVals.isFinance) ||
    inputFormVals.isBrandCEO ||
    inputFormVals.isRMAction ||
    inputFormVals.isHRAction ||
    inputFormVals.isExecutive ||
    (inputFormVals.isFinCEORMHRRole && inputFormVals.isPARSubmissionsQueue)
  ) {
    hideFields.par_level = false;
    hideFields.par_billable = false;
    hideFields.allocReconcilable = false;
    hideFields.allocRateCard = false;
    hideFields.profit_center = false;

    if (
      inputFormVals.isBrandCEO ||
      inputFormVals.isHRAction ||
      inputFormVals.isFinance ||
      inputFormVals.isExecutive
    ) {
      hideFields.current_base_salary = false;
      hideFields.date_of_last_compensation_change = false;
      hideFields.date_of_lastpromotion = false;
      hideFields.current_performance_rating = false;
    }
    if (inputFormVals.isRMAction || inputFormVals.isHRAction) {
      hideFields.job_code = !inputFormVals.isRMAction
        ? false
        : inputFormVals.isEmpTypeGlobal;
      hideFields.par_brand = false;
      hideFields.par_axcompany = false;
      hideFields.par_payroll_company = false;
      hideFields.par_bpc = false;
      hideFields.position_id = false;
    }

    hideFields.salary_budget = inputFormVals.isRMAction;
    hideFields.midpoint_budget = inputFormVals.isRMAction;
    hideFields.requested_offer = inputFormVals.isRMAction;
    hideFields.target_effective_date = inputFormVals.isRMAction;
    hideFields.compa_ratio_of_offer = inputFormVals.isRMAction;
    hideFields.percent_increase = inputFormVals.isRMAction;

    if (inputFormVals.isHRAction) {
      hideFields.position_id = false;
    }
    showFieldsReadOnly.requested_level = inputFormVals.showDescription;
    showFieldsReadOnly.promotion_business_title = inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
    showFieldsReadOnly.position_id = inputFormVals.isRMAction
      ? !inputFormVals.showDescription
      : inputFormVals.showDescription;

    // Constant to show if request is in PAR Submissions Tab in HR, RM Login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      (inputFormVals.isHRRole || inputFormVals.isRMRole) &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;
    hideFields.approved_offer = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      inputFormVals.isExecutive ||
      isPARSubmissionsAndComplete
    );
    hideFields.approved_effective_date = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      inputFormVals.isExecutive ||
      isPARSubmissionsAndComplete
    );

    showFieldsReadOnly.approved_offer = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isExecutive
    );
    showFieldsReadOnly.approved_effective_date = !(
      inputFormVals.isBrandCEO ||
      inputFormVals.isFinance ||
      inputFormVals.isExecutive
    );

    // Decision is shown for RM Action Level, HR Action Level OR (Submissions Tab + Complete Status)
    hideFields.decision = !(
      inputFormVals.isRMAction ||
      inputFormVals.isHRAction ||
      isPARSubmissionsAndComplete
    );
    hideSections.positionJustification = inputFormVals.isRMAction;
  }
};

const reallocationActiveWorkflowFieldVisibility = (
  showFieldsReadOnly,
  hideFields,
  inputFormVals,
  hideSections
) => {
  hideFields.deleteAllocationBtn = true;
  hideFields.par_billable = false;
  hideFields.profit_center = true;
  hideFields.relatedPARTickets = false;
  hideFields.par_level = false;
  hideFields.salary_budget = true;
  hideFields.business_title = false;
  if (inputFormVals.isSubmitterRole) {
    hideFields.par_level = true;
    hideFields.allocReconcilable = true;
    showFieldsReadOnly.open_requisition_reason = inputFormVals.showDescription;
    showFieldsReadOnly.current_performance_rating =
      inputFormVals.showDescription;
    showFieldsReadOnly.current_base_salary = inputFormVals.showDescription;
    showFieldsReadOnly.date_of_last_compensation_change =
      inputFormVals.showDescription;
    hideFields.submitter_comments = true;
    showFieldsReadOnly.date_of_lastpromotion = inputFormVals.showDescription;
    showFieldsReadOnly.requested_offer = inputFormVals.showDescription;
    showFieldsReadOnly.related_pars = inputFormVals.showDescription;
    showFieldsReadOnly.comment = inputFormVals.showDescription;
    showFieldsReadOnly.fill_date = inputFormVals.showDescription;
    showFieldsReadOnly.cc_emails = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_employee_type =
      inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_level = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_reason = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_details = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_serviceline_department =
      inputFormVals.showDescription;
    showFieldsReadOnly.target_effective_date = inputFormVals.showDescription;
    showFieldsReadOnly.reallocation_reassign_allocation_to =
      inputFormVals.showDescription;
  } else if (inputFormVals.isRMIntake) {
    // Allocation fields and buttons are visible for RM Intake
    hideFields.allocRateCard = false;
    hideFields.allocReconcilable = false;
    hideFields.addAllocationBtn = false;
    hideFields.deleteAllocationBtn = false;
    hideFields.profit_center = false;
    hideFields.salary_budget = false;

    // Allocations are always editable for RM Intake
    showFieldsReadOnly.allocClient = false;
    showFieldsReadOnly.allocLOB = false;
    showFieldsReadOnly.allocVal = false;
    showFieldsReadOnly.allocRateCard = false;
    showFieldsReadOnly.profit_center = false;
    showFieldsReadOnly.related_pars = false;
    showFieldsReadOnly.cc_emails = false;
    showFieldsReadOnly.reallocation_reassign_allocation_to = true;
    showFieldsReadOnly.reallocation_reason = true;
    showFieldsReadOnly.reallocation_details = true;
    showFieldsReadOnly.target_effective_date = true;
    showFieldsReadOnly.file_upload = false;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledService || inputFormVals.isFinance)) ||
    (inputFormVals.isPARSubmissionsQueue &&
      (inputFormVals.isScaledExRole || inputFormVals.isFinanceRole))
  ) {
    hideFields.allocReconcilable = false;
    // Scaled Service Role - Rate card and Salary Budget is Hidden
    hideFields.salary_budget = inputFormVals.isScaledServiceRole;
    hideFields.allocRateCard = inputFormVals.isScaledServiceRole;
    hideFields.profit_center = false;

    showFieldsReadOnly.allocRateCard = true;
    showFieldsReadOnly.allocVal = true;
    showFieldsReadOnly.allocReconcilable = true;
    showFieldsReadOnly.profit_center = true;
    showFieldsReadOnly.related_pars = true;
    showFieldsReadOnly.cc_emails = true;
    showFieldsReadOnly.reallocation_reason = true;
    showFieldsReadOnly.reallocation_details = true;
    showFieldsReadOnly.reallocation_reassign_allocation_to = true;
    showFieldsReadOnly.target_effective_date = true;
    hideFields.approved_effective_date = !inputFormVals.isFinance;
    showFieldsReadOnly.approved_effective_date = !inputFormVals.isFinance;
  } else if (
    (!inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMAction) ||
    (inputFormVals.isPARSubmissionsQueue && inputFormVals.isRMRole)
  ) {
    hideFields.allocReconcilable = false;
    hideFields.allocRateCard = false;
    hideFields.salary_budget = false;

    hideFields.profit_center = false;
    showFieldsReadOnly.allocRateCard = true;
    showFieldsReadOnly.allocVal = true;
    showFieldsReadOnly.allocReconcilable = true;
    showFieldsReadOnly.profit_center = true;
    showFieldsReadOnly.related_pars = true;
    showFieldsReadOnly.cc_emails = true;
    showFieldsReadOnly.reallocation_reason = true;
    showFieldsReadOnly.reallocation_details = inputFormVals.true;
    showFieldsReadOnly.reallocation_reassign_allocation_to = true;
    showFieldsReadOnly.target_effective_date = true;

    hideFields.target_effective_date = inputFormVals.isRMAction;
    hideSections.positionJustification = inputFormVals.isRMAction;

    // Constant to show if request is in PAR Submissions Tab in RM login
    // AND PAR is complete (which means it has no queue associated with it)
    const isPARSubmissionsAndComplete =
      inputFormVals.isRMRole &&
      inputFormVals.isPARSubmissionsQueue &&
      inputFormVals.isNoQueue;

    // Decision is shown for RM Action Level OR (Submissions Tab + Complete Status)
    hideFields.approved_effective_date = !(
      inputFormVals.isRMAction || isPARSubmissionsAndComplete
    );
    hideFields.decision = !(
      inputFormVals.isRMAction || isPARSubmissionsAndComplete
    );
  }
};

const getPositionTitle = (
  validations,
  parLevel,
  summaryDepartment,
  servicelineDepartment,
  employeeType
) => {
  let parPositionTitlePrefix = validations.parPositionTitlePrefix;

  if (employeeType === RapConstants.EMPLOYEE_TYPE_VALUES[1]) {
    // employeeType is Global
    parPositionTitlePrefix = validations.parPositionTitlePrefixGlobal;
  }

  parPositionTitlePrefix = parPositionTitlePrefix?.filter(
    (x) => x.parentValue.toUpperCase() === parLevel.toUpperCase()
  );
  const val = servicelineDepartment + "~" + summaryDepartment;
  const parPositionTitleSuffix = validations.parPositionTitleSuffix?.filter(
    (x) => x.parentValue === val
  );
  const positionTitlePre =
    parPositionTitlePrefix && parPositionTitlePrefix[0]
      ? parPositionTitlePrefix[0].value
      : null;
  const positionTitleSuf =
    parPositionTitleSuffix && parPositionTitleSuffix[0]
      ? parPositionTitleSuffix[0].value
      : null;
  const positionTitle =
    positionTitlePre && positionTitleSuf
      ? positionTitlePre + ", " + positionTitleSuf
      : positionTitlePre || positionTitleSuf;

  return positionTitle;
};

const isReturnedAction = (actionName) =>
  actionName === ParConstants.PAR_ACTIONS.RETURNED ||
  actionName === ParConstants.PAR_ACTIONS.RETURNED_TO_CEO ||
  actionName === ParConstants.PAR_ACTIONS.WITHDRAWN ||
  actionName === ParConstants.PAR_ACTIONS.RETURNED_TO_HR;

export default {
  getCompRatioOfOffer,
  addToStaffWorkflowFieldVisibility,
  openRequestTBHWorkflowFieldVisibility,
  urgentCounterWorkflowFieldVisibility,
  backfillRequestWorkflowFieldVisibility,
  reallocationTBHWorkflowFieldVisibility,
  promotionInPlaceWorkFlowFieldVisibility,
  salaryAdjustmentWorkflowFieldVisibility,
  reallocationActiveWorkflowFieldVisibility,
  getPositionTitle,
  isReturnedAction
};

import { useMemo } from "react";
import ParConstants from "../../../common/ParConstants";

const useQueueStatus = (initialData) => {
  const {
    RM_INTAKE,
    HR_INTAKE,
    HR_ACTION,
    RM_ACTION,
    SCALED_SVC,
    FINANCE,
    BRAND_CEO,
    EXCECUTIVE
  } = ParConstants.PAR_QUEUE;

  const currentQueueName = initialData?.current_queue_name;
  return useMemo(
    () => ({
      isRMIntake: currentQueueName === RM_INTAKE,
      isHRIntake: currentQueueName === HR_INTAKE,
      isHRAction: currentQueueName === HR_ACTION,
      isRMAction: currentQueueName === RM_ACTION,
      isScaledService: currentQueueName === SCALED_SVC,
      isFinance: currentQueueName === FINANCE,
      isBrandCEO: currentQueueName === BRAND_CEO,
      isExecutive: currentQueueName === EXCECUTIVE,
      isNoQueue: currentQueueName === null || currentQueueName === undefined
    }),
    [initialData]
  );
};

export default useQueueStatus;

import PropTypes from "prop-types";
import React from "react";

const Employee = ({
  employeeName,
  employeeWorkdayId,
  employeeType,
  employeeLocation
}) => (
  <div className="row employee-details">
    <div className="col-md-10 mt-3">
      <div className="row par-table par-table-highlight ml-0">
        <div className="col-md-3">
          <strong>Employee Name</strong>
        </div>
        <div className="col-md-3">
          <strong>Employee Workday ID</strong>
        </div>
        <div className="col-md-3">
          <strong>Employee Type</strong>
        </div>
        <div className="col-md-3">
          <strong>Employee Location</strong>
        </div>
      </div>
    </div>
    <div className="col-md-10">
      <div className="row par-table  par-table-content ml-0">
        <div className="col-md-3">
          <strong>{employeeName}</strong>
        </div>
        <div className="col-md-3">
          <strong>{employeeWorkdayId}</strong>
        </div>
        <div className="col-md-3">
          <strong>{employeeType}</strong>
        </div>
        <div className="col-md-3">
          <strong>{employeeLocation}</strong>
        </div>
      </div>
    </div>
  </div>
);

export default Employee;

Employee.propTypes = {
  employeeName: PropTypes.string,
  employeeWorkdayId: PropTypes.string,
  employeeType: PropTypes.string,
  employeeLocation: PropTypes.string
};

Employee.defaultProps = {
  employeeName: "",
  employeeWorkdayId: "",
  employeeType: "",
  employeeLocation: ""
};

/* eslint-disable no-cond-assign */
/* eslint-disable no-return-assign */
import React from "react";
import { toast } from "react-toastify";

import SearchModal from "../SearchModal";
import MainForm from "../MainForm/MainForm";
import Spinner from "../shared/Spinner";
import Tooltip from "../shared/Tooltip";
import api from "../../services/RapApi";
import RapConstants from "../../common/RapConstants";
import Constants from "../../common/Constants";

/**
 * Loads the list of records that represent the workflow of things the user wants to update.
 * This file maintains control over moving from one record to the next, and is the overlord
 * of control of the entire screen.
 */
class WorkflowList extends React.Component {
  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isValidationLoading: true,
      isDataLoading: false,
      tableData: [],
      selectedRow: {},
      showFilterModal: false,
      searchValue: "",
      validations: null,
      formInput: {},
      recordGatekeeper: null,
      isRecordLoaded: false,
      serviceLineDept: []
    };

    this.searchInputRef = React.createRef();

    // a better communication channel down to the main form component that skips state and repaint hassle
    this.mainFormEventer = null;

    // these don't need to be reactive
    this.selectedIndex = -1;
    this.sortField = "mslPin";
    this.sortOrder = "up";

    // keybindings are against the window object, so literally any key presses in the
    // window are detected even if focus is in a text area or a dropdown.
    this.keyDownBind = (evt) => this.keyDown(evt);
    this.keyUpBind = (evt) => this.keyUp(evt);

    // The key down/up for literally every keypress needs to be quick, so the following is
    // written for performance; maps for quick lookup, and using a bitmask to keep track of
    // the state of the keys as they're going down and up. The following is a of the key
    // code, and the binary position in the bitmask it will work with. There's four keys
    // for "ctrl" so that MacOS works as expected.
    // Bitmask values...
    //  - if Ctl is held down, mask is 00001 = 1
    //  - if Alt is held down, mask is 00010 = 2
    //  - if F is held down, mask is 10000 = 16
    //  - if all these are held down, mask is 10011 = 19
    this.keymask = 0;
    this.keyFlags = {
      17: 1, // ctl (control, windows keys and mac-command keys below are logically the same)
      91: 1, // win/mac-control
      92: 1, // win/mac-control
      93: 1, // win/mac-control
      18: 2, // alt
      188: 4, // , <
      190: 8, // . >
      70: 16, // F
      83: 32, // S
      65: 64, // A
      78: 128, // N
      82: 256 // R
    };

    // Map the mask values to functions that we'd like to trigger
    // for custom logic
    this.keyBindings = {
      [4 + 2]: () => {
        // alt + <
        this.previousRecord();
      },
      [8 + 2]: () => {
        // alt + >
        this.nextRecord();
      },
      [16 + 1 + 2]: () => {
        // ctl/command + alt + F
        this.toggleFilterModal();
      },
      [32 + 1 + 2]: () => {
        // ctl/command + alt + S
        if (this.mainFormEventer) this.mainFormEventer("save-record");
      },
      [64 + 1 + 2]: () => {
        // ctl/command + alt + A
        if (this.mainFormEventer) this.mainFormEventer("add-allocation");
      },
      [128 + 1 + 2]: () => {
        // ctl/command + alt + N
        if (this.mainFormEventer) this.mainFormEventer("new-record");
      },
      [128 + 2]: () => {
        // alt + N
        if (this.mainFormEventer) this.mainFormEventer("notes-focus");
      },
      [256 + 2]: () => {
        // alt + R
        if (this.mainFormEventer) this.mainFormEventer("change-reason-focus");
      },
      [32 + 2]: () => {
        // alt + S
        this.searchInputRef?.current?.focus();
      }
    };

    // attach hotkeys to the window
    window.addEventListener(
      "keydown",
      (this.keyDownBind = (event) => {
        // map the key code to a flag if we have one
        let flag = this.keyFlags[event.which];

        // if we have a flag, update the keymask by bitwise 'OR'ing it to the mask
        if (flag) this.keymask |= flag; // eslint-disable-line

        // see if there's a function for the current value and execute it
        const func = this.keyBindings[this.keymask];
        if (func) func();

        // This key management pretty tightly keeps track of everything,
        // except that "ctrl + F" triggers a browser find function, and we
        // will lose the F key-up event. This little block here makes sure
        // that those key combinations will get a resetting of the flag to
        // the keymask...
        // below: if Ctl or Alt are down, and one of the other keys is pressed...
        // eslint-disable-next-line no-bitwise
        if (this.keymask & 3 && this.keymask & 508) {
          // ...set a quick timeout to reset the flag
          setTimeout(() => (this.keymask &= ~flag), 50); // eslint-disable-line
        }
      }),
      true
    );

    window.addEventListener(
      "keyup",
      (this.keyUpBind = (event) => {
        // reset the bitmask flag on a key-up
        let flag = this.keyFlags[event.which];
        if (flag) this.keymask &= ~flag; // eslint-disable-line no-bitwise
      }),
      true
    );

    // trap window blurs for pessimism reasons just to reset the keymask
    window.addEventListener("blur", () => this.keymask === 0);
    window.addEventListener("focus", () => this.keymask === 0);
  }

  componentDidMount() {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    this.mounted = true;
    this.loadValidationValues();
  }

  componentWillUnmount() {
    // detach hotkeys
    window.removeEventListener("keydown", this.keyDownBind);
    window.removeEventListener("keyup", this.keyUpBind);
    this.mounted = false;
  }

  loadValidationValues = async () => {
    try {
      const validations = await api.getValidations();
      if (this.mounted) {
        this.setState(() => ({
          isValidationLoading: false,
          validations,
          isRecordLoaded: true
        }));
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an error in loading the dropdown values - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  loadWorkflowList = async (searchRequest = {}) => {
    this.setState(() => ({ tableData: [], isDataLoading: true }));
    try {
      // moving specifics of http to the api file so that we get the data we want
      // and if there are issues, that we throw exceptions uniformly to update the UI
      const tableData = (await api.getWorkflowList(searchRequest)) || [];
      this.setState(() => ({ tableData, isDataLoading: false }));
      if (tableData && tableData.length > 0) {
        this.showRowDetails(undefined, 0);
      } else {
        toast.warn("No records found!", Constants.TOAST_OPTIONS);
      }
    } catch (ex) {
      console.log(ex);
      toast.error(
        `There was an error in loading the employee list - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
      this.setState(() => ({ tableData: undefined }));
    }
  };

  loadRecord = async (recordId) => {
    // confirm that it's not the "NULL" from WorkflowList
    // which indicates it wants to clear the form
    if (recordId && recordId !== RapConstants.EMPTY_RECORD_ID) {
      const actualLoading = async () => {
        try {
          // if multiple loads get triggered, the gatekeeper will make sure we only bother
          // updating the UI for the most recent call
          this.setState(() => ({
            recordGatekeeper: recordId,
            isRecordLoaded: false,
            formInput: {}
          }));

          let record = await api.getRecord(recordId);
          if (record.mslPin === this.state.recordGatekeeper) {
            // sorting the allocations based off client name in alphabetical order
            record.allocations = record.allocations.sort((a, b) => {
              let firstClient = a.client.toUpperCase();
              let secondClient = b.client.toUpperCase();
              return firstClient < secondClient
                ? -1
                : firstClient > secondClient
                ? 1
                : 0;
            });

            this.setState((prevState) => ({
              formInput: record || {},
              recordGatekeeper: null,
              isRecordLoaded: true,
              // filter service line based on summary detp for the record being loaded
              serviceLineDept:
                prevState.validations.serviceLineDepartmentList.filter(
                  (x) => x.parentValue === record.summaryDepartment
                )
            }));
            // wait a beat, then tell it to focus
            setTimeout(() => {
              if (this.mainFormEventer) this.mainFormEventer("focus");
            }, 100);
          }
        } catch (ex) {
          // FIXME: show error fetching record
          console.log(ex);
          toast.error(
            `There was an error in loading the record - ${ex.message}`,
            Constants.TOAST_OPTIONS
          );
        }
      };

      if (this.loadingDebounce) {
        clearTimeout(this.loadingDebounce);
      }
      this.loadingDebounce = setTimeout(actualLoading, 200);
    } else {
      this.setState(() => ({ formInput: {}, isRecordLoaded: true }));
    }
  };

  previousRecord = () => {
    let { tableData } = this.state;
    if (tableData.length) {
      let idx = this.selectedIndex;

      if (idx > 0) idx--;
      else idx = tableData.length - 1;

      this.showRowDetails(undefined, idx);
    }
  };

  nextRecord = () => {
    let { tableData } = this.state;
    if (tableData.length) {
      let idx = this.selectedIndex;

      if (idx < tableData.length - 1) idx++;
      else idx = 0;

      this.showRowDetails(undefined, idx);
    }
  };

  getSearchResults = () => {
    const { searchValue } = this.state;
    // Clear any previous call that's still running
    const enteredText = (this.searchInputRef.current.value || "").trim();
    if (enteredText === "") {
      this.clearFilter();
    }
    if (enteredText !== "" && enteredText !== searchValue) {
      this.setState(() => ({ searchValue: enteredText }));
      this.loadWorkflowList({ textSearch: enteredText });
    }
  };

  applyFilterOptions = (filterData) => {
    // Splitting client-brand to be passed in different parameters
    if (filterData.client.lastIndexOf("-") !== -1) {
      filterData.clientBrand = filterData.client
        .substring(filterData.client.lastIndexOf("-") + 1)
        .trim();
      filterData.client = filterData.client
        .substring(0, filterData.client.lastIndexOf("-"))
        .trim();
    }
    // loads the workflow list with the search details from the modal
    this.loadWorkflowList(filterData);
    this.toggleFilterModal();
  };

  clearFilter = () => {
    // Reset the table filter with empty filter array
    this.setState(() => ({ tableData: [] }));
    this.deselect();
  };

  deselect = () => {
    this.setState(() => ({ selectedRow: {} }));
    this.loadRecord(RapConstants.EMPTY_RECORD_ID);
  };

  showRowDetails = (row, idx) => {
    if (row && idx === undefined) {
      idx = this.state.tableData.findIndex((r) => r.mslPin === row.mslPin);
    } else if (!row && idx !== undefined && idx > -1) {
      row = this.state.tableData[idx];
    }

    this.selectedIndex = idx;
    this.setState(() => ({ selectedRow: row }));
    this.loadRecord(row.mslPin || RapConstants.EMPTY_RECORD_ID);
  };

  toggleFilterModal = () => {
    this.setState((prevState) => ({
      showFilterModal: !prevState.showFilterModal
    }));
  };

  copyRecordHandler = (record) => {
    this.setState(() => ({
      selectedRow: {},
      formInput: record || {},
      recordGatekeeper: null,
      isRecordLoaded: true
    }));
  };

  recordUpdated = (newRecord) => {
    // Refresh the validation data in case its updated
    this.loadValidationValues();

    // after update record removing isCopyRecord from record obj
    if (newRecord.isCopyRecord) {
      delete newRecord.isCopyRecord;
    }

    const tableData = [...this.state.tableData];
    let recordIndex = tableData.findIndex(
      (r) => "" + r.mslPin === "" + newRecord.mslPin
    );
    if (recordIndex >= 0) {
      // update the record in the workflow list
      tableData[
        recordIndex
      ].displayName = `${newRecord.familyName}, ${newRecord.givenName}`;
      tableData[recordIndex].status = newRecord.status;
      tableData[recordIndex].tbhCategory = newRecord.tbhCategory;
    } else {
      if (!newRecord.displayName) {
        newRecord.displayName = `${newRecord.familyName}, ${newRecord.givenName}`;
      }
      tableData.push(newRecord);
    }
    this.setState(() => ({
      tableData,
      formInput: newRecord,
      selectedRow: newRecord
    }));
  };

  sortList = (field) => {
    this.sortOrder =
      this.sortField !== field ? "up" : this.sortOrder === "up" ? "down" : "up";
    this.sortField = field;
    let dir = this.sortOrder === "up" ? 1 : -1;
    let dat = [...this.state.tableData];

    if (field === "startDate") {
      dat.sort((x, y) => {
        const dateX = new Date(x[field]);
        const dateY = new Date(y[field]);

        if (dateX === dateY) return 0;
        return dateX < dateY ? -1 * dir : 1 * dir;
      });
    } else {
      dat.sort((x, y) => {
        if (x[field] === y[field]) return 0;
        return x[field] < y[field] ? -1 * dir : 1 * dir;
      });
    }
    this.setState(() => ({ tableData: dat }));

    const row = this.state.selectedRow;
    this.selectedIndex = row
      ? dat.findIndex((r) => r.mslPin === row.mslPin)
      : -1;
  };

  setEmployeeStatusIcon = (record) => {
    let iconType = "";

    // TBH
    if (record.tbhCategory === RapConstants.TBH_CATEGORY.pendingPAR) {
      iconType = "pending-opr";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.activePAR) {
      iconType = "active-opr";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.hold) {
      iconType = "onhold-user";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.internalFill) {
      iconType = "internal-fill";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.global) {
      iconType = "global-user";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.holdForPair) {
      iconType = "hold-for-pair";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.recruiting) {
      iconType = "recruiting";
    } else if (record.tbhCategory === RapConstants.TBH_CATEGORY.filled) {
      if (record.status === RapConstants.EMPLOYEE_STATUS_VALUES[0]) {
        iconType = "active-user";
      } else {
        iconType = "onleave-user";
      }
    }

    return iconType;
  };

  /** Allows for parent to provide a way for subcomponent to register an event channel to the parent so that
   * the parent can directly event to a subordinate without causing repaints or state fussing.
   * ( This may be improper, but difficulty of easy things in many frameworks (Vue is the same) drives me nutty :)
   */
  eventProvider = (eventer) => {
    this.mainFormEventer = eventer;
  };

  render() {
    const {
      validations,
      isValidationLoading,
      tableData,
      selectedRow,
      showFilterModal
    } = this.state;
    const { sortField, sortOrder } = this;
    if (isValidationLoading) {
      return (
        <div className="row ml-2">
          Loading Data...
          <Spinner />
        </div>
      );
    }
    const sortIndicator = (field) =>
      field === sortField ? (
        <i className={"fas fa-sort-" + sortOrder} />
      ) : (
        <></>
      );

    // needed a variabel to play with for the status icon...
    return (
      <div className="row ml-1 pr-0">
        <div className="col-sm-3 pr-0">
          <div>
            <table className="col-md-12 top-filter">
              <tbody>
                <tr>
                  <td width="100%">
                    <form
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "100%",
                        maxWidth: "unset"
                      }}
                      className="col-md-7"
                      onSubmit={(e) => {
                        e.preventDefault();
                        setTimeout(() => this.getSearchResults(), 10);
                        return false;
                      }}
                    >
                      <table className="col-md-12">
                        <tbody>
                          <tr className="top-filter">
                            <td width="100%" className="top-filter">
                              <Tooltip text="Search by Given Name, Family Name OR MSL PIN">
                                <input
                                  placeholder="Search"
                                  type="text"
                                  className="form-control"
                                  style={{ width: "100% !important" }}
                                  ref={this.searchInputRef}
                                />
                              </Tooltip>
                            </td>
                            <td className="top-filter">
                              <div
                                data-testid="filter-button"
                                className="input-group-append"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.getSearchResults()}
                              >
                                <span className="input-group-text rounded-right">
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </td>
                  <td>
                    <button
                      type="submit"
                      className="btn btn-success btn-sm ml-1"
                      onClick={this.toggleFilterModal}
                    >
                      Advanced
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {(!tableData || tableData.length === 0) && (
            <div>
              {!this.state.isDataLoading && (
                <div className="mt-2 card">
                  <div
                    data-testid="no-records-container"
                    className="no-records-div"
                  >
                    <div className="no-records-img">
                      <i className="fa fa-search fa-3x" />
                    </div>
                    <div className="no-records-text">
                      Please use the filter or search to get the records you
                      want to work on
                    </div>
                  </div>
                </div>
              )}
              {this.state.isDataLoading && (
                <div className="ml-2 mt-2">
                  Loading Records...
                  <Spinner />
                </div>
              )}
            </div>
          )}
          {tableData && tableData.length > 0 && (
            <div className="form-group mt-2 card">
              <div className="wfTable ml-2">
                <div className="wfTableHead">
                  <div
                    className="wftMslPinHead md-3"
                    onClick={() => this.sortList("mslPin")}
                  >
                    MSL&nbsp;PIN&nbsp;{sortIndicator("mslPin")}
                  </div>
                  <div
                    className="wftNameHead"
                    onClick={() => this.sortList("displayName")}
                  >
                    Name&nbsp;{sortIndicator("displayName")}
                  </div>
                  <div
                    className="wftStartDateHead"
                    onClick={() => this.sortList("startDate")}
                  >
                    Start Date &nbsp;{sortIndicator("displayName")}
                  </div>
                  <div
                    className="wftStatusHead"
                    onClick={() => this.sortList("status")}
                  >
                    Status&nbsp;{sortIndicator("status")}
                  </div>
                </div>
              </div>
              <div className="table-wrap scrollable ml-2 mr-1">
                <div className="wfTable">
                  {tableData.map((r, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={"workflow-" + r.mslPin + "-" + index}
                      className={
                        "wfTableRow" +
                        (r.mslPin === selectedRow.mslPin ? " selected" : "")
                      }
                      onClick={() => this.showRowDetails(r)}
                    >
                      <div className="wftMslPin">{r.mslPin}</div>
                      <div className="wftName">{r.displayName}</div>
                      <div className="wftStartDate">{r.startDate}</div>

                      <div className="wftStatus">
                        <span>
                          <i
                            title={r?.tbhCategory}
                            className={
                              (r.status ===
                                RapConstants.EMPLOYEE_STATUS_VALUES[3] &&
                              r.tbhCategory === RapConstants.TBH_CATEGORY.filled
                                ? "far "
                                : "fa ") +
                              "fa-user " +
                              this.setEmployeeStatusIcon(r)
                            }
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-9">
          <MainForm
            dataInput={this.state.formInput}
            validations={validations}
            onSubmit={this.recordUpdated}
            onClear={this.deselect}
            onCopyRecord={this.copyRecordHandler}
            eventer={this.eventProvider}
            isRecordLoaded={this.state.isRecordLoaded}
            serviceLineDept={this.state.serviceLineDept}
          />
        </div>

        {showFilterModal && (
          <SearchModal
            isModalOpen={showFilterModal}
            validations={validations}
            onSearchModalClose={this.toggleFilterModal}
            onSearchModalSubmit={this.applyFilterOptions}
          />
        )}
      </div>
    );
  }
}

export default WorkflowList;
